import React from "react";
import Card from "react-bootstrap/Card";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useHistory, useParams, RouteComponentProps } from "react-router-dom";
import { useMutation } from "react-relay";
import { FormikHelpers } from "formik";
import { toast } from "react-toastify";
import FormLayout from "../../../../../Form/FormLayout";
import { ComponentRule, IProperty } from "../../../../../Form/models";
import FormLayoutFooter from "../../../../../Form/FormLayoutFooter";
import {
  CreateAuthMethodMutation,
  UpdateAuthMethodMutation,
  DeleteAuthMethodMutation,
  useCorporateAuthMethodById,
  SSOAuthMethodFormData,
} from "./CorporatesAuthMethodsQueriesMutations";
import DynamicInputGroup from "../../../../../Form/DynamicInputGroup";
import {
  getFieldsByInputObjectName,
  getSettingsByGroup,
} from "../../../../../Form/formUtilities";
import properties from "../../../../../../data/sso-auth-method-settings.json";
import Textarea from "../../../../../Form/Textarea";
import { ConfirmationModal } from "../../../../../common/ConfirmationModal";
import { useModal } from "../../../../../Context/ModalContext";
import DynamicSelect from "../../../../../Form/DynamicSelect";

interface MatchParams {
  corporate_id: string;
  auth_method_id?: string;
}

type FormData = SSOAuthMethodFormData;
type Props = RouteComponentProps<MatchParams> & {};

// Custom component rules
const componentRules: Record<string, ComponentRule> = {
  metadataIssuer: {
    lg: 6,
    md: 6,
    xs: 6,
  },
  metadataUrl: {
    lg: 6,
    md: 6,
    xs: 6,
  },
  metadataConfig: {
    lg: 6,
    md: 6,
    xs: 6,
    component: Textarea,
    componentProps: {
      rows: 10,
    },
  },
  metadataOverrides: {
    lg: 6,
    md: 6,
    xs: 6,
  },
  authType: {
    component: DynamicSelect,
    componentProps: {
      options: [{ value: "saml", label: "SAML" }],
      defaultValue: null,
      allowCreate: true,
    },
  },
};

export default function CorporatesAuthMethodsProfile({ match }: Props) {
  const history = useHistory();
  const goBackUrl = `${match.url.split(`/auth_methods`)[0]}/auth_methods`;

  const { t } = useTranslation("corporates-auth-methods");
  const { corporate_id: corporateId, auth_method_id: authMethodId } =
    useParams<MatchParams>();

  const [formData, isCreate, { deleteMutation, createOrUpdateMutation }] =
    useCorporateAuthMethodData(authMethodId);

  const { showModal, hideModal } = useModal();

  const validationRules = yup.object({
    displayName: yup
      .string()
      .required()
      .label(t("profile.property.displayName.label")),
    code: yup.string().required().label(t("profile.property.code.label")),
    authType: yup
      .string()
      .required()
      .label(t("profile.property.authType.label")),
    employmentIdAttribute: yup
      .string()
      .nullable()
      .transform((curr, orig) => (orig === "" ? null : curr))
      .label(t("profile.property.employmentIdAttribute.label")),
    metadataOverrides: yup
      .mixed()
      .isValidJson(t("profile.property.metadataOverrides.label")),
  });

  const onSave = (
    changes: Partial<FormData>,
    errorHandler: (error: Error) => void,
    event?: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    values?: any,
    helpers?: FormikHelpers<FormData>,
  ) => {
    createOrUpdateMutation({
      variables: {
        ...(!isCreate && { id: authMethodId }),
        input: validationRules.cast(changes),
      },
      onCompleted: () => {
        toast(
          isCreate
            ? t("translation:form.notifications.created_successfully")
            : t("translation:form.notifications.saved_successfully"),
        );
        history.replace(goBackUrl);
      },
      onError(error: Error) {
        errorHandler(error);
      },
    });
  };

  const onDelete = () => {
    if (formData) {
      showModal(
        <ConfirmationModal
          onClose={hideModal}
          okClicked={() => {
            deleteMutation({
              variables: {
                id: authMethodId,
              },
              onCompleted: () => {
                toast(t("translation:form.notifications.deleted_successfully"));
                history.replace(goBackUrl);
                hideModal();
              },
              onError(error: Error) {
                alert(error);
                hideModal();
              },
            });
          }}
          variant="danger"
          title={t("profile.deleteModal.title", {
            name: formData.displayName,
          })}
          okText={t("profile.deleteModal.buttons.yes")}
        >
          {t("profile.deleteModal.message")}
        </ConfirmationModal>,
      );
    }
  };

  return (
    <Card body>
      <FormLayout<FormData>
        base={
          formData ||
          ({
            corporateId,
            authType: "saml",
          } as FormData)
        }
        onSave={onSave}
        validationRules={validationRules}
        componentRules={componentRules}
        isCreate={isCreate}
        propertyList={[]}
      >
        <DynamicInputGroup
          fields={getSettingsByGroup(
            getFieldsByInputObjectName(
              properties as unknown as IProperty[],
              "SSOAuthMethodUpdateInput",
            ),
          )}
        />
        <FormLayoutFooter isCreate={isCreate} onDelete={onDelete} />
      </FormLayout>
    </Card>
  );
}

function useCorporateAuthMethodData(authMethodId?: string) {
  const [deleteMutation] = useMutation(DeleteAuthMethodMutation);
  const [createMutation] = useMutation(CreateAuthMethodMutation);
  const [updateMutation] = useMutation(UpdateAuthMethodMutation);

  const authMethod = useCorporateAuthMethodById(authMethodId);
  const isCreate = authMethod == null;

  return [
    authMethod,
    isCreate,
    {
      deleteMutation,
      createOrUpdateMutation: isCreate ? createMutation : updateMutation,
    },
  ] as const;
}
