import React from "react";
import { Route, RouteComponentProps, Link, Redirect } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import { Translation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { graphql, QueryRenderer } from "react-relay";

import HeaderPortal from "../../../Portal/HeaderPortal";
import { idmInternalEnvironment } from "../../../../environment";
import AccountProfile from "./AccountProfile";
import AccountDetails from "./AccountDetails";
import AccountEmployments from "./AccountEmployments";
import AccountActionsDropdown from "./AccountActionsDropdown";
import AccountDevices from "./AccountDevices";

import { Id } from "../../../../models/common";
import AppContext, { AppContextValue } from "../../external/Context/AppContext";
import Loader from "../../../common/Loader";
import { AccountLayout_Query } from "./__generated__/AccountLayout_Query.graphql";
import AccountBadge from "./AccountBadge";

const query = graphql`
  query AccountLayout_Query($userId: ID!) {
    users(ids: [$userId]) {
      nodes {
        id
        email
        lockedMinutesRemaining
        confirmedAt
        confirmationSentAt
        confirmationToken
        deleted
        userStacks {
          stackId
          synced
        }
        devices {
          id
        }
      }
    }
  }
`;

interface MatchParams {
  user_id: string;
}

type Props = RouteComponentProps<MatchParams> & {};

type State = {
  userId: Id;
};

export default class AccountLayout extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    const properties = props;
    const {
      match: { params },
    } = properties;

    const userId = params.user_id;
    this.state = {
      userId,
    };
  }

  render() {
    const properties = this.props;
    const { match } = properties;
    const { userId } = this.state;

    return (
      <QueryRenderer<AccountLayout_Query>
        environment={idmInternalEnvironment}
        query={query}
        variables={{
          userId,
        }}
        render={({ error, props }) => {
          if (error) {
            return <div>Error! {error.message}</div>;
          }
          if (!props) {
            return <Loader />;
          }

          if (!props.users?.nodes?.length || !props.users?.nodes[0]) {
            // return <div>Cannot find user with id {userId}</div>;
            return <Redirect to="/accounts" />;
          }

          const user = props.users?.nodes[0];

          return (
            <Translation ns="accounts">
              {(t) => (
                <>
                  <header className="sub-header-container">
                    <Nav variant="tabs">
                      <LinkContainer to={`${match.url}`} exact>
                        <Nav.Link>{t("layout.nav.settings")}</Nav.Link>
                      </LinkContainer>
                      <LinkContainer to={`${match.url}/details`}>
                        <Nav.Link>{t("layout.nav.details")}</Nav.Link>
                      </LinkContainer>
                      <LinkContainer to={`${match.url}/businesses`}>
                        <Nav.Link>{`${t("layout.nav.businesses")}`}</Nav.Link>
                      </LinkContainer>
                      <LinkContainer to={`${match.url}/devices`}>
                        <Nav.Link>{`${t("layout.nav.devices")} (${
                          user.devices.length
                        })`}</Nav.Link>
                      </LinkContainer>
                      <AppContext.Consumer>
                        {(context: AppContextValue) => (
                          <AccountActionsDropdown
                            user={user as any}
                            stack={context.stack}
                          />
                        )}
                      </AppContext.Consumer>
                    </Nav>
                  </header>
                  <div className="mt-4">
                    <Route
                      exact
                      path={`${match.path}`}
                      render={() => (
                        <AccountProfile
                          environment={idmInternalEnvironment}
                          {...properties}
                        />
                      )}
                    />
                    <Route
                      path={`${match.path}/details`}
                      render={() => <AccountDetails {...properties} />}
                    />
                    <Route
                      path={`${match.path}/businesses`}
                      render={() => (
                        <AccountEmployments {...properties} user={user} />
                      )}
                    />
                    <Route
                      path={`${match.path}/devices`}
                      render={() => <AccountDevices {...properties} />}
                    />
                  </div>
                  <footer />
                  <HeaderPortal>
                    <Link to="/accounts">
                      <span>{t("layout.title")}</span>
                    </Link>
                    <span className="ml-2 mr-2">&gt;</span>
                    <span>
                      {user?.email}{" "}
                      <AccountBadge className="ml-1" user={user} />
                    </span>
                  </HeaderPortal>
                </>
              )}
            </Translation>
          );
        }}
      />
    );
  }
}
