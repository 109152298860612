/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type TimeOffTypesQueryMutations_GetSingleTimeOffType_QueryVariables = {
    ids?: Array<string> | null | undefined;
    businessId: string;
    skip: boolean;
};
export type TimeOffTypesQueryMutations_GetSingleTimeOffType_QueryResponse = {
    readonly timeOffTypes?: {
        readonly nodes: ReadonlyArray<{
            readonly id: string;
            readonly name: string;
            readonly shortName: string | null;
            readonly code: string | null;
            readonly paid: boolean;
            readonly ignoreAutoReject: boolean;
            readonly isManagerByProxyOnly: boolean;
            readonly employmentTypeCodes: ReadonlyArray<string>;
        } | null> | null;
    } | undefined;
};
export type TimeOffTypesQueryMutations_GetSingleTimeOffType_Query = {
    readonly response: TimeOffTypesQueryMutations_GetSingleTimeOffType_QueryResponse;
    readonly variables: TimeOffTypesQueryMutations_GetSingleTimeOffType_QueryVariables;
};



/*
query TimeOffTypesQueryMutations_GetSingleTimeOffType_Query(
  $ids: [ID!]
  $businessId: ID!
  $skip: Boolean!
) {
  timeOffTypes(ids: $ids, businessId: $businessId) @skip(if: $skip) {
    nodes {
      id
      name
      shortName
      code
      paid
      ignoreAutoReject
      isManagerByProxyOnly
      employmentTypeCodes
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "businessId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ids"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skip"
},
v3 = [
  {
    "condition": "skip",
    "kind": "Condition",
    "passingValue": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "businessId",
            "variableName": "businessId"
          },
          {
            "kind": "Variable",
            "name": "ids",
            "variableName": "ids"
          }
        ],
        "concreteType": "TimeOffTypeConnection",
        "kind": "LinkedField",
        "name": "timeOffTypes",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TimeOffType",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "shortName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "paid",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "ignoreAutoReject",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isManagerByProxyOnly",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "employmentTypeCodes",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TimeOffTypesQueryMutations_GetSingleTimeOffType_Query",
    "selections": (v3/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "TimeOffTypesQueryMutations_GetSingleTimeOffType_Query",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "1deb52512f9c28c73d3de6424c3d4523",
    "id": null,
    "metadata": {},
    "name": "TimeOffTypesQueryMutations_GetSingleTimeOffType_Query",
    "operationKind": "query",
    "text": "query TimeOffTypesQueryMutations_GetSingleTimeOffType_Query(\n  $ids: [ID!]\n  $businessId: ID!\n  $skip: Boolean!\n) {\n  timeOffTypes(ids: $ids, businessId: $businessId) @skip(if: $skip) {\n    nodes {\n      id\n      name\n      shortName\n      code\n      paid\n      ignoreAutoReject\n      isManagerByProxyOnly\n      employmentTypeCodes\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'e50edf8214bfa15479b1565957524efd';
export default node;
