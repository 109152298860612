import React, { Component } from "react";
import { graphql, QueryRenderer } from "react-relay";
import { RouteComponentProps } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Translation } from "react-i18next";
import { BusinessContext } from "../../Context/BusinessContext";
import Searchable from "../../Searchable/Searchable";
import EmploymentTable, {
  EmploymentStatus,
  getCurrentlyEmployedParam,
  getDeletedParam,
} from "./EmploymentTable";
import DynamicSelect from "../../Form/DynamicSelect";
import { Employments_InternalQuery } from "./__generated__/Employments_InternalQuery.graphql";
import HeaderPortal from "../../Portal/HeaderPortal";

const EmploymentsQuery = graphql`
  query Employments_InternalQuery(
    $businessId: ID!
    $searchValue: String
    $deleted: Boolean
    $acceptedInvite: Boolean
    $userLinked: Boolean
    $currentlyEmployed: Boolean
    $pageSize: Int!
    $after: String
  ) {
    ...EmploymentTable_viewer
  }
`;

interface MatchParams {
  business_id: string;
  stack_id: string;
}

type Props = RouteComponentProps<MatchParams> & {};

type State = {
  searchValue: string;
  employmentStatus: EmploymentStatus;
  unlinked: boolean;
};

const defaultFilter = {
  searchValue: "",
  employmentStatus: EmploymentStatus.Any,
  unlinked: false,
};

export default class Employments extends Component<Props, State> {
  static contextType = BusinessContext;

  constructor(props: any) {
    super(props);

    this.state = defaultFilter;
  }

  private onSearchChange(value: string) {
    this.setState({
      searchValue: value,
    });
  }

  render() {
    const properties = this.props;
    const { searchValue, employmentStatus, unlinked } = this.state;
    const { environment } = this.context;

    const {
      match: { params },
    } = properties;
    const { business_id: businessId, stack_id: stackId } = params;

    return (
      <Translation ns="employment">
        {(t) => (
          <>
            <HeaderPortal as="span" elementId="sub-header-portal">
              <span className="ml-2 mr-2">&gt;</span>
              <span>{t("breadcrumb")}</span>
            </HeaderPortal>
            <Row className="my-2">
              <Col md={3}>
                <Searchable
                  className="employments"
                  searchValue={searchValue}
                  onSearchChange={this.onSearchChange.bind(this)}
                  placeholder={t("table.search")}
                />
              </Col>
              <Col md="auto">
                <Form inline>
                  <Form.Group>
                    <Form.Label className="mr-2 ml-0">
                      {t("table.status")}
                    </Form.Label>
                    <DynamicSelect<EmploymentStatus>
                      options={[
                        {
                          label: t("table.statuses.any"),
                          value: EmploymentStatus.Any,
                        },
                        {
                          label: t("table.statuses.employed"),
                          value: EmploymentStatus.Employed,
                        },
                        {
                          label: t("table.statuses.invited"),
                          value: EmploymentStatus.Invited,
                        },
                        {
                          label: t("table.statuses.terminated"),
                          value: EmploymentStatus.Terminated,
                        },
                      ]}
                      value={employmentStatus}
                      name="employment-status"
                      onChange={(
                        newValue: EmploymentStatus | null | undefined,
                      ) => {
                        this.setState({
                          employmentStatus:
                            newValue ?? EmploymentStatus.Employed,
                        });
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="border-right">
                    <Form.Check
                      className="mr-4 ml-4"
                      type="checkbox"
                      id="unlinked"
                      label={t("table.unlinked")}
                      name="unlinked"
                      checked={unlinked}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        const { currentTarget } = e;
                        const { checked } = currentTarget;
                        this.setState({
                          unlinked: checked,
                        });
                      }}
                    />
                  </Form.Group>
                </Form>
              </Col>
              <Col>
                <Button
                  variant="outline-primary"
                  onClick={() => {
                    this.setState(defaultFilter);
                  }}
                >
                  {t("table.clearFilters")}
                </Button>
              </Col>
            </Row>

            <Card body>
              <QueryRenderer<Employments_InternalQuery>
                environment={environment}
                query={EmploymentsQuery}
                variables={{
                  businessId,
                  searchValue,
                  acceptedInvite:
                    employmentStatus === EmploymentStatus.Invited
                      ? false
                      : undefined,
                  deleted: getDeletedParam(employmentStatus),
                  userLinked: unlinked ? false : undefined,
                  currentlyEmployed:
                    getCurrentlyEmployedParam(employmentStatus),
                  pageSize: 50,
                }}
                render={({ error, props }) => {
                  if (error) {
                    return <div>Error!</div>;
                  }
                  if (!props) {
                    return null;
                  }
                  return (
                    <EmploymentTable
                      {...properties}
                      stackId={stackId}
                      businessId={businessId}
                      searchValue={searchValue}
                      employmentStatus={employmentStatus}
                      unlinked={unlinked}
                      viewer={props as any}
                    />
                  );
                }}
              />
            </Card>
          </>
        )}
      </Translation>
    );
  }
}
