/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type FixedShiftRestrictionEnum = "not_applicable" | "optional" | "required" | "%future added value";
export type EmploymentTypesQueriesMutations_GetEmploymentTypeConfigs_QueryVariables = {
    businessId: string;
    deleted?: boolean | null | undefined;
    search?: string | null | undefined;
};
export type EmploymentTypesQueriesMutations_GetEmploymentTypeConfigs_QueryResponse = {
    readonly employmentTypeConfigs: {
        readonly nodes: ReadonlyArray<{
            readonly id: string;
            readonly code: string | null;
            readonly deleted: boolean;
            readonly employmentTypeCode: string;
            readonly fixedShiftRestriction: FixedShiftRestrictionEnum;
            readonly gmhEnabled: boolean;
            readonly gmhMinimumDurationError: unknown | null;
            readonly gmhMinimumDurationWarning: unknown | null;
            readonly isDefault: boolean;
            readonly name: string;
            readonly shortName: string;
            readonly smallId: number;
            readonly timeClockName: string;
            readonly timeOffComplianceEnabled: boolean;
        } | null> | null;
    };
};
export type EmploymentTypesQueriesMutations_GetEmploymentTypeConfigs_Query = {
    readonly response: EmploymentTypesQueriesMutations_GetEmploymentTypeConfigs_QueryResponse;
    readonly variables: EmploymentTypesQueriesMutations_GetEmploymentTypeConfigs_QueryVariables;
};



/*
query EmploymentTypesQueriesMutations_GetEmploymentTypeConfigs_Query(
  $businessId: ID!
  $deleted: Boolean
  $search: String
) {
  employmentTypeConfigs(businessId: $businessId, deleted: $deleted, search: $search) {
    nodes {
      id
      code
      deleted
      employmentTypeCode
      fixedShiftRestriction
      gmhEnabled
      gmhMinimumDurationError
      gmhMinimumDurationWarning
      isDefault
      name
      shortName
      smallId
      timeClockName
      timeOffComplianceEnabled
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "deleted"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Variable",
        "name": "deleted",
        "variableName": "deleted"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      }
    ],
    "concreteType": "EmploymentTypeConfigConnection",
    "kind": "LinkedField",
    "name": "employmentTypeConfigs",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EmploymentTypeConfig",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deleted",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "employmentTypeCode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fixedShiftRestriction",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "gmhEnabled",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "gmhMinimumDurationError",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "gmhMinimumDurationWarning",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isDefault",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shortName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "smallId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "timeClockName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "timeOffComplianceEnabled",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EmploymentTypesQueriesMutations_GetEmploymentTypeConfigs_Query",
    "selections": (v1/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EmploymentTypesQueriesMutations_GetEmploymentTypeConfigs_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4366789b0ef3e3436ac89049686fcbf7",
    "id": null,
    "metadata": {},
    "name": "EmploymentTypesQueriesMutations_GetEmploymentTypeConfigs_Query",
    "operationKind": "query",
    "text": "query EmploymentTypesQueriesMutations_GetEmploymentTypeConfigs_Query(\n  $businessId: ID!\n  $deleted: Boolean\n  $search: String\n) {\n  employmentTypeConfigs(businessId: $businessId, deleted: $deleted, search: $search) {\n    nodes {\n      id\n      code\n      deleted\n      employmentTypeCode\n      fixedShiftRestriction\n      gmhEnabled\n      gmhMinimumDurationError\n      gmhMinimumDurationWarning\n      isDefault\n      name\n      shortName\n      smallId\n      timeClockName\n      timeOffComplianceEnabled\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'd2ebf2efe310e716d0ff4f253168ce98';
export default node;
