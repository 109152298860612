import React from "react";
import { graphql, QueryRenderer } from "react-relay";
import { RouteComponentProps } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Profile from "./EmploymentProfileForm";
import { BusinessContext } from "../../Context/BusinessContext";
import Loader from "../../common/Loader";

const EmploymentQuery = graphql`
  query EmploymentProfile_Query($businessId: ID!, $employmentId: ID!) {
    employments(businessId: $businessId, ids: [$employmentId]) {
      edges {
        node {
          ...EmploymentProfileForm_employment
        }
      }
    }
  }
`;

interface MatchParams {
  business_id: string;
  employment_id?: string;
}

type Props = RouteComponentProps<MatchParams> & {};

export default class EmploymentProfile extends React.Component<Props> {
  static contextType = BusinessContext;

  render() {
    const { match, ...rest } = this.props;
    const { params } = match;
    const { business_id: businessId, employment_id: employmentId } = params;
    const { environment } = this.context;

    return (
      <QueryRenderer
        environment={environment}
        query={EmploymentQuery}
        variables={{
          businessId,
          employmentId,
        }}
        render={({ error, props }) => {
          if (error) {
            return <div>Error!</div>;
          }
          if (!props) {
            return <Loader />;
          }
          return (
            <Card body>
              <Profile
                {...rest}
                match={match as any}
                employment={(props as any).employments.edges[0].node}
                environment={environment}
              />
            </Card>
          );
        }}
      />
    );
  }
}
