import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Link, Route, RouteComponentProps } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import { LinkContainer } from "react-router-bootstrap";
import BusinessProfile from "./BusinessProfile";
import HeaderPortal from "../../Portal/HeaderPortal";
import { useBusinessContext } from "../../Context/BusinessContext";
import Employments from "../Employment/Employments";
import Schedules from "../Schedule/Schedules";
import AOSTemplates from "../AOSTemplates/AOSTemplates";
import BreakTypes from "../BreakTypes/BreakTypes";
import BreakTypesProfile from "../BreakTypes/BreakTypesProfile/BreakTypesProfile";
import SkillLevels from "../SkillLevels/SkillLevels";
import TimeOffTypesProfile from "../TimeOffTypes/TimeOffTypesProfile/TimeOffTypesProfile";
import TimeOffTypes from "../TimeOffTypes/TimeOffTypes";
import PeopleSettingsProfile from "./PeopleSettings/PeopleSettingsProfile";
import AuditLogs from "../AuditLogs/AuditLogs";
import MetadataTypes from "./MetadataTypes/MetadataTypes";
import MetadataTypesProfile from "./MetadataTypes/MetadataTypesProfile/MetadataTypesProfile";
import MetadataLayout from "./MetadataLayout/MetadataLayout";
import { useAppRouter } from "../../hooks/useAppRouter";
import EmploymentTypes from "../EmploymentTypes/EmploymentTypes";
import EmploymentTypesProfile from "../EmploymentTypes/EmploymentTypesProfile/EmploymentTypesProfile";
import PerformanceSettings from "../Employment/PerformanceSettings";
import FeedbackReasonsSettings from "./FeedbackReasons/FeedbackReasons";

interface MatchParams {
  business_id: string;
}

type Props = RouteComponentProps<MatchParams> & {};

enum ActiveSubRouteLocation {
  General = "general",
  BreakType = "breakTypes",
  EmploymentType = "employmentTypes",
  BreakTypeEdit = "breakTypesEdit",
  SkillLevel = "skillLevels",
  Employees = "employees",
  AosTemplates = "aosTemplates",
  Schedule = "schedules",
  TimeOffType = "timeOffType",
  TimeOffTypeEdit = "timeOffTypesEdit",
  MetadataType = "metadataType",
  MetadataTypeEdit = "metadataTypeEdit",
  MetadataLayouts = "metadataLayout",
  PeopleSettings = "peopleSettings",
  FeedbackReasons = "feedbackReasons",
  PerformanceSettingsLoc = "performanceSettings",
}

const BusinessLayout: FunctionComponent<Props> = ({ match }: Props) => {
  const { t } = useTranslation("businesses");
  const { business_id: businessId } = match.params;

  const { businessInCache: business, appContext } = useBusinessContext();

  const isNewBusiness = business?.id == null;
  const [selectedSubRoute, subRouteOptions] =
    useBusinessLayoutNavigation(isNewBusiness);

  const dropdownIsSelected = [
    ActiveSubRouteLocation.BreakType,
    ActiveSubRouteLocation.BreakTypeEdit,
    ActiveSubRouteLocation.General,
    ActiveSubRouteLocation.SkillLevel,
    ActiveSubRouteLocation.TimeOffType,
    ActiveSubRouteLocation.TimeOffTypeEdit,
    ActiveSubRouteLocation.PeopleSettings,
  ].includes(selectedSubRoute as ActiveSubRouteLocation);

  const employeesDropdownIsSelected = [
    ActiveSubRouteLocation.PerformanceSettingsLoc,
    ActiveSubRouteLocation.EmploymentType,
    ActiveSubRouteLocation.Employees,
    ActiveSubRouteLocation.FeedbackReasons,
  ].includes(selectedSubRoute as ActiveSubRouteLocation);

  const metadataDropdownSelected = [
    ActiveSubRouteLocation.MetadataType,
    ActiveSubRouteLocation.MetadataTypeEdit,
    ActiveSubRouteLocation.MetadataLayouts,
  ].includes(selectedSubRoute as ActiveSubRouteLocation);

  const showFeedbackReasonsLink =
    business?.disciplinaryActionEnabled || business?.commendationEnabled;

  return (
    <>
      {appContext.stack && (
        <>
          <HeaderPortal as="span">
            {isNewBusiness && (
              <>
                <Link to={`/stack/${appContext.stack.id}/businesses`}>
                  <span>{t("layout.title")}</span>
                </Link>
                <span className="ml-2 mr-2">&gt;</span>
                <span>
                  {business?.id ? business?.businessName : t("table.new")}
                </span>
              </>
            )}

            {!isNewBusiness && (
              <>
                <Link to={`/stack/${appContext.stack.id}/businesses`}>
                  <span>{business?.businessName}</span>
                </Link>
              </>
            )}

            <span id="sub-header-portal" />
          </HeaderPortal>
        </>
      )}

      <header className="sub-header-container">
        {subRouteOptions?.hideNav && (
          <Nav variant="tabs">
            <LinkContainer className="active" exact to="#">
              <Nav.Link>{t("layout.nav.settings")}</Nav.Link>
            </LinkContainer>
          </Nav>
        )}
        {!subRouteOptions?.hideNav && (
          <Nav variant="tabs">
            <NavDropdown
              title={t("layout.nav.settings")}
              id="nav-dropdown"
              className={dropdownIsSelected ? "active" : ""}
            >
              <LinkContainer exact to={`${match.url}`}>
                <NavDropdown.Item>{t("layout.nav.general")}</NavDropdown.Item>
              </LinkContainer>
              {!isNewBusiness && (
                <>
                  <LinkContainer exact to={`${match.url}/people_settings`}>
                    <NavDropdown.Item>
                      {t("layout.nav.peopleSettings")}
                    </NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Divider />
                  <LinkContainer exact to={`${match.url}/break_types`}>
                    <NavDropdown.Item>
                      {t("layout.nav.breakTypes")}
                    </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer exact to={`${match.url}/skill_levels`}>
                    <NavDropdown.Item>
                      {t("layout.nav.skillLevels")}
                    </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer exact to={`${match.url}/time_off_types`}>
                    <NavDropdown.Item>
                      {t("layout.nav.timeOffTypes")}
                    </NavDropdown.Item>
                  </LinkContainer>
                </>
              )}
            </NavDropdown>

            {businessId && (
              <>
                <NavDropdown
                  title={t("layout.nav.employees")}
                  id="nav-dropdown"
                  className={employeesDropdownIsSelected ? "active" : ""}
                >
                  <LinkContainer exact to={`${match.url}/employees`}>
                    <NavDropdown.Item>
                      {t("layout.nav.people")}
                    </NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Divider />
                  <LinkContainer exact to={`${match.url}/performance_settings`}>
                    <NavDropdown.Item>
                      {t("layout.nav.performanceSettings")}
                    </NavDropdown.Item>
                  </LinkContainer>
                  {showFeedbackReasonsLink ? (
                    <LinkContainer exact to={`${match.url}/feedback_reasons`}>
                      <NavDropdown.Item>
                        {t("layout.nav.feedbackReasons")}
                      </NavDropdown.Item>
                    </LinkContainer>
                  ) : (
                    <></>
                  )}
                  <NavDropdown.Divider />
                  <LinkContainer exact to={`${match.url}/employment_types`}>
                    <NavDropdown.Item>
                      {t("layout.nav.employmentTypes")}
                    </NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>

                <LinkContainer exact to={`${match.url}/schedules`}>
                  <Nav.Link>{t("layout.nav.schedules")}</Nav.Link>
                </LinkContainer>
                <LinkContainer exact to={`${match.url}/aos_templates`}>
                  <Nav.Link>{t("layout.nav.aosTemplates")}</Nav.Link>
                </LinkContainer>
                <NavDropdown
                  title={t("layout.nav.metadata")}
                  id="nav-dropdown"
                  className={metadataDropdownSelected ? "active" : ""}
                >
                  <LinkContainer exact to={`${match.url}/metadata_types`}>
                    <NavDropdown.Item>
                      {t("layout.nav.metadataTypes")}
                    </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer exact to={`${match.url}/metadata_layout`}>
                    <NavDropdown.Item>
                      {t("layout.nav.metadataLayout")}
                    </NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
                <LinkContainer exact to={`${match.url}/audit_logs`}>
                  <Nav.Link>{t("layout.nav.auditLogs")}</Nav.Link>
                </LinkContainer>
              </>
            )}
          </Nav>
        )}
      </header>
      <div className="mt-3">
        <Route exact path={`${match.path}`} component={BusinessProfile} />
        <Route exact path={`${match.path}/employees`} component={Employments} />
        <Route exact path={`${match.path}/schedules`} component={Schedules} />
        <Route
          exact
          path={`${match.path}/break_types`}
          component={BreakTypes}
        />
        <Route
          exact
          path={`${match.path}/employment_types`}
          component={EmploymentTypes}
        />
        <Route
          exact
          path={[
            `${match.path}/employment_types/create`,
            `${match.path}/employment_types/edit/:employment_type_id`,
          ]}
          component={EmploymentTypesProfile}
        />
        <Route
          exact
          path={[
            `${match.path}/break_types/create`,
            `${match.path}/break_types/edit/:break_type_id`,
          ]}
          component={BreakTypesProfile}
        />
        <Route
          exact
          path={`${match.path}/people_settings`}
          component={PeopleSettingsProfile}
        />
        <Route
          exact
          path={`${match.path}/time_off_types`}
          component={TimeOffTypes}
        />
        <Route
          exact
          path={[
            `${match.path}/time_off_types/create`,
            `${match.path}/time_off_types/edit/:time_off_type_id`,
          ]}
          component={TimeOffTypesProfile}
        />
        <Route
          exact
          path={`${match.path}/metadata_types`}
          component={MetadataTypes}
        />
        <Route
          exact
          path={[
            `${match.path}/metadata_types/create`,
            `${match.path}/metadata_types/edit/:metadata_type_id`,
          ]}
          component={MetadataTypesProfile}
        />
        <Route
          path={`${match.path}/metadata_layout`}
          component={MetadataLayout}
        />
        <Route
          exact
          path={`${match.path}/aos_templates`}
          component={AOSTemplates}
        />
        <Route
          exact
          path={`${match.path}/skill_levels`}
          component={SkillLevels}
        />
        <Route exact path={`${match.path}/audit_logs`} component={AuditLogs} />
        <Route
          exact
          path={`${match.path}/performance_settings`}
          component={PerformanceSettings}
        />
        <Route
          path={`${match.path}/feedback_reasons`}
          component={FeedbackReasonsSettings}
        />
      </div>
      <footer />
    </>
  );
};

// Returns the current sub route and options based on it
function useBusinessLayoutNavigation(isNewBusiness: boolean) {
  const router = useAppRouter();

  const defaultOptions = {
    hideNav: false,
  };

  if (!isNewBusiness && router.pathname.includes("/skill_levels")) {
    return [ActiveSubRouteLocation.SkillLevel, defaultOptions] as const;
  }

  if (!isNewBusiness && router.pathname.includes("/break_types")) {
    if (router.pathname.endsWith("/break_types")) {
      return [ActiveSubRouteLocation.BreakType, defaultOptions] as const;
    }
    return [ActiveSubRouteLocation.BreakTypeEdit, { hideNav: true }] as const;
  }

  if (!isNewBusiness && router.pathname.includes("/time_off_types")) {
    if (router.pathname.endsWith("/time_off_types")) {
      return [ActiveSubRouteLocation.TimeOffType, defaultOptions] as const;
    }
    return [ActiveSubRouteLocation.TimeOffTypeEdit, { hideNav: true }] as const;
  }

  if (!isNewBusiness && router.pathname.includes("/metadata_types")) {
    if (router.pathname.endsWith("/metadata_types")) {
      return [ActiveSubRouteLocation.MetadataType, defaultOptions] as const;
    }
    return [
      ActiveSubRouteLocation.MetadataTypeEdit,
      { hideNav: true },
    ] as const;
  }

  if (!isNewBusiness && router.pathname.includes("/metadata_layout")) {
    return [ActiveSubRouteLocation.MetadataLayouts, defaultOptions] as const;
  }

  if (router.pathname.endsWith("/employees")) {
    return [ActiveSubRouteLocation.Employees, defaultOptions] as const;
  }

  if (router.pathname.endsWith("/aos_templates")) {
    return [ActiveSubRouteLocation.AosTemplates, defaultOptions] as const;
  }

  if (router.pathname.endsWith("/schedules")) {
    return [ActiveSubRouteLocation.Schedule, defaultOptions] as const;
  }

  if (router.pathname.endsWith("/people_settings")) {
    return [ActiveSubRouteLocation.PeopleSettings, defaultOptions] as const;
  }

  if (router.pathname.endsWith("/employment_types")) {
    return [ActiveSubRouteLocation.EmploymentType, defaultOptions] as const;
  }

  if (
    router.pathname.endsWith("/feedback_reasons/disciplinary_actions") ||
    router.pathname.endsWith("/feedback_reasons/commendations")
  ) {
    return [ActiveSubRouteLocation.FeedbackReasons, defaultOptions] as const;
  }

  if (router.pathname.endsWith("/performance_settings")) {
    return [
      ActiveSubRouteLocation.PerformanceSettingsLoc,
      defaultOptions,
    ] as const;
  }

  return [ActiveSubRouteLocation.General, defaultOptions] as const;
}

export default BusinessLayout;
