/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type BusinessService_SearchStackForEmployments_QueryVariables = {
    userId?: string | null | undefined;
};
export type BusinessService_SearchStackForEmployments_QueryResponse = {
    readonly searchStackForEmployments: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly computedName: string;
                readonly acceptedInvite: boolean;
                readonly deleted: boolean;
                readonly email: string;
                readonly during: string | null;
                readonly createdAt: string;
                readonly updatedAt: string;
                readonly business: {
                    readonly id: string;
                    readonly businessName: string;
                };
            } | null;
        } | null> | null;
    };
};
export type BusinessService_SearchStackForEmployments_Query = {
    readonly response: BusinessService_SearchStackForEmployments_QueryResponse;
    readonly variables: BusinessService_SearchStackForEmployments_QueryVariables;
};



/*
query BusinessService_SearchStackForEmployments_Query(
  $userId: ID
) {
  searchStackForEmployments(userId: $userId) {
    edges {
      node {
        id
        computedName
        acceptedInvite
        deleted
        email
        during
        createdAt
        updatedAt
        business {
          id
          businessName
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "userId",
        "variableName": "userId"
      }
    ],
    "concreteType": "EmploymentConnection",
    "kind": "LinkedField",
    "name": "searchStackForEmployments",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EmploymentEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Employment",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "computedName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "acceptedInvite",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "deleted",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "during",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "createdAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updatedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Business",
                "kind": "LinkedField",
                "name": "business",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "businessName",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BusinessService_SearchStackForEmployments_Query",
    "selections": (v2/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BusinessService_SearchStackForEmployments_Query",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "c24d8d7b38900a91d6ad510f34928a83",
    "id": null,
    "metadata": {},
    "name": "BusinessService_SearchStackForEmployments_Query",
    "operationKind": "query",
    "text": "query BusinessService_SearchStackForEmployments_Query(\n  $userId: ID\n) {\n  searchStackForEmployments(userId: $userId) {\n    edges {\n      node {\n        id\n        computedName\n        acceptedInvite\n        deleted\n        email\n        during\n        createdAt\n        updatedAt\n        business {\n          id\n          businessName\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '781ab91e11b40722b7b17905211816ff';
export default node;
