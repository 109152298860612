import React, { Component } from "react";
import { FormikContextType } from "formik";
import cloneDeep from "lodash/cloneDeep";
import Button from "react-bootstrap/Button";
import { Translation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons/faTrashAlt";

// eslint-disable-next-line @typescript-eslint/no-use-before-define
type Props = typeof RemoveIcon.defaultProps & {
  formikContext: FormikContextType<any>;
  fieldKey: string;
  index: number;
  disabled?: boolean;
  className?: string;
};

type State = {};

export default class RemoveIcon extends Component<Props> {
  static defaultProps = {
    className: "pl-0 pr-0 show-on-hover",
  };

  render() {
    const { formikContext, fieldKey, index, disabled, className } = this.props;
    return (
      <Translation>
        {(t) => (
          <Button
            disabled={disabled}
            variant="link"
            className={`close ${className || ""}`}
            aria-label={t("form.actions.remove")}
            onClick={() => {
              const helpers = formikContext.getFieldHelpers(fieldKey);
              const fields = formikContext.getFieldProps(fieldKey);
              const temp = cloneDeep(fields.value);
              temp.splice(index, 1);
              helpers.setValue(temp);
            }}
          >
            <span aria-hidden="true">
              <FontAwesomeIcon size="sm" color="#8C8C8C" icon={faTrashAlt} />
            </span>
          </Button>
        )}
      </Translation>
    );
  }
}
