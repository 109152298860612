import React, { Component } from "react";
import { createFragmentContainer, graphql } from "react-relay";
import { Link, match } from "react-router-dom";
import { WithTranslation, withTranslation } from "react-i18next";

import { toRelative } from "../../../../utils/utility";
import { AccountRow_viewer } from "./__generated__/AccountRow_viewer.graphql";
import { IStack } from "../../../../models/common";
import AccountBadge from "./AccountBadge";

type Props = WithTranslation & {
  viewer: AccountRow_viewer;
  match: match<any>;
  stacksById: Map<string, IStack>;
};

export class AccountRow extends Component<Props> {
  render() {
    const { t, stacksById } = this.props;

    const user = this.props.viewer;
    return (
      <tr key={user.id}>
        <td>
          <Link to={`/account/${user.id}`}>{user.email}</Link>
        </td>
        <td>
          <AccountBadge user={user} />
        </td>
        <td>{user.locale}</td>
        <td>
          {user.userStacks.map((stack, index) => {
            const { stackId } = stack;
            const stackInfo = stacksById?.get(stackId);
            return (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`${user.id}-${index}`}>
                <Link to={`/stack/${stackId}`}>{stackInfo?.stackCode}</Link>
              </div>
            );
          })}
        </td>
        <td>
          {user.acceptedPolicyAt
            ? t("table.rows.acceptedPrivacyPolicy", {
                relativeTime: toRelative(user.acceptedPolicyAt as string, {
                  defaultValue: "-",
                }),
                version: user.acceptedPolicyVersion || "-",
              })
            : "-"}
        </td>
        <td>
          {toRelative(user.lastSignInAt as string, { defaultValue: "-" })}
        </td>
        <td>{toRelative(user.updatedAt as string, { defaultValue: "-" })}</td>
      </tr>
    );
  }
}

export default createFragmentContainer(
  withTranslation("accounts")(AccountRow),
  {
    viewer: graphql`
      fragment AccountRow_viewer on User {
        id
        email
        confirmedAt
        confirmationSentAt
        confirmationToken
        lockedMinutesRemaining
        deleted
        locale
        lastSignInAt
        acceptedPolicyAt
        acceptedPolicyVersion
        updatedAt
        userStacks {
          stackId
          synced
        }
      }
    `,
  },
);
