import React, { Component, FunctionComponent } from "react";
import { graphql, QueryRenderer } from "react-relay";
import without from "lodash/without";
import { Link, RouteComponentProps } from "react-router-dom";
import { Translation } from "react-i18next";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { BusinessContext } from "../../Context/BusinessContext";
import { EmploymentSchedules_InternalQuery } from "./__generated__/EmploymentSchedules_InternalQuery.graphql";
import { Id } from "../../../models/common";
import { toRelative } from "../../../utils/utility";
import {
  ScheduleService,
  ScheduleByIdsType,
} from "../Services/ScheduleService";
import Loader from "../../common/Loader";

const StyledSpan = styled.span`
  color: ${(props) => props.theme.bluePrimary};
  &.inherited {
    color: ${(props) => props.theme.grey100};
  }
`;

const EmploymentsQuery = graphql`
  query EmploymentSchedules_InternalQuery(
    $businessId: ID!
    $employmentId: ID!
  ) {
    employments(businessId: $businessId, ids: [$employmentId]) {
      edges {
        node {
          id
          employmentSchedules {
            scheduleId
            defined
            inherited
            groupAdmin
            inheritedGroupAdmin
            scheduleManager
            inheritedScheduleManager
            scheduleManagerWithPay
            inheritedScheduleManagerWithPay
            shiftManager
            inheritedShiftManager
            schedulable
            inheritedSchedulable
            updatedAt
          }
        }
      }
    }
  }
`;

interface MatchParams {
  business_id: Id;
  stack_id: Id;
  employment_id: Id;
}

type Props = RouteComponentProps<MatchParams> & {};

type State = {
  schedules: Map<Id, ScheduleByIdsType>;
};

export default class EmploymentSchedules extends Component<Props, State> {
  static contextType = BusinessContext;

  constructor(props: any) {
    super(props);

    this.state = {
      schedules: new Map<Id, ScheduleByIdsType>(),
    };
  }

  private async fetchSchedules(businessId: Id, scheduleIds: Id[]) {
    const { environment } = this.context;
    const result = await ScheduleService.getSchedulesByIds(
      environment,
      businessId,
      scheduleIds,
    );

    this.setState((prevState) => ({
      schedules: scheduleIds.reduce((r, i) => {
        const schedule = result.get(i);
        r.set(i, schedule);
        return r;
      }, prevState.schedules),
    }));
  }

  render() {
    const properties = this.props;
    const { environment } = this.context;
    const { schedules } = this.state;

    const {
      match: { params },
    } = properties;
    const {
      business_id: businessId,
      stack_id: stackId,
      employment_id: employmentId,
    } = params;

    return (
      <Translation ns="employment">
        {(t) => (
          <Card body>
            <QueryRenderer<EmploymentSchedules_InternalQuery>
              environment={environment}
              query={EmploymentsQuery}
              variables={{
                businessId,
                employmentId,
              }}
              render={({ error, props }) => {
                if (error) {
                  return <div>Error!</div>;
                }
                if (!props) {
                  return <Loader />;
                }

                const node = props.employments?.edges?.[0]?.node ?? null;
                if (!node || !node.employmentSchedules) {
                  return null;
                }

                const idsFound = node.employmentSchedules.map(
                  (i) => i.scheduleId,
                );

                const loaded = Array.from(schedules.keys());
                const idsToFetch = without(idsFound, ...loaded);
                if (idsToFetch.length) {
                  this.fetchSchedules(businessId, idsToFetch);
                  return null;
                }

                const employmentSchedules = node.employmentSchedules.map(
                  (employmentSchedule) => {
                    const schedule = schedules.get(
                      employmentSchedule.scheduleId,
                    );
                    return (
                      <tr key={node.id}>
                        <td>
                          <Link
                            to={`/stack/${stackId}/business/${businessId}/schedule/${employmentSchedule.scheduleId}`}
                          >
                            {schedule?.scheduleName ??
                              employmentSchedule.scheduleId}
                          </Link>
                        </td>
                        <td>
                          <PermissionIcon
                            base={employmentSchedule.groupAdmin}
                            inherited={employmentSchedule.inheritedGroupAdmin}
                          />
                        </td>
                        <td>
                          <PermissionIcon
                            base={employmentSchedule.scheduleManager}
                            inherited={
                              employmentSchedule.inheritedScheduleManager
                            }
                          />
                        </td>
                        <td>
                          <PermissionIcon
                            base={employmentSchedule.scheduleManagerWithPay}
                            inherited={
                              employmentSchedule.inheritedScheduleManagerWithPay
                            }
                          />
                        </td>
                        <td>
                          <PermissionIcon
                            base={employmentSchedule.shiftManager}
                            inherited={employmentSchedule.inheritedShiftManager}
                          />
                        </td>
                        <td>
                          <PermissionIcon
                            base={employmentSchedule.schedulable}
                            inherited={employmentSchedule.inheritedSchedulable}
                          />
                        </td>
                        <td>
                          {toRelative(employmentSchedule.updatedAt as string, {
                            defaultValue: "-",
                          })}
                        </td>
                      </tr>
                    );
                  },
                );

                return (
                  <Table hover size="sm">
                    <thead>
                      <tr>
                        <th>{t("employmentSchedulesTable.headers.name")}</th>
                        <th>
                          {t("employmentSchedulesTable.headers.groupAdmin")}
                        </th>
                        <th>
                          {t(
                            "employmentSchedulesTable.headers.scheduleManager",
                          )}
                        </th>
                        <th>
                          {t(
                            "employmentSchedulesTable.headers.scheduleManagerWithPay",
                          )}
                        </th>
                        <th>
                          {t("employmentSchedulesTable.headers.shiftManager")}
                        </th>
                        <th>
                          {t("employmentSchedulesTable.headers.schedulable")}
                        </th>
                        <th>
                          {t("employmentSchedulesTable.headers.lastUpdate")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>{employmentSchedules}</tbody>
                  </Table>
                );
              }}
            />
          </Card>
        )}
      </Translation>
    );
  }
}

type PermissionIconProps = {
  base: boolean;
  inherited: boolean;
};

const PermissionIcon: FunctionComponent<PermissionIconProps> = (
  p: PermissionIconProps,
) => {
  const { base, inherited } = p;

  if (!base && !inherited) {
    return null;
  }

  return (
    <StyledSpan className={inherited ? "inherited" : ""}>
      <FontAwesomeIcon icon={faCheck} />
    </StyledSpan>
  );
};
