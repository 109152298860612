/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type EmploymentTable_viewer = {
    readonly employments: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly firstName: string;
                readonly lastName: string;
                readonly email: string;
                readonly userId: string | null;
                readonly acceptedInvite: boolean;
                readonly deleted: boolean;
                readonly securityRole: string;
                readonly updatedAt: string;
                readonly userEmail: string | null;
                readonly during: string | null;
                readonly businessInvite: {
                    readonly id: string;
                    readonly expiryTime: string;
                } | null;
            } | null;
        } | null> | null;
        readonly pageInfo: {
            readonly hasNextPage: boolean;
            readonly endCursor: string | null;
        };
    };
    readonly " $refType": "EmploymentTable_viewer";
};
export type EmploymentTable_viewer$data = EmploymentTable_viewer;
export type EmploymentTable_viewer$key = {
    readonly " $data"?: EmploymentTable_viewer$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"EmploymentTable_viewer">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "acceptedInvite"
    },
    {
      "kind": "RootArgument",
      "name": "after"
    },
    {
      "kind": "RootArgument",
      "name": "businessId"
    },
    {
      "kind": "RootArgument",
      "name": "currentlyEmployed"
    },
    {
      "kind": "RootArgument",
      "name": "deleted"
    },
    {
      "kind": "RootArgument",
      "name": "pageSize"
    },
    {
      "kind": "RootArgument",
      "name": "searchValue"
    },
    {
      "kind": "RootArgument",
      "name": "userLinked"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "pageSize",
        "cursor": "after",
        "direction": "forward",
        "path": [
          "employments"
        ]
      }
    ]
  },
  "name": "EmploymentTable_viewer",
  "selections": [
    {
      "alias": "employments",
      "args": [
        {
          "kind": "Variable",
          "name": "acceptedInvite",
          "variableName": "acceptedInvite"
        },
        {
          "kind": "Variable",
          "name": "businessId",
          "variableName": "businessId"
        },
        {
          "kind": "Variable",
          "name": "currentlyEmployed",
          "variableName": "currentlyEmployed"
        },
        {
          "kind": "Variable",
          "name": "deleted",
          "variableName": "deleted"
        },
        {
          "kind": "Variable",
          "name": "search",
          "variableName": "searchValue"
        },
        {
          "kind": "Variable",
          "name": "userLinked",
          "variableName": "userLinked"
        }
      ],
      "concreteType": "EmploymentConnection",
      "kind": "LinkedField",
      "name": "__EmploymentTable_employments_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EmploymentEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Employment",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "firstName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lastName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "email",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "userId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "acceptedInvite",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "deleted",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "securityRole",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "updatedAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "userEmail",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "during",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "BusinessInvite",
                  "kind": "LinkedField",
                  "name": "businessInvite",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "expiryTime",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "InternalQuery",
  "abstractKey": null
};
})();
(node as any).hash = 'ed203c4ab05578beca5faa7e204c8ed6';
export default node;
