/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AosQueryMutations_BasicProfile_Fragment = {
    readonly id: string;
    readonly analyticsEnabled: boolean;
    readonly aosConfigSchema: unknown;
    readonly populateScheduleEnabled: boolean;
    readonly autoPopulateScheduleEnabled: boolean;
    readonly autoPopulateScheduleDaysInAdvance: number;
    readonly autoPopulateScheduleAtTime: number;
    readonly labourCostPercentageEnabled: boolean;
    readonly labourCostPercentage: ReadonlyArray<number> | null;
    readonly aosConfig: {
        readonly schedulingDayStart: string | null;
        readonly addUnderstaffingShifts: boolean | null;
        readonly weeklyWorkhoursMin: number | null;
        readonly weeklyWorkhoursMax: number | null;
        readonly shiftMin: number | null;
        readonly shiftMax: number | null;
        readonly shiftMinAbsolute: number | null;
        readonly shiftGapMin: number | null;
        readonly shiftMaxStart: string | null;
        readonly maxRolesPerShift: number | null;
        readonly minShiftPartLength: number | null;
        readonly breakTrigger: number | null;
        readonly breakLength: number | null;
        readonly breakMinStart: number | null;
        readonly breakMaxStart: number | null;
        readonly breakEndPad: number | null;
        readonly includeRoles: ReadonlyArray<string> | null;
        readonly demandOnly: ReadonlyArray<string> | null;
        readonly bonusRoles: ReadonlyArray<string> | null;
        readonly ignoreBreakRoles: ReadonlyArray<string> | null;
        readonly flexibleRole: string | null;
        readonly coreObeysAosRules: boolean | null;
        readonly skipSchedulingManagers: boolean | null;
        readonly skipSchedulingCore: boolean | null;
        readonly addCoreBreaks: boolean | null;
        readonly optimizeCoreBreaks: boolean | null;
        readonly weeklyDayparts: unknown | null;
        readonly daypartRanks: unknown | null;
        readonly planningOrder: ReadonlyArray<string> | null;
        readonly overstaffingPenalty: number | null;
        readonly skillPreference: number | null;
        readonly shiftLengthPreference: number | null;
        readonly penaltyShortParts: number | null;
        readonly penaltyShortPartsCutoff: number | null;
        readonly costEqualLabor: number | null;
        readonly weightEqualLaborByRating: ReadonlyArray<number> | null;
        readonly applyRule24HoursRest: boolean | null;
        readonly maxWorkdaysCalweek: number | null;
        readonly maxWorkdaysWorkweek: number | null;
        readonly overstaffingByRole: ReadonlyArray<string> | null;
        readonly understaffingByRole: ReadonlyArray<string> | null;
        readonly shiftConfig: unknown | null;
    } | null;
    readonly " $refType": "AosQueryMutations_BasicProfile_Fragment";
};
export type AosQueryMutations_BasicProfile_Fragment$data = AosQueryMutations_BasicProfile_Fragment;
export type AosQueryMutations_BasicProfile_Fragment$key = {
    readonly " $data"?: AosQueryMutations_BasicProfile_Fragment$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"AosQueryMutations_BasicProfile_Fragment">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AosQueryMutations_BasicProfile_Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "analyticsEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "aosConfigSchema",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "populateScheduleEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "autoPopulateScheduleEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "autoPopulateScheduleDaysInAdvance",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "autoPopulateScheduleAtTime",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "labourCostPercentageEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "labourCostPercentage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AosConfig",
      "kind": "LinkedField",
      "name": "aosConfig",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "schedulingDayStart",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "addUnderstaffingShifts",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "weeklyWorkhoursMin",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "weeklyWorkhoursMax",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shiftMin",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shiftMax",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shiftMinAbsolute",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shiftGapMin",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shiftMaxStart",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "maxRolesPerShift",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "minShiftPartLength",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "breakTrigger",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "breakLength",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "breakMinStart",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "breakMaxStart",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "breakEndPad",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "includeRoles",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "demandOnly",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bonusRoles",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "ignoreBreakRoles",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "flexibleRole",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "coreObeysAosRules",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "skipSchedulingManagers",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "skipSchedulingCore",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "addCoreBreaks",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "optimizeCoreBreaks",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "weeklyDayparts",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "daypartRanks",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "planningOrder",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "overstaffingPenalty",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "skillPreference",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shiftLengthPreference",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "penaltyShortParts",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "penaltyShortPartsCutoff",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "costEqualLabor",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "weightEqualLaborByRating",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "applyRule24HoursRest",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "maxWorkdaysCalweek",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "maxWorkdaysWorkweek",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "overstaffingByRole",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "understaffingByRole",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "shiftConfig",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Schedule",
  "abstractKey": null
};
(node as any).hash = '788499232e2bcc3f0612066815b68529';
export default node;
