import React, { Component } from "react";
import { ConfigurationObject, SessionInfo } from "../models/common";
import Login from "./IDM/external/Login";
import Authentication from "../utils/authentication";
import { ModalContextProvider } from "./Context/ModalContextLegacy";
import AuthenticatedApp from "./IDM/external/AuthenticatedApp";

import "../styles/App.scss";
import "react-toastify/dist/ReactToastify.css";
import SignOutMutation from "./IDM/external/mutations/SignOutMutation";
import { idmExternalEnvironment } from "../environment";
import { SignOutMutationResponse } from "./IDM/external/mutations/__generated__/SignOutMutation.graphql";
import StackService from "./IDM/internal/Stack/StackService";
import ServerError, { ServerErrorCode } from "../utils/server-error";

type State = {
  session: SessionInfo | null;
};

type Props = {
  configuration: ConfigurationObject;
};

class App extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const s = Authentication.restoreSession();

    this.state = {
      session: s,
    };
  }

  private async handleLogin(i: SessionInfo) {
    Authentication.storeSession(i);
    try {
      await StackService.getAllStacks();
      // if user has access to internal query
      this.setState({
        session: i,
      });
    } catch (e) {
      const { source } = (e as any) || {};
      if (source) {
        const serverError = new ServerError(source);
        if (serverError.code === ServerErrorCode.AuthorizationError) {
          Authentication.clearSession();
          this.setState({
            session: null,
          });
          return;
        }
      }

      // unhandled
      throw e;
    }
  }

  handleLogout() {
    SignOutMutation(
      idmExternalEnvironment,
      (response: SignOutMutationResponse, errors?: any) => {
        if (errors) {
          // eslint-disable-next-line no-console
          console.debug("error signing out: ", errors);
          return;
        }

        const { signOut } = response;
        if (signOut) {
          Authentication.clearSession();
          this.setState({
            session: null,
          });
        }
      },
    );
  }

  render() {
    const { session } = this.state;
    const { configuration } = this.props;

    if (session === null || session.authToken == null || session.user == null) {
      return (
        <ModalContextProvider>
          <Login
            deploymentConfiguration={configuration}
            onLoggedIn={(s: SessionInfo) => this.handleLogin(s)}
          />
        </ModalContextProvider>
      );
    }
    return (
      <AuthenticatedApp
        user={session.user}
        handleLogout={this.handleLogout.bind(this)}
        stackCode={configuration?.stack}
      />
    );
  }
}

export default App;
