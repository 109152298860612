import React from "react";
import { graphql, QueryRenderer } from "react-relay";
import { RouteComponentProps } from "react-router-dom";
import Card from "react-bootstrap/Card";
import { idmInternalEnvironment } from "../../../../environment";
import Profile from "./Profile";
import { StackProfile_Query } from "./__generated__/StackProfile_Query.graphql";
import Loader from "../../../common/Loader";

const StackProfileQuery = graphql`
  query StackProfile_Query($stackId: ID!) {
    stacks(ids: [$stackId]) {
      edges {
        node {
          ...Profile_stack
        }
      }
    }
  }
`;
interface MatchParams {
  stack_id: string;
}

type Props = RouteComponentProps<MatchParams> & {};

type State = {};
export default class StackProfile extends React.PureComponent<Props, State> {
  render() {
    const properties = this.props;
    const { match } = properties;
    const { params } = match;
    const stackId = params.stack_id;
    const environment = idmInternalEnvironment;

    const getProfile = (stack: any) => (
      <Card body>
        <Profile {...properties} stack={stack} environment={environment} />
      </Card>
    );

    if (!stackId) {
      // Create stack
      return getProfile(null);
    }

    return (
      <QueryRenderer<StackProfile_Query>
        environment={environment}
        query={StackProfileQuery}
        variables={{
          stackId,
        }}
        render={({ error, props }) => {
          if (error) {
            return <div>Error!</div>;
          }
          if (!props) {
            return <Loader />;
          }

          const { edges } = props.stacks;
          if (edges == null || edges.length === 0) {
            return <div>Error! Stack not found</div>;
          }

          return getProfile((edges as any)[0].node);
        }}
      />
    );
  }
}
