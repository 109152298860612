import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

// eslint-disable-next-line @typescript-eslint/no-use-before-define
type Props = typeof ButtonIcon.defaultProps & {
  disabled?: boolean;
  className?: string;
  icon: IconProp;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
};

type State = {};

export default class ButtonIcon extends Component<Props> {
  static defaultProps = {
    className: "pl-0 pr-0 pt-0 pb-0 show-on-hover",
  };

  render() {
    const { onClick, disabled, className, icon } = this.props;
    return (
      <Button
        disabled={disabled}
        variant="link"
        className={className}
        onClick={onClick}
      >
        <FontAwesomeIcon icon={icon} />
      </Button>
    );
  }
}
