import React, { Component } from "react";
import { FormikContext } from "formik";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// eslint-disable-next-line import/no-cycle
import Field from "./Field";
import { SchemaFieldType } from "./models";
import { getSchemaFieldType } from "./formUtilities";

type State = {};

// eslint-disable-next-line @typescript-eslint/no-use-before-define
type Props = typeof PayDates.defaultProps & {
  value: number[];
  fieldKey: string;
  disabled?: boolean;
  onChange: (newValue: number[] | null) => void;
  type: SchemaFieldType;
};

export default class PayDates extends Component<Props, State> {
  static defaultProps = {};

  shouldComponentUpdate(nextProps: Props) {
    const { value, disabled } = nextProps;
    // Update component when value or disabled property changes
    return value !== this.props.value || disabled !== this.props.disabled;
  }

  render() {
    const { fieldKey, disabled, type } = this.props;

    return (
      <FormikContext.Consumer>
        {() => {
          return (
            <>
              {[0, 1].map((i: number) => {
                const indexKey = `${fieldKey}[${i}]`;
                return (
                  <Row key={indexKey}>
                    <Col md={10}>
                      <Field
                        schemaFieldType={getSchemaFieldType(
                          type.ofType as SchemaFieldType,
                        )}
                        fieldKey={indexKey}
                        md={12}
                        lg={12}
                        label={`Pay cycle ${i + 1}`}
                        disabled={disabled != null ? disabled : false}
                        hideError
                      />
                    </Col>
                  </Row>
                );
              })}
            </>
          );
        }}
      </FormikContext.Consumer>
    );
  }
}
