import React from "react";
import { Route, RouteComponentProps } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import { LinkContainer } from "react-router-bootstrap";
import AOSTemplateProfile from "./AOSTemplateProfile";

interface MatchParams {
  business_id: string;
  aos_template_id?: string;
  stack_id: string;
  clone_from_id?: string;
}

type Props = RouteComponentProps<MatchParams> & {};

type State = {};
export default class AOSTemplateLayout extends React.Component<Props, State> {
  render() {
    const properties = this.props;
    const { match } = properties;

    return (
      <>
        <header className="sub-header-container">
          <Nav variant="tabs">
            <LinkContainer exact to={`${match.url}`}>
              <Nav.Link>Profile</Nav.Link>
            </LinkContainer>
          </Nav>
        </header>
        <div className="mt-3">
          <Route
            exact
            path={`${match.path}`}
            render={(p: RouteComponentProps<any>) => (
              <AOSTemplateProfile {...p} />
            )}
          />
        </div>
      </>
    );
  }
}
