import React, { PureComponent } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import without from "lodash/without";
import includes from "lodash/includes";
import styled from "styled-components";
import { BaseOption } from "../../models/common";

const StyledListGroupItem = styled(ListGroup.Item)`
  padding: 4px 6px;
  max-height: 32px;
  &.active {
    border-color: ${(props) => props.theme.borderColor};
  }
`;

type Props<T> = {
  options: BaseOption<T, number>[];
  value: T[];
  onChange: (newValue: T[]) => void;
  disabled?: boolean;
};

export default class DaysOfWeekSelector<T> extends PureComponent<Props<T>> {
  getOptionFromValue = (value: any[]) => {
    return (value || []).map((v) =>
      this.props.options.find((i) => i.value === v),
    );
  };

  render() {
    const { value, disabled, onChange, options } = this.props;
    const fieldValue = value || [];
    return (
      <ListGroup horizontal>
        {(options || []).map((option) => {
          // const optionsValue = options.map((o) => o.value);
          const isActive = includes(fieldValue, option.value);
          return (
            <StyledListGroupItem
              key={option.data}
              action
              disabled={disabled}
              active={isActive}
              type="button"
              onClick={() => {
                // toggle
                const newValue = !isActive
                  ? fieldValue.concat([option.value])
                  : without(fieldValue, option.value);
                onChange(newValue);
              }}
            >
              {option.label}
            </StyledListGroupItem>
          );
        })}
      </ListGroup>
    );
  }
}
