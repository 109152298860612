/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type Schedules_InternalQueryVariables = {
    businessId: string;
    searchValue: string;
    pageSize: number;
    after?: string | null | undefined;
};
export type Schedules_InternalQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"ScheduleTable_viewer">;
};
export type Schedules_InternalQuery = {
    readonly response: Schedules_InternalQueryResponse;
    readonly variables: Schedules_InternalQueryVariables;
};



/*
query Schedules_InternalQuery(
  $businessId: ID!
  $searchValue: String!
  $pageSize: Int!
  $after: String
) {
  ...ScheduleTable_viewer
}

fragment ScheduleTable_viewer on InternalQuery {
  schedules(businessId: $businessId, search: $searchValue, first: $pageSize, after: $after, isGroup: false) {
    edges {
      node {
        id
        scheduleName
        isGroup
        __typename
      }
      cursor
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "businessId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "pageSize"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "searchValue"
},
v4 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "businessId",
    "variableName": "businessId"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "pageSize"
  },
  {
    "kind": "Literal",
    "name": "isGroup",
    "value": false
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "searchValue"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "Schedules_InternalQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ScheduleTable_viewer"
      }
    ],
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "Schedules_InternalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "ScheduleConnection",
        "kind": "LinkedField",
        "name": "schedules",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ScheduleEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Schedule",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "scheduleName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isGroup",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "filters": [
          "businessId",
          "search",
          "isGroup"
        ],
        "handle": "connection",
        "key": "ScheduleTable_schedules",
        "kind": "LinkedHandle",
        "name": "schedules"
      }
    ]
  },
  "params": {
    "cacheID": "6ece5311865c4400d6b64ee61b599875",
    "id": null,
    "metadata": {},
    "name": "Schedules_InternalQuery",
    "operationKind": "query",
    "text": "query Schedules_InternalQuery(\n  $businessId: ID!\n  $searchValue: String!\n  $pageSize: Int!\n  $after: String\n) {\n  ...ScheduleTable_viewer\n}\n\nfragment ScheduleTable_viewer on InternalQuery {\n  schedules(businessId: $businessId, search: $searchValue, first: $pageSize, after: $after, isGroup: false) {\n    edges {\n      node {\n        id\n        scheduleName\n        isGroup\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '814fd8d7b7d711032df54224d2caa5ba';
export default node;
