/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ScheduleService_SchedulesByIds_QueryVariables = {
    businessId: string;
    ids?: Array<string> | null | undefined;
};
export type ScheduleService_SchedulesByIds_QueryResponse = {
    readonly schedules: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly scheduleName: string;
                readonly isGroup: boolean;
            } | null;
        } | null> | null;
    };
};
export type ScheduleService_SchedulesByIds_Query = {
    readonly response: ScheduleService_SchedulesByIds_QueryResponse;
    readonly variables: ScheduleService_SchedulesByIds_QueryVariables;
};



/*
query ScheduleService_SchedulesByIds_Query(
  $businessId: ID!
  $ids: [ID!]
) {
  schedules(businessId: $businessId, ids: $ids) {
    edges {
      node {
        id
        scheduleName
        isGroup
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ids"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Variable",
        "name": "ids",
        "variableName": "ids"
      }
    ],
    "concreteType": "ScheduleConnection",
    "kind": "LinkedField",
    "name": "schedules",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ScheduleEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Schedule",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "scheduleName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isGroup",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ScheduleService_SchedulesByIds_Query",
    "selections": (v1/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ScheduleService_SchedulesByIds_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f9b63820199ef7651de15bae9a064520",
    "id": null,
    "metadata": {},
    "name": "ScheduleService_SchedulesByIds_Query",
    "operationKind": "query",
    "text": "query ScheduleService_SchedulesByIds_Query(\n  $businessId: ID!\n  $ids: [ID!]\n) {\n  schedules(businessId: $businessId, ids: $ids) {\n    edges {\n      node {\n        id\n        scheduleName\n        isGroup\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'e2fd0d8bed1ee0cd6283f9c1fb98ab35';
export default node;
