/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type EmploymentFeedbackTypeEnum = "commendation" | "disciplinary_action" | "%future added value";
export type FeedbackReasonsQueriesMutations_GetEmploymentFeedbackReasonsQueryVariables = {
    businessId: string;
    feedbackType?: Array<EmploymentFeedbackTypeEnum> | null | undefined;
    includeDeleted?: boolean | null | undefined;
};
export type FeedbackReasonsQueriesMutations_GetEmploymentFeedbackReasonsQueryResponse = {
    readonly employmentFeedbackReasons: {
        readonly nodes: ReadonlyArray<{
            readonly id: string;
            readonly feedbackType: EmploymentFeedbackTypeEnum;
            readonly description: string;
            readonly defaultMessage: string | null;
            readonly deleted: boolean;
        } | null> | null;
    };
};
export type FeedbackReasonsQueriesMutations_GetEmploymentFeedbackReasonsQuery = {
    readonly response: FeedbackReasonsQueriesMutations_GetEmploymentFeedbackReasonsQueryResponse;
    readonly variables: FeedbackReasonsQueriesMutations_GetEmploymentFeedbackReasonsQueryVariables;
};



/*
query FeedbackReasonsQueriesMutations_GetEmploymentFeedbackReasonsQuery(
  $businessId: ID!
  $feedbackType: [EmploymentFeedbackTypeEnum!]
  $includeDeleted: Boolean
) {
  employmentFeedbackReasons(businessId: $businessId, feedbackType: $feedbackType, includeDeleted: $includeDeleted) {
    nodes {
      id
      feedbackType
      description
      defaultMessage
      deleted
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "feedbackType"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "includeDeleted"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Variable",
        "name": "feedbackType",
        "variableName": "feedbackType"
      },
      {
        "kind": "Variable",
        "name": "includeDeleted",
        "variableName": "includeDeleted"
      }
    ],
    "concreteType": "EmploymentFeedbackReasonConnection",
    "kind": "LinkedField",
    "name": "employmentFeedbackReasons",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "EmploymentFeedbackReason",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "feedbackType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "defaultMessage",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deleted",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FeedbackReasonsQueriesMutations_GetEmploymentFeedbackReasonsQuery",
    "selections": (v1/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FeedbackReasonsQueriesMutations_GetEmploymentFeedbackReasonsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7f48d1375b9a810a0f16293152476289",
    "id": null,
    "metadata": {},
    "name": "FeedbackReasonsQueriesMutations_GetEmploymentFeedbackReasonsQuery",
    "operationKind": "query",
    "text": "query FeedbackReasonsQueriesMutations_GetEmploymentFeedbackReasonsQuery(\n  $businessId: ID!\n  $feedbackType: [EmploymentFeedbackTypeEnum!]\n  $includeDeleted: Boolean\n) {\n  employmentFeedbackReasons(businessId: $businessId, feedbackType: $feedbackType, includeDeleted: $includeDeleted) {\n    nodes {\n      id\n      feedbackType\n      description\n      defaultMessage\n      deleted\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '65777c782facd070dc0d74df52585bc7';
export default node;
