import React, { CSSProperties } from "react";
import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";
import Button from "react-bootstrap/Button";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import Field from "../../../../../Form/Field";
import { StringFieldType } from "../../../../../Form/models";
import DynamicSelect from "../../../../../Form/DynamicSelect";
import VerticalSortableContext, {
  createStandardSortableId,
  handleSortableAdd,
  handleSortableDelete,
  SortableDragHandle,
  SortableTrashIcon,
} from "../../../../../Context/VerticalSortableContext";

type Props = {
  options: { label: string; value: string }[];
};

type EditViewModalForm = {
  groups: string[];
};

export default function SortableLayoutGroups(props: Props) {
  const { t } = useTranslation("metadata-types");

  const formikContext = useFormikContext<EditViewModalForm>();
  const fields = formikContext.getFieldProps("groups").value as string[];
  const helpers = formikContext.getFieldHelpers("groups");

  const canAdd =
    !fields.includes("") && fields?.length !== props.options.length;

  const onAdd = () => {
    if (canAdd) {
      handleSortableAdd(helpers, fields, "");
    }
  };

  const onDelete = (sortableId: string) =>
    handleSortableDelete(helpers, fields, sortableId);

  // Sortable id cannot be an object, but we can encode multiple pieces of data in the string using hashes (or any delimiter)
  const createSortableId = (field: string, index: number) =>
    createStandardSortableId(field === "" ? "empty" : field, index);

  const getSelectableOptions = (fieldName: string) => {
    return props.options.filter((option) => {
      if (fieldName !== option.value) {
        return !fields.includes(option.value);
      }
      return true;
    });
  };

  return (
    <VerticalSortableContext
      data={fields}
      updateListItems={helpers.setValue}
      items={fields.map((fieldName, index) =>
        createSortableId(fieldName, index),
      )}
    >
      {fields.map((fieldName, index) => {
        const itemKey = createSortableId(fieldName, index);
        return (
          <SortableGroupItem
            disabled={itemKey.includes("empty")}
            key={itemKey}
            sortableId={createSortableId(fieldName, index)}
            onDelete={onDelete}
          >
            <Field
              xs={7}
              md={7}
              lg={7}
              fieldKey={`groups[${index}]`}
              component={DynamicSelect}
              componentProps={{
                options: getSelectableOptions(fieldName),
                autoFocus: true,
              }}
              schemaFieldType={StringFieldType}
              hideError
            />
          </SortableGroupItem>
        );
      })}
      <Button disabled={!canAdd} onClick={() => onAdd()} variant="light">
        {t("metadataLayout.views.editModal.addGroup")}
      </Button>
    </VerticalSortableContext>
  );
}

function SortableGroupItem(props: {
  sortableId: string;
  disabled: boolean;
  onDelete: (sortableId: string) => void;
  children: React.ReactNode;
}) {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: props.sortableId,
    });

  return (
    <div
      ref={props.disabled ? null : setNodeRef}
      style={
        {
          transform: CSS.Transform.toString(transform),
          transition,
          display: "flex",
          flexDirection: "row",
          alignItems: "baseline",
        } as CSSProperties
      }
    >
      <SortableDragHandle
        disabled={props.disabled}
        {...attributes}
        {...listeners}
      />
      {props.children}
      <SortableTrashIcon onClick={() => props.onDelete(props.sortableId)} />
    </div>
  );
}
