import React, { Component } from "react";
import { FormikContext } from "formik";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import isArray from "lodash/isArray";
import styled, { css } from "styled-components";
import AddButton from "./AddButton";
import RemoveIcon from "./RemoveIcon";
// eslint-disable-next-line import/no-cycle
import Field from "./Field";
import {
  GroupName,
  IProperty,
  Kind,
  SchemaFieldType,
  SubGroupName,
} from "./models";
import { getSchemaFieldType } from "./formUtilities";

type State = {};

// eslint-disable-next-line @typescript-eslint/no-use-before-define
type Props<T> = typeof List.defaultProps & {
  value: T[];
  fieldKey: string;
  disabled?: boolean;
  type: SchemaFieldType;
  fields?: Map<GroupName, Map<SubGroupName, IProperty[]>>;
  defaultValue?: T;
  hideError?: boolean;
};

const StyledDiv = styled("div")<{ isNestedObject?: boolean }>`
  .row {
    button {
      top: 8px;
      position: absolute;
      left: 4px;
    }

    ${(props) =>
      props.isNestedObject &&
      css`
        &:first-child {
          button {
            top: 32px;
          }
        }
      `};
  }
`;

export default class List<T> extends Component<Props<T>, State> {
  static defaultProps = {
    defaultValue: "",
    hideError: false,
  };

  shouldComponentUpdate(nextProps: Props<T>) {
    const { value, disabled } = nextProps;
    // Update component when value or disabled property changes
    return value !== this.props.value || disabled !== this.props.disabled;
  }

  render() {
    const { fieldKey, value, type, fields, disabled, defaultValue, hideError } =
      this.props;

    const subType = type ? getSchemaFieldType(type) : undefined;
    const isNestedObject = subType && subType.kind === Kind.INPUT_OBJECT;

    return (
      <FormikContext.Consumer>
        {(formikContext) => {
          const meta = formikContext.getFieldMeta(fieldKey);
          const { error } = meta;

          const safeValue = value && Array.isArray(value) ? value : [];
          return (
            <StyledDiv isNestedObject={isNestedObject}>
              {safeValue.map((v: any, i: number) => {
                const indexKey = `${fieldKey}[${i}]`;
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <Row key={`list-${i}`} className="flex-nowrap">
                    <Col md={10}>
                      <Row>
                        <Field
                          schemaFieldType={subType}
                          fieldKey={indexKey}
                          md={12}
                          lg={12}
                          hideDescription={isNestedObject}
                          hideLabel={isNestedObject && i > 0}
                          hideError={
                            !isArray(error) || hideError || isNestedObject // hide if already shown error at parent level
                          }
                          fields={fields}
                          disabled={disabled}
                        />
                      </Row>
                    </Col>
                    <Col md={2}>
                      <RemoveIcon
                        formikContext={formikContext}
                        fieldKey={fieldKey}
                        index={i}
                        disabled={disabled}
                      />
                    </Col>
                  </Row>
                );
              })}
              <AddButton
                formikContext={formikContext}
                name={fieldKey}
                disabled={disabled}
                defaultValue={defaultValue}
              />
            </StyledDiv>
          );
        }}
      </FormikContext.Consumer>
    );
  }
}
