import React, { Component } from "react";
import { FormikErrors } from "formik";
import isObject from "lodash/isObject";
import { uniq } from "lodash";

// eslint-disable-next-line @typescript-eslint/no-use-before-define
type Props<T> = typeof ErrorSummary.defaultProps & {
  errors: FormikErrors<T>;
};

type State = {};

export default class ErrorSummary<T> extends Component<Props<T>> {
  static defaultProps = {};

  render() {
    const { errors } = this.props;

    const errorSummary = (e: FormikErrors<T>): string[] => {
      let result: string[] = [];
      Object.keys(e).forEach((errorKey) => {
        const errorValue = (e as Record<string, any>)[errorKey];
        if (isObject(errorValue)) {
          result = result.concat(errorSummary(errorValue as FormikErrors<T>));
        } else {
          result.push(errorValue);
        }
      });

      return result;
    };

    const getErrors = (e: FormikErrors<T>) => {
      const result = uniq(errorSummary(e));
      return result.map((error, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={`error-${index}`}>{error}</div>
      ));
    };

    return errors && Object.keys(errors).length ? (
      <div className="alert alert-danger" role="alert">
        {getErrors(errors)}
      </div>
    ) : null;
  }
}
