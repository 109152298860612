import React, { Component } from "react";
import Form from "react-bootstrap/Form";

type State = {};

export default class Searchable extends Component<Props, State> {
  static defaultProps = {
    className: "",
    placeholder: "search schedule...",
  };

  private onSearchChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { target } = event;
    const { value } = target;
    this.props.onSearchChange(value);
  }

  render() {
    const { searchValue, className, placeholder } = this.props;
    return (
      <div className={className}>
        <Form.Control
          type="search"
          name="searchValue"
          placeholder={placeholder}
          value={searchValue}
          onChange={this.onSearchChange.bind(this)}
        />
      </div>
    );
  }
}

type Props = {
  onSearchChange: (value: string) => void;
  searchValue: string;
  className: string;
  placeholder: string;
};
