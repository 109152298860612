/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type EmploymentTypeEnum = "casual" | "full_time" | "part_time" | "permanent" | "%future added value";
export type LegacyBreakTypeEnum = "meal" | "rest" | "unknown" | "%future added value";
export type BreakTypeQueriesMutations_GetScheduleBreakTypes_QueryVariables = {
    businessId: string;
    scheduleId: string;
    search?: string | null | undefined;
};
export type BreakTypeQueriesMutations_GetScheduleBreakTypes_QueryResponse = {
    readonly scheduleBreakTypes: {
        readonly nodes: ReadonlyArray<{
            readonly availableRange: {
                readonly rangeStartTime: unknown;
                readonly rangeEndTime: unknown;
            } | null;
            readonly breakScreenOrdering: number;
            readonly breakTypeCode: string;
            readonly breakTypeId: string;
            readonly canOverrideClockedPaidBreak: boolean;
            readonly code: string | null;
            readonly createdAt: string;
            readonly defaultDuration: unknown | null;
            readonly deleted: boolean;
            readonly description: string | null;
            readonly employmentMetadata: string | null;
            readonly employmentTypes: ReadonlyArray<EmploymentTypeEnum>;
            readonly hidden: boolean;
            readonly id: string;
            readonly legacyBreakType: LegacyBreakTypeEnum;
            readonly name: string;
            readonly paidThreshold: unknown;
            readonly schedulable: boolean;
            readonly scheduleId: string | null;
            readonly scheduleOverride: boolean;
            readonly updatedAt: string;
        } | null> | null;
    };
};
export type BreakTypeQueriesMutations_GetScheduleBreakTypes_Query = {
    readonly response: BreakTypeQueriesMutations_GetScheduleBreakTypes_QueryResponse;
    readonly variables: BreakTypeQueriesMutations_GetScheduleBreakTypes_QueryVariables;
};



/*
query BreakTypeQueriesMutations_GetScheduleBreakTypes_Query(
  $businessId: ID!
  $scheduleId: ID!
  $search: String
) {
  scheduleBreakTypes(businessId: $businessId, scheduleId: $scheduleId, search: $search) {
    nodes {
      availableRange {
        rangeStartTime
        rangeEndTime
      }
      breakScreenOrdering
      breakTypeCode
      breakTypeId
      canOverrideClockedPaidBreak
      code
      createdAt
      defaultDuration
      deleted
      description
      employmentMetadata
      employmentTypes
      hidden
      id
      legacyBreakType
      name
      paidThreshold
      schedulable
      scheduleId
      scheduleOverride
      updatedAt
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "scheduleId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Variable",
        "name": "scheduleId",
        "variableName": "scheduleId"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      }
    ],
    "concreteType": "ScheduleBreakTypeConnection",
    "kind": "LinkedField",
    "name": "scheduleBreakTypes",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ScheduleBreakType",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "HoursMinutesRange",
            "kind": "LinkedField",
            "name": "availableRange",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rangeStartTime",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rangeEndTime",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "breakScreenOrdering",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "breakTypeCode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "breakTypeId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "canOverrideClockedPaidBreak",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "createdAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "defaultDuration",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "deleted",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "employmentMetadata",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "employmentTypes",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "hidden",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "legacyBreakType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "paidThreshold",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "schedulable",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "scheduleId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "scheduleOverride",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "updatedAt",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BreakTypeQueriesMutations_GetScheduleBreakTypes_Query",
    "selections": (v1/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BreakTypeQueriesMutations_GetScheduleBreakTypes_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "95eeee414ec867eb96cb06c3fd926efe",
    "id": null,
    "metadata": {},
    "name": "BreakTypeQueriesMutations_GetScheduleBreakTypes_Query",
    "operationKind": "query",
    "text": "query BreakTypeQueriesMutations_GetScheduleBreakTypes_Query(\n  $businessId: ID!\n  $scheduleId: ID!\n  $search: String\n) {\n  scheduleBreakTypes(businessId: $businessId, scheduleId: $scheduleId, search: $search) {\n    nodes {\n      availableRange {\n        rangeStartTime\n        rangeEndTime\n      }\n      breakScreenOrdering\n      breakTypeCode\n      breakTypeId\n      canOverrideClockedPaidBreak\n      code\n      createdAt\n      defaultDuration\n      deleted\n      description\n      employmentMetadata\n      employmentTypes\n      hidden\n      id\n      legacyBreakType\n      name\n      paidThreshold\n      schedulable\n      scheduleId\n      scheduleOverride\n      updatedAt\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '20603a9e630c9cecacde8cade988d7af';
export default node;
