import React, { Component } from "react";
import NavDropdown from "react-bootstrap/NavDropdown";
import { RouteComponentProps } from "react-router-dom";
import AppContext, {
  AppContextValue,
} from "../IDM/external/Context/AppContext";
import { IStack, Id } from "../../models/common";

type Props = RouteComponentProps & {};

type State = {};

export default class StackSelector extends Component<Props, State> {
  render() {
    const { history } = this.props;

    const getStackDropdown = (
      appContext: AppContextValue,
      props?: Map<Id, IStack>,
    ) => {
      if (!props) {
        return null;
      }

      return (Array.from(props.values()) || []).map((s: IStack) => (
        <NavDropdown.Item
          key={s.id}
          eventKey={s.id}
          onSelect={() => {
            appContext.setStackId(s.id);
            history.push(`/stack/${s.id}/businesses`);
          }}
        >
          {s.domainName}
        </NavDropdown.Item>
      ));
    };

    return (
      <AppContext.Consumer>
        {(appContext: AppContextValue) => (
          <NavDropdown
            className="d-inline-block"
            title={
              appContext.stack ? appContext.stack.domainName : "All Stacks"
            }
            id="basic-nav-dropdown"
          >
            {getStackDropdown(appContext, appContext.myStacksById)}
          </NavDropdown>
        )}
      </AppContext.Consumer>
    );
  }
}
