import React, { Component } from "react";

import Button from "react-bootstrap/Button";
import { FormikContextType } from "formik";
import cloneDeep from "lodash/cloneDeep";
import styled from "styled-components";

const StyledButton = styled(Button)`
  max-width: 160px;
`;

// eslint-disable-next-line @typescript-eslint/no-use-before-define
type Props<T> = typeof AddButton.defaultProps & {
  formikContext: FormikContextType<any>;
  name: string;
  defaultValue?: T;
  maxItems?: number;
  disabled?: boolean;
};

type State = {};

export default class AddButton<T> extends Component<Props<T>> {
  static defaultProps = {};

  render() {
    const { formikContext, name, defaultValue, maxItems, disabled } =
      this.props;
    const helpers = formikContext.getFieldHelpers(name);
    const fields = formikContext.getFieldProps(name);

    const canAdd = maxItems == null || (fields.value || []).length < maxItems;
    return (
      <StyledButton
        size="sm"
        block
        variant="light"
        disabled={!canAdd || disabled}
        onClick={() => {
          if (!canAdd) {
            return;
          }

          const temp = cloneDeep(fields.value) || [];
          temp.push(defaultValue);
          helpers.setValue(temp);
        }}
      >
        +
      </StyledButton>
    );
  }
}
