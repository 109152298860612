import React, { JSXElementConstructor, ReactElement } from "react";
import ReactDOM from "react-dom";

// eslint-disable-next-line @typescript-eslint/no-use-before-define
type Props = typeof HeaderPortal.defaultProps & {
  elementId: string;
  as: string;
};

export default class HeaderPortal extends React.Component<Props> {
  static defaultProps = {
    elementId: "header-portal",
    as: "div",
  };

  private el: HTMLElement;

  private portalRoot: HTMLElement | null = null;

  constructor(props: Props) {
    super(props);
    this.el = document.createElement(props.as);
    this.getHeaderPortal();
  }

  getHeaderPortal() {
    if (!this.portalRoot) {
      this.portalRoot = document.getElementById(this.props.elementId);
    }
  }

  componentDidMount = () => {
    this.getHeaderPortal();

    if (this.portalRoot) {
      this.portalRoot.appendChild(this.el);
    }
  };

  componentWillUnmount = () => {
    if (this.portalRoot) {
      this.portalRoot.removeChild(this.el);
    }
  };

  render() {
    const { children } = this.props;
    return ReactDOM.createPortal(children, this.el);
  }
}

type HeaderPortalBreadCrumbsProps = {
  breadcrumbs: React.ReactElement[];
  prefixChevron?: boolean | undefined;
};

// Utility to be used with the header portal in order to quickly create breadcrumbs without manually concatenating jsx elements
export function HeaderPortalBreadcrumbs({
  breadcrumbs,
  prefixChevron = true,
}: HeaderPortalBreadCrumbsProps) {
  return (
    <>
      {prefixChevron && (
        <span key="chevron" className="ml-2 mr-2">
          &gt;
        </span>
      )}
      {breadcrumbs
        .map((breadcrumb: React.ReactElement, index: number) =>
          React.cloneElement(breadcrumb, {
            // eslint-disable-next-line react/no-array-index-key
            key: `breadcrumb-${index}`,
          }),
        )
        .reduce(
          (
            acc: React.ReactElement,
            curr: React.ReactElement,
            index: number,
          ) => {
            return (
              <>
                {acc}
                {/* eslint-disable-next-line react/no-array-index-key */}
                <span key={`chevron-${index}`} className="ml-2 mr-2">
                  &gt;
                </span>
                {curr}
              </>
            );
          },
        )}
    </>
  );
}
