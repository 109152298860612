/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type AosQueryMutations_AdvancedProfile_QueryVariables = {
    businessId: string;
    scheduleId: string;
};
export type AosQueryMutations_AdvancedProfile_QueryResponse = {
    readonly schedules: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly analyticsEnabled: boolean;
                readonly populateScheduleEnabled: boolean;
                readonly aosConfigSchema: unknown;
                readonly populateScheduleReplanningEnabled: boolean;
                readonly aosConfig: {
                    readonly aosTimeout: number | null;
                    readonly aosCombinedRoles: unknown | null;
                    readonly aosOrchestratorConfig: unknown | null;
                    readonly replanningTimeout: number | null;
                    readonly replanningOrchestratorConfig: unknown | null;
                } | null;
            } | null;
        } | null> | null;
    };
};
export type AosQueryMutations_AdvancedProfile_Query = {
    readonly response: AosQueryMutations_AdvancedProfile_QueryResponse;
    readonly variables: AosQueryMutations_AdvancedProfile_QueryVariables;
};



/*
query AosQueryMutations_AdvancedProfile_Query(
  $businessId: ID!
  $scheduleId: ID!
) {
  schedules(businessId: $businessId, ids: [$scheduleId]) {
    edges {
      node {
        id
        analyticsEnabled
        populateScheduleEnabled
        aosConfigSchema
        populateScheduleReplanningEnabled
        aosConfig {
          aosTimeout
          aosCombinedRoles
          aosOrchestratorConfig
          replanningTimeout
          replanningOrchestratorConfig
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "scheduleId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "items": [
          {
            "kind": "Variable",
            "name": "ids.0",
            "variableName": "scheduleId"
          }
        ],
        "kind": "ListValue",
        "name": "ids"
      }
    ],
    "concreteType": "ScheduleConnection",
    "kind": "LinkedField",
    "name": "schedules",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ScheduleEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Schedule",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "analyticsEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "populateScheduleEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "aosConfigSchema",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "populateScheduleReplanningEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AosConfig",
                "kind": "LinkedField",
                "name": "aosConfig",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "aosTimeout",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "aosCombinedRoles",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "aosOrchestratorConfig",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "replanningTimeout",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "replanningOrchestratorConfig",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AosQueryMutations_AdvancedProfile_Query",
    "selections": (v1/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AosQueryMutations_AdvancedProfile_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e983dd54c6944379765a39f572b72399",
    "id": null,
    "metadata": {},
    "name": "AosQueryMutations_AdvancedProfile_Query",
    "operationKind": "query",
    "text": "query AosQueryMutations_AdvancedProfile_Query(\n  $businessId: ID!\n  $scheduleId: ID!\n) {\n  schedules(businessId: $businessId, ids: [$scheduleId]) {\n    edges {\n      node {\n        id\n        analyticsEnabled\n        populateScheduleEnabled\n        aosConfigSchema\n        populateScheduleReplanningEnabled\n        aosConfig {\n          aosTimeout\n          aosCombinedRoles\n          aosOrchestratorConfig\n          replanningTimeout\n          replanningOrchestratorConfig\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '20370aeb0faac0f314d6eb7d86276994';
export default node;
