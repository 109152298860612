import React from "react";
import Row from "react-bootstrap/Row";
import { FormikContext } from "formik";
import { startCase, isFunction, isBoolean } from "lodash";
// eslint-disable-next-line import/no-cycle
import Field from "./Field";
import GroupHeader from "./GroupHeader";
import SubGroupHeader from "./SubGroupHeader";
import {
  IProperty,
  ComponentRule,
  GroupName,
  SubGroupName,
  CommonComponentRule,
  FormGroupProperties,
} from "./models";
import CommonComponents from "./CommonComponents";
import {
  DynamicFormContext,
  DynamicFormContextProps,
} from "../Context/DynamicFormContext";

export type DisabledRule = boolean | ((x: any) => boolean);

// eslint-disable-next-line @typescript-eslint/no-use-before-define
type Props = typeof DynamicInputGroup.defaultProps &
  FormGroupProperties & {
    fieldKey?: string;
    fields: Map<GroupName, Map<SubGroupName, IProperty[]>>;
    disabled?: boolean;
    hideGroupName?: boolean;
    hideSubGroupName?: boolean;
  };

type State = {};

export default class DynamicInputGroup extends React.Component<Props, State> {
  static contextType = DynamicFormContext;

  static defaultProps = {
    fields: new Map<GroupName, Map<SubGroupName, IProperty[]>>(),
  };

  render() {
    const {
      fields,
      // groupName,
      // subGroupName,
      fieldKey,
      hideGroupName,
      hideSubGroupName,
      ...componentRuleProperties
    } = this.props;

    const { disabled } = this.props;
    const dynamicFormContext: DynamicFormContextProps = this.context;

    return (
      <FormikContext.Consumer>
        {({ values }) => {
          const formsFields: JSX.Element[] = [];
          fields.forEach(
            (
              subGroups: Map<SubGroupName, IProperty[]>,
              fieldGroupName: GroupName,
            ) => {
              const subGroupsFields: JSX.Element[] = [];
              const displayGroupName = fieldGroupName || "";

              subGroups.forEach(
                (properties: IProperty[], fieldSubGroupName: SubGroupName) => {
                  const displayedSubGroupName = fieldSubGroupName;
                  subGroupsFields.push(
                    <fieldset
                      className="sub-group"
                      key={`${displayGroupName}-${displayedSubGroupName}`}
                    >
                      {!hideSubGroupName && displayedSubGroupName && (
                        <SubGroupHeader>{displayedSubGroupName}</SubGroupHeader>
                      )}
                      <Row>
                        {properties.map((field: IProperty) => {
                          const {
                            key,
                            name,
                            type,
                            label,
                            component: componentName,
                            description,
                          } = field;

                          let mergedComponentRuleProperties = {
                            ...componentRuleProperties,
                          };

                          // check if component name is registered in CommonComponents
                          if (componentName != null) {
                            const commonDefinition: CommonComponentRule =
                              CommonComponents[componentName];

                            if (
                              commonDefinition &&
                              commonDefinition.component != null
                            ) {
                              // merge with common component rules
                              mergedComponentRuleProperties = {
                                ...mergedComponentRuleProperties,
                                ...commonDefinition,
                              };
                            }
                          }

                          // check if component is defined in custom componentRules
                          if (dynamicFormContext.componentRules != null) {
                            const profileComponentRule: ComponentRule =
                              dynamicFormContext.componentRules[key];

                            if (profileComponentRule != null) {
                              // Property is defined in componentRules
                              const { disabled: profileComponentRuleDisabled } =
                                profileComponentRule;

                              // Process disabled option
                              const disabledRule = {
                                disabled,
                              };
                              if (isFunction(profileComponentRuleDisabled)) {
                                disabledRule.disabled =
                                  disabled ||
                                  profileComponentRuleDisabled(values);
                              } else if (
                                isBoolean(profileComponentRuleDisabled)
                              ) {
                                disabledRule.disabled =
                                  disabled || profileComponentRuleDisabled;
                              }

                              mergedComponentRuleProperties = {
                                ...mergedComponentRuleProperties,
                                ...profileComponentRule,
                                ...disabledRule,
                              };
                            }
                          }

                          const safeFieldKey = fieldKey
                            ? `${fieldKey}.${name}`
                            : key;

                          return (
                            type && (
                              <Field
                                key={key}
                                schemaFieldType={type}
                                fieldKey={safeFieldKey}
                                label={label || startCase(name)}
                                description={description}
                                fields={fields}
                                {...mergedComponentRuleProperties}
                              />
                            )
                          );
                        })}
                      </Row>
                    </fieldset>,
                  );
                },
              );

              formsFields.push(
                <fieldset className="group" key={displayGroupName}>
                  {!hideGroupName && displayGroupName && (
                    <GroupHeader>{displayGroupName}</GroupHeader>
                  )}
                  {subGroupsFields}
                </fieldset>,
              );
            },
          );
          return formsFields;
        }}
      </FormikContext.Consumer>
    );
  }
}
