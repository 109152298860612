/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type LegacyBreakTypeEnum = "meal" | "rest" | "unknown" | "%future added value";
export type BreakTypeQueriesMutations_GetSingleBusinessBreakType_QueryVariables = {
    businessId: string;
    id: string;
    skip: boolean;
};
export type BreakTypeQueriesMutations_GetSingleBusinessBreakType_QueryResponse = {
    readonly breakTypes?: {
        readonly nodes: ReadonlyArray<{
            readonly id: string;
            readonly deleted: boolean;
            readonly name: string;
            readonly breakTypeCode: string;
            readonly legacyBreakType: LegacyBreakTypeEnum;
            readonly breakScreenOrdering: number;
            readonly description: string | null;
            readonly code: string | null;
            readonly defaultDuration: unknown | null;
            readonly availableRange: {
                readonly rangeStartTime: unknown;
                readonly rangeEndTime: unknown;
            } | null;
            readonly schedulable: boolean;
            readonly paidThreshold: unknown;
            readonly canOverrideClockedPaidBreak: boolean;
            readonly employmentTypeCodes: ReadonlyArray<string>;
            readonly employmentMetadata: string | null;
        } | null> | null;
    } | undefined;
};
export type BreakTypeQueriesMutations_GetSingleBusinessBreakType_Query = {
    readonly response: BreakTypeQueriesMutations_GetSingleBusinessBreakType_QueryResponse;
    readonly variables: BreakTypeQueriesMutations_GetSingleBusinessBreakType_QueryVariables;
};



/*
query BreakTypeQueriesMutations_GetSingleBusinessBreakType_Query(
  $businessId: ID!
  $id: ID!
  $skip: Boolean!
) {
  breakTypes(businessId: $businessId, id: $id, includeDeleted: true) @skip(if: $skip) {
    nodes {
      id
      deleted
      name
      breakTypeCode
      legacyBreakType
      breakScreenOrdering
      description
      code
      defaultDuration
      availableRange {
        rangeStartTime
        rangeEndTime
      }
      schedulable
      paidThreshold
      canOverrideClockedPaidBreak
      employmentTypeCodes
      employmentMetadata
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "condition": "skip",
    "kind": "Condition",
    "passingValue": false,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "businessId",
            "variableName": "businessId"
          },
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
          },
          {
            "kind": "Literal",
            "name": "includeDeleted",
            "value": true
          }
        ],
        "concreteType": "BreakTypeConnection",
        "kind": "LinkedField",
        "name": "breakTypes",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BreakType",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "deleted",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "breakTypeCode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "legacyBreakType",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "breakScreenOrdering",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "defaultDuration",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "HoursMinutesRange",
                "kind": "LinkedField",
                "name": "availableRange",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rangeStartTime",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "rangeEndTime",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "schedulable",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "paidThreshold",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canOverrideClockedPaidBreak",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "employmentTypeCodes",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "employmentMetadata",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BreakTypeQueriesMutations_GetSingleBusinessBreakType_Query",
    "selections": (v1/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BreakTypeQueriesMutations_GetSingleBusinessBreakType_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "77890e2f3380db57a1e0ec65b1741657",
    "id": null,
    "metadata": {},
    "name": "BreakTypeQueriesMutations_GetSingleBusinessBreakType_Query",
    "operationKind": "query",
    "text": "query BreakTypeQueriesMutations_GetSingleBusinessBreakType_Query(\n  $businessId: ID!\n  $id: ID!\n  $skip: Boolean!\n) {\n  breakTypes(businessId: $businessId, id: $id, includeDeleted: true) @skip(if: $skip) {\n    nodes {\n      id\n      deleted\n      name\n      breakTypeCode\n      legacyBreakType\n      breakScreenOrdering\n      description\n      code\n      defaultDuration\n      availableRange {\n        rangeStartTime\n        rangeEndTime\n      }\n      schedulable\n      paidThreshold\n      canOverrideClockedPaidBreak\n      employmentTypeCodes\n      employmentMetadata\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '78da739cd2ce93ffa2b81de9b104fa4d';
export default node;
