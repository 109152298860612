import React, { Component } from "react";
import { graphql, QueryRenderer } from "react-relay";
import { RouteComponentProps } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Searchable from "../../Searchable/Searchable";
import { BusinessContext } from "../../Context/BusinessContext";
import ScheduleTable from "./ScheduleTable";
import Loader from "../../common/Loader";
import HeaderPortal from "../../Portal/HeaderPortal";

const SchedulesQuery = graphql`
  query Schedules_InternalQuery(
    $businessId: ID!
    $searchValue: String!
    $pageSize: Int!
    $after: String
  ) {
    ...ScheduleTable_viewer
  }
`;

interface MatchParams {
  business_id: string;
  stack_id: string;
}

type Props = RouteComponentProps<MatchParams> & {};

type State = {
  searchValue: string;
};
export default class Schedules extends Component<Props, State> {
  static contextType = BusinessContext;

  constructor(props: any) {
    super(props);
    this.state = {
      searchValue: "",
    };
  }

  private onSearchChange(value: string) {
    this.setState({
      searchValue: value,
    });
  }

  render() {
    const properties = this.props;
    const { searchValue } = this.state;
    const { environment } = this.context;

    const {
      match: { params },
    } = properties;
    const { business_id: businessId, stack_id: stackId } = params;

    return (
      <>
        <HeaderPortal as="span" elementId="sub-header-portal">
          <span className="ml-2 mr-2">&gt;</span>
          <span>Schedules</span>
        </HeaderPortal>

        <Row className="my-2">
          <Col>
            <Searchable
              className="schedules"
              searchValue={searchValue}
              onSearchChange={this.onSearchChange.bind(this)}
              placeholder="search schedules..."
            />
          </Col>
          <Col md="auto">{/* primary button */}</Col>
        </Row>

        <Card body>
          <QueryRenderer
            environment={environment}
            query={SchedulesQuery}
            variables={{
              businessId,
              searchValue: searchValue || "",
              pageSize: 50,
            }}
            render={({ error, props }) => {
              if (error) {
                return <div>Error!</div>;
              }
              if (!props) {
                return <Loader />;
              }
              return (
                <ScheduleTable
                  {...properties}
                  stackId={stackId}
                  businessId={businessId}
                  searchValue={searchValue}
                  viewer={props as any}
                />
              );
            }}
          />
        </Card>
      </>
    );
  }
}
