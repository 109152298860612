import React, { Component } from "react";
import styled from "styled-components";

const StyledGroupName = styled("legend")`
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  padding: 8px;
  background-color: ${(props) => props.theme.borderColor};
  border-radius: 3px;
`;

// eslint-disable-next-line @typescript-eslint/no-use-before-define
type Props = typeof GroupHeader.defaultProps & {
  groupName?: string;
  className?: string;
};

export default class GroupHeader extends Component<Props> {
  static defaultProps = {
    className: "fieldset-group",
  };

  render() {
    const { children, className } = this.props;
    return <StyledGroupName className={className}>{children}</StyledGroupName>;
  }
}
