/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type AvailabilityTypeEnum = "Availability_NoPermanentWorkSchedule" | "Availability_TemporaryAvailability" | "%future added value";
export type CurrencyEnum = "aud" | "cad" | "eur" | "gbp" | "nzd" | "usd" | "%future added value";
export type EmploymentRateEditPastEnum = "disabled" | "enabled" | "%future added value";
export type EmploymentTypeFixedShiftRestrictionOptionEnum = "not_applicable" | "optional" | "required" | "%future added value";
export type SwapDropFilteringEnum = "default" | "jtc" | "%future added value";
export type SwapSortingEnum = "default" | "skill_level" | "%future added value";
export type BusinessInput = {
    accentColor1?: string | null | undefined;
    aggregateToStartDay?: boolean | null | undefined;
    allowManagerToApproveOwnPunch?: boolean | null | undefined;
    analyticsEnabled?: boolean | null | undefined;
    analyticsServerUrl?: string | null | undefined;
    anonymousShiftDropToUser?: boolean | null | undefined;
    anonymousShiftSwapToUser?: boolean | null | undefined;
    approvalNeededForCommendation?: boolean | null | undefined;
    approvalNeededForDisciplinaryAction?: boolean | null | undefined;
    approvalNeededForRoleRateChange?: boolean | null | undefined;
    approvalRequests?: ApprovalRequestsConfigInput | null | undefined;
    assignEmployeeConfirmationText?: string | null | undefined;
    autoAcceptRosteredShifts?: boolean | null | undefined;
    autoRejectTimeFromUnavailabilityStart?: AutoRejectTimeInput | null | undefined;
    autoRejectUnavailabilityEvents?: boolean | null | undefined;
    availabilityChangeDuringPublishedScheduleEnabled?: boolean | null | undefined;
    availabilityConsentRequired?: boolean | null | undefined;
    availabilityCoverageHorizonDays?: number | null | undefined;
    availabilityEmployeeComments?: boolean | null | undefined;
    availabilityErrorOnAnyShiftConflict?: boolean | null | undefined;
    availabilityErrorOnMinNoticeDays?: number | null | undefined;
    availabilityErrorOnRosteredShiftConflict?: boolean | null | undefined;
    availabilityManagerComments?: boolean | null | undefined;
    availabilityMaxCycleWeeks?: number | null | undefined;
    availabilityTypesEnabled?: Array<AvailabilityTypeEnum> | null | undefined;
    availabilityUseConsecutiveDaysOffWaived?: boolean | null | undefined;
    availabilityUseMaxDurationPerWeek?: boolean | null | undefined;
    availabilityWarnOnMinNoticeDays?: number | null | undefined;
    businessName?: string | null | undefined;
    businessTerms?: unknown | null | undefined;
    canAdjustForecast?: boolean | null | undefined;
    chatEnabled?: boolean | null | undefined;
    clockInThreshold?: number | null | undefined;
    clockOutThreshold?: number | null | undefined;
    clockTrackingEnabled?: boolean | null | undefined;
    coBrandingEnabled?: boolean | null | undefined;
    commendationEmploymentNotifications?: boolean | null | undefined;
    commendationEnabled?: boolean | null | undefined;
    contractsConsentEnabled?: boolean | null | undefined;
    contractsUseEmploymentType?: boolean | null | undefined;
    contractsUseMinDurationPerWeek?: boolean | null | undefined;
    crossScheduleComplianceChecksEnabled?: boolean | null | undefined;
    csvReportDisclaimerEnabled?: boolean | null | undefined;
    currencyType?: CurrencyEnum | null | undefined;
    darAnalyticsSyncThreshold?: number | null | undefined;
    darDataOnlyPastSlicesEnabled?: boolean | null | undefined;
    darEnabled?: boolean | null | undefined;
    darFields?: Array<BusinessDarFieldInput> | null | undefined;
    dataLakeGlobalId?: string | null | undefined;
    dataLakeId?: string | null | undefined;
    datapassAffectingMetadata?: Array<string> | null | undefined;
    datapassTrackingEnabled?: boolean | null | undefined;
    dateFormat?: string | null | undefined;
    defaultPayrollCutoffTime?: string | null | undefined;
    defaultScheduleDayEndTime?: string | null | undefined;
    defaultScheduleDayStartTime?: string | null | undefined;
    defaultScheduleSnapshotDuration?: number | null | undefined;
    defaultScheduleSnapshotStartDate?: string | null | undefined;
    deletePrimaryJobTitlesEnabled?: boolean | null | undefined;
    disableEmployeeLeaveRequest?: boolean | null | undefined;
    disciplinaryActionEmploymentNotifications?: boolean | null | undefined;
    disciplinaryActionEnabled?: boolean | null | undefined;
    displayWarningUnavailabilityEvents?: boolean | null | undefined;
    dropMobileDisclaimer?: string | null | undefined;
    dropMobileDisclaimerEnabled?: boolean | null | undefined;
    durationFormat?: string | null | undefined;
    dynamicFieldsLayout?: DynamicFieldsLayoutInput | null | undefined;
    earlyShiftStartThreshold?: number | null | undefined;
    earningsEstimator?: string | null | undefined;
    emplTypeGmhSettings?: EmplTypeGmhSettingsUpdateInput | null | undefined;
    emplTypeTimeOffComplianceEnabled?: EmplTypeTimeOffComplianceEnabledInput | null | undefined;
    employeeAvailabilityEditableByManager?: boolean | null | undefined;
    employeeDraftsEnabled?: boolean | null | undefined;
    employeeMultiRateEnabled?: boolean | null | undefined;
    employmentExternalManagedFlags?: EmploymentExternalManagedFlagsUpdateInput | null | undefined;
    employmentRateEditEnabled?: boolean | null | undefined;
    employmentRateEditPast?: EmploymentRateEditPastEnum | null | undefined;
    employmentTypeFixedShiftRestriction?: EmploymentTypeFixedShiftRestrictionUpdateInput | null | undefined;
    enableMiscPay?: boolean | null | undefined;
    enablePastShiftOperation?: boolean | null | undefined;
    enableShiftDifferentials?: boolean | null | undefined;
    feedbackCommentEnabled?: boolean | null | undefined;
    feedbackReasonEnabled?: boolean | null | undefined;
    firstDayOfWeek?: number | null | undefined;
    firstNameCharCount?: number | null | undefined;
    firstNameSuffix?: string | null | undefined;
    fixedShiftsBreakDurationEnabled?: boolean | null | undefined;
    fixedShiftsEnabled?: boolean | null | undefined;
    fixedShiftsRoleId?: string | null | undefined;
    footerDisclaimer?: string | null | undefined;
    forecastRequiresApproval?: boolean | null | undefined;
    globalBusinessId?: string | null | undefined;
    globalImageId?: string | null | undefined;
    highlightOverstaffing?: boolean | null | undefined;
    homeStoreTransferManagerCommentsEnabled?: boolean | null | undefined;
    idleTimeoutEnabled?: boolean | null | undefined;
    idleTimeoutLength?: number | null | undefined;
    image?: string | null | undefined;
    inviteFooter?: string | null | undefined;
    inviteHeader?: string | null | undefined;
    inviteMessage?: string | null | undefined;
    inviteUserConfirmationText?: string | null | undefined;
    isFake?: boolean | null | undefined;
    jobTitlesEnabled?: boolean | null | undefined;
    kpiAvgHourlyRateEnabled?: boolean | null | undefined;
    kpiSalesPerEmployeeHourEnabled?: boolean | null | undefined;
    lastNameCharCount?: number | null | undefined;
    lastNameSuffix?: string | null | undefined;
    liveTimeClockViewEnabled?: boolean | null | undefined;
    localeSuffix?: string | null | undefined;
    mailerLocales?: unknown | null | undefined;
    managerAppEnabled?: boolean | null | undefined;
    managerAssignUsersToSchedulesEnabled?: boolean | null | undefined;
    managerInviteUsersEnabled?: boolean | null | undefined;
    managerTerminateUsersEnabled?: boolean | null | undefined;
    mandatoryReasonsForEmploymentStatus?: MandatoryReasonsForEmploymentStatusInput | null | undefined;
    markAsAbsentEnabled?: boolean | null | undefined;
    markAsAbsentEnabledToUser?: boolean | null | undefined;
    maxShiftDuration?: number | null | undefined;
    militaryTime?: boolean | null | undefined;
    minSharingAge?: number | null | undefined;
    monthlyCostLoadingEnabled?: boolean | null | undefined;
    nicknameReplacesOnlyFirstName?: boolean | null | undefined;
    notificationsOnTimeClockAppEnabled?: boolean | null | undefined;
    payCycleStartDate?: string | null | undefined;
    payDates?: Array<number> | null | undefined;
    payDisclaimer?: string | null | undefined;
    payFrequency?: string | null | undefined;
    payPeriodDailyReportSplitNames?: boolean | null | undefined;
    payPeriodReviewEnabled?: boolean | null | undefined;
    payPeriodSelfApprovalEnabled?: boolean | null | undefined;
    populateScheduleReplanningEnabled?: boolean | null | undefined;
    punchSlipMode?: string | null | undefined;
    quarterlyAggregationEnabled?: boolean | null | undefined;
    rateType?: unknown | null | undefined;
    registrationNumber?: string | null | undefined;
    regularBusinessInviteEnabled?: boolean | null | undefined;
    reportSettings?: unknown | null | undefined;
    restScreenTheme?: string | null | undefined;
    roleJobTitles?: unknown | null | undefined;
    rostered?: boolean | null | undefined;
    roundingStrategy?: string | null | undefined;
    schoolCalendarsEnabled?: boolean | null | undefined;
    shareRequiresApproval?: boolean | null | undefined;
    sharedStoreShiftsEnabled?: boolean | null | undefined;
    shiftCanOverlapLeaveRequest?: boolean | null | undefined;
    shiftCanOverlapUnavailability?: boolean | null | undefined;
    shiftCostBreakdownEnabled?: boolean | null | undefined;
    shiftDropEnabled?: boolean | null | undefined;
    shiftDropThreshold?: number | null | undefined;
    shiftMultiRateEnabled?: boolean | null | undefined;
    shiftSwapEnabled?: boolean | null | undefined;
    shiftSwapNeedsApproval?: boolean | null | undefined;
    shortDescription?: string | null | undefined;
    showAvatarIcon?: boolean | null | undefined;
    showBreaksToUser?: boolean | null | undefined;
    showColleaguesToUser?: boolean | null | undefined;
    showEarnings?: boolean | null | undefined;
    showEarningsToUser?: boolean | null | undefined;
    showNickname?: boolean | null | undefined;
    showShiftRolesToUser?: boolean | null | undefined;
    showSystemRolesAndPermissions?: boolean | null | undefined;
    storeTransferOnHireDateEnabled?: boolean | null | undefined;
    swapDropFiltering?: SwapDropFilteringEnum | null | undefined;
    swapMobileDisclaimer?: string | null | undefined;
    swapMobileDisclaimerEnabled?: boolean | null | undefined;
    swapSorting?: SwapSortingEnum | null | undefined;
    terminateUserConfirmationText?: string | null | undefined;
    timeClockAppBusinessImageUri?: string | null | undefined;
    timeClockAppFetchScheduleJobTitles?: boolean | null | undefined;
    timeClockAppMinTimeBetweenBreaks?: unknown | null | undefined;
    timeClockAppPinConfig?: TimeClockAppPinConfigInput | null | undefined;
    timeClockAppSyncIntervalInSeconds?: number | null | undefined;
    timeClockAppWorkflowConfig?: unknown | null | undefined;
    timeClockRoleRateChangeAuthThreshold?: number | null | undefined;
    timeOffDuringPublishedScheduleEnabled?: boolean | null | undefined;
    timeOffEmployeeCommentsEnabled?: boolean | null | undefined;
    timeOffManagerCommentsEnabled?: boolean | null | undefined;
    timeOffRequestDuringBlackoutEnabled?: boolean | null | undefined;
    timeOffShiftNotificationsEnabled?: boolean | null | undefined;
    timeOffShiftsEnabled?: boolean | null | undefined;
    timekeepingReadonly?: boolean | null | undefined;
    timekeepingRolesVisible?: boolean | null | undefined;
    timeoutThreshold?: number | null | undefined;
    topLevelForecastMeasurements?: Array<AnalyticsTopLevelMeasurementInput> | null | undefined;
    unassignEmployeeConfirmationText?: string | null | undefined;
    unassignedShiftDefaultRateEnabled?: boolean | null | undefined;
    unshareOnTermination?: boolean | null | undefined;
    voluntaryLateClockOutEnabled?: boolean | null | undefined;
    warningCodeMap?: unknown | null | undefined;
    warningTimeFromUnavailabilityStart?: AutoWarnTimeInput | null | undefined;
    webuiViewOptions?: unknown | null | undefined;
};
export type ApprovalRequestsConfigInput = {
    employmentRateChange?: EmploymentRateChangeRequestConfigInput | null | undefined;
};
export type EmploymentRateChangeRequestConfigInput = {
    autoApprovalEnabled?: boolean | null | undefined;
};
export type AutoRejectTimeInput = {
    unit?: string | null | undefined;
    value?: number | null | undefined;
};
export type BusinessDarFieldInput = {
    description?: string | null | undefined;
    fieldCode: string;
    name: string;
    valueType: string;
};
export type DynamicFieldsLayoutInput = {
    groups: Array<DynamicFieldsLayoutGroupInput>;
    views: Array<DynamicFieldsLayoutViewInput>;
};
export type DynamicFieldsLayoutGroupInput = {
    fields: Array<DynamicFieldsLayoutGroupFieldInput>;
    label: string;
    name: string;
};
export type DynamicFieldsLayoutGroupFieldInput = {
    metadataTypeName: string;
    width: number;
};
export type DynamicFieldsLayoutViewInput = {
    groups: Array<string>;
    name: string;
};
export type EmplTypeGmhSettingsUpdateInput = {
    casual?: EmplTypeGmhSettingsEmploymentTypeConfigUpdateInput | null | undefined;
    fullTime?: EmplTypeGmhSettingsEmploymentTypeConfigUpdateInput | null | undefined;
    partTime?: EmplTypeGmhSettingsEmploymentTypeConfigUpdateInput | null | undefined;
    permanent?: EmplTypeGmhSettingsEmploymentTypeConfigUpdateInput | null | undefined;
};
export type EmplTypeGmhSettingsEmploymentTypeConfigUpdateInput = {
    enabled?: boolean | null | undefined;
    minimumDurationError?: unknown | null | undefined;
    minimumDurationWarning?: unknown | null | undefined;
};
export type EmplTypeTimeOffComplianceEnabledInput = {
    casual: boolean;
    fullTime: boolean;
    partTime: boolean;
    permanent: boolean;
};
export type EmploymentExternalManagedFlagsUpdateInput = {
    availability?: boolean | null | undefined;
    contract?: boolean | null | undefined;
    hireHistory?: boolean | null | undefined;
    homeStore?: boolean | null | undefined;
    jtc?: boolean | null | undefined;
    nickname?: boolean | null | undefined;
    personalDetails?: boolean | null | undefined;
    roleAndRate?: boolean | null | undefined;
    school?: boolean | null | undefined;
    sharedStore?: boolean | null | undefined;
    skillLevel?: boolean | null | undefined;
    timeOff?: boolean | null | undefined;
};
export type EmploymentTypeFixedShiftRestrictionUpdateInput = {
    casual?: EmploymentTypeFixedShiftRestrictionOptionEnum | null | undefined;
    fullTime?: EmploymentTypeFixedShiftRestrictionOptionEnum | null | undefined;
    partTime?: EmploymentTypeFixedShiftRestrictionOptionEnum | null | undefined;
    permanent?: EmploymentTypeFixedShiftRestrictionOptionEnum | null | undefined;
};
export type MandatoryReasonsForEmploymentStatusInput = {
    active?: boolean | null | undefined;
    leaveOfAbsence?: boolean | null | undefined;
    suspended?: boolean | null | undefined;
    terminated?: boolean | null | undefined;
};
export type TimeClockAppPinConfigInput = {
    max: number;
    min: number;
};
export type AnalyticsTopLevelMeasurementInput = {
    isCurrency: boolean;
    isPrimary: boolean;
    label: string;
    name: string;
};
export type AutoWarnTimeInput = {
    unit?: string | null | undefined;
    value?: number | null | undefined;
};
export type PeopleSettingsQueriesMutations_UpdateBusinessPeopleSettings_MutationVariables = {
    input: BusinessInput;
    id: string;
};
export type PeopleSettingsQueriesMutations_UpdateBusinessPeopleSettings_MutationResponse = {
    readonly updateBusiness: {
        readonly employmentExternalManagedFlags: {
            readonly availability: boolean;
            readonly contract: boolean;
            readonly hireHistory: boolean;
            readonly homeStore: boolean;
            readonly sharedStore: boolean;
            readonly jtc: boolean;
            readonly nickname: boolean;
            readonly personalDetails: boolean;
            readonly roleAndRate: boolean;
            readonly school: boolean;
            readonly skillLevel: boolean;
            readonly timeOff: boolean;
        };
        readonly employeeDraftsEnabled: boolean;
        readonly contractsUseEmploymentType: boolean;
        readonly contractsConsentEnabled: boolean;
        readonly contractsUseMinDurationPerWeek: boolean;
        readonly disableEmployeeLeaveRequest: boolean;
        readonly timeOffManagerCommentsEnabled: boolean;
        readonly timeOffEmployeeCommentsEnabled: boolean;
        readonly displayWarningUnavailabilityEvents: boolean;
        readonly warningTimeFromUnavailabilityStart: {
            readonly value: number;
            readonly unit: string;
        };
        readonly autoRejectUnavailabilityEvents: boolean;
        readonly autoRejectTimeFromUnavailabilityStart: {
            readonly value: number;
            readonly unit: string;
        };
        readonly timeOffShiftsEnabled: boolean;
        readonly timeOffShiftNotificationsEnabled: boolean;
        readonly timeOffRequestDuringBlackoutEnabled: boolean;
        readonly timeOffDuringPublishedScheduleEnabled: boolean;
        readonly employeeAvailabilityEditableByManager: boolean;
        readonly availabilityWarnOnMinNoticeDays: number;
        readonly availabilityErrorOnMinNoticeDays: number;
        readonly availabilityEmployeeComments: boolean;
        readonly availabilityManagerComments: boolean;
        readonly availabilityTypesEnabled: ReadonlyArray<AvailabilityTypeEnum>;
        readonly availabilityConsentRequired: boolean;
        readonly availabilityUseMaxDurationPerWeek: boolean;
        readonly availabilityMaxCycleWeeks: number;
        readonly availabilityErrorOnRosteredShiftConflict: boolean;
        readonly availabilityErrorOnAnyShiftConflict: boolean;
        readonly availabilityCoverageHorizonDays: number;
        readonly availabilityUseConsecutiveDaysOffWaived: boolean;
        readonly availabilityChangeDuringPublishedScheduleEnabled: boolean;
        readonly fixedShiftsEnabled: boolean;
        readonly fixedShiftsRoleId: string | null;
        readonly fixedShiftsBreakDurationEnabled: boolean;
        readonly managerTerminateUsersEnabled: boolean;
        readonly unshareOnTermination: boolean;
        readonly mandatoryReasonsForEmploymentStatus: {
            readonly terminated: boolean;
        };
        readonly datapassTrackingEnabled: boolean;
        readonly datapassAffectingMetadata: ReadonlyArray<string>;
        readonly homeStoreTransferManagerCommentsEnabled: boolean;
        readonly storeTransferOnHireDateEnabled: boolean;
        readonly shareRequiresApproval: boolean;
        readonly minSharingAge: number;
    };
};
export type PeopleSettingsQueriesMutations_UpdateBusinessPeopleSettings_Mutation = {
    readonly response: PeopleSettingsQueriesMutations_UpdateBusinessPeopleSettings_MutationResponse;
    readonly variables: PeopleSettingsQueriesMutations_UpdateBusinessPeopleSettings_MutationVariables;
};



/*
mutation PeopleSettingsQueriesMutations_UpdateBusinessPeopleSettings_Mutation(
  $input: BusinessInput!
  $id: ID!
) {
  updateBusiness(id: $id, input: $input) {
    employmentExternalManagedFlags {
      availability
      contract
      hireHistory
      homeStore
      sharedStore
      jtc
      nickname
      personalDetails
      roleAndRate
      school
      skillLevel
      timeOff
    }
    employeeDraftsEnabled
    contractsUseEmploymentType
    contractsConsentEnabled
    contractsUseMinDurationPerWeek
    disableEmployeeLeaveRequest
    timeOffManagerCommentsEnabled
    timeOffEmployeeCommentsEnabled
    displayWarningUnavailabilityEvents
    warningTimeFromUnavailabilityStart {
      value
      unit
    }
    autoRejectUnavailabilityEvents
    autoRejectTimeFromUnavailabilityStart {
      value
      unit
    }
    timeOffShiftsEnabled
    timeOffShiftNotificationsEnabled
    timeOffRequestDuringBlackoutEnabled
    timeOffDuringPublishedScheduleEnabled
    employeeAvailabilityEditableByManager
    availabilityWarnOnMinNoticeDays
    availabilityErrorOnMinNoticeDays
    availabilityEmployeeComments
    availabilityManagerComments
    availabilityTypesEnabled
    availabilityConsentRequired
    availabilityUseMaxDurationPerWeek
    availabilityMaxCycleWeeks
    availabilityErrorOnRosteredShiftConflict
    availabilityErrorOnAnyShiftConflict
    availabilityCoverageHorizonDays
    availabilityUseConsecutiveDaysOffWaived
    availabilityChangeDuringPublishedScheduleEnabled
    fixedShiftsEnabled
    fixedShiftsRoleId
    fixedShiftsBreakDurationEnabled
    managerTerminateUsersEnabled
    unshareOnTermination
    mandatoryReasonsForEmploymentStatus {
      terminated
    }
    datapassTrackingEnabled
    datapassAffectingMetadata
    homeStoreTransferManagerCommentsEnabled
    storeTransferOnHireDateEnabled
    shareRequiresApproval
    minSharingAge
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "EmploymentExternalManagedFlags",
  "kind": "LinkedField",
  "name": "employmentExternalManagedFlags",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "availability",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contract",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hireHistory",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "homeStore",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sharedStore",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jtc",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nickname",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "personalDetails",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "roleAndRate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "school",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "skillLevel",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeOff",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "employeeDraftsEnabled",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contractsUseEmploymentType",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contractsConsentEnabled",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "contractsUseMinDurationPerWeek",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "disableEmployeeLeaveRequest",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeOffManagerCommentsEnabled",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeOffEmployeeCommentsEnabled",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayWarningUnavailabilityEvents",
  "storageKey": null
},
v12 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "unit",
    "storageKey": null
  }
],
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "AutoWarnTime",
  "kind": "LinkedField",
  "name": "warningTimeFromUnavailabilityStart",
  "plural": false,
  "selections": (v12/*: any*/),
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "autoRejectUnavailabilityEvents",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "AutoRejectTime",
  "kind": "LinkedField",
  "name": "autoRejectTimeFromUnavailabilityStart",
  "plural": false,
  "selections": (v12/*: any*/),
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeOffShiftsEnabled",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeOffShiftNotificationsEnabled",
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeOffRequestDuringBlackoutEnabled",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "timeOffDuringPublishedScheduleEnabled",
  "storageKey": null
},
v20 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "employeeAvailabilityEditableByManager",
  "storageKey": null
},
v21 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "availabilityWarnOnMinNoticeDays",
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "availabilityErrorOnMinNoticeDays",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "availabilityEmployeeComments",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "availabilityManagerComments",
  "storageKey": null
},
v25 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "availabilityTypesEnabled",
  "storageKey": null
},
v26 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "availabilityConsentRequired",
  "storageKey": null
},
v27 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "availabilityUseMaxDurationPerWeek",
  "storageKey": null
},
v28 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "availabilityMaxCycleWeeks",
  "storageKey": null
},
v29 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "availabilityErrorOnRosteredShiftConflict",
  "storageKey": null
},
v30 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "availabilityErrorOnAnyShiftConflict",
  "storageKey": null
},
v31 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "availabilityCoverageHorizonDays",
  "storageKey": null
},
v32 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "availabilityUseConsecutiveDaysOffWaived",
  "storageKey": null
},
v33 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "availabilityChangeDuringPublishedScheduleEnabled",
  "storageKey": null
},
v34 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fixedShiftsEnabled",
  "storageKey": null
},
v35 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fixedShiftsRoleId",
  "storageKey": null
},
v36 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fixedShiftsBreakDurationEnabled",
  "storageKey": null
},
v37 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "managerTerminateUsersEnabled",
  "storageKey": null
},
v38 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unshareOnTermination",
  "storageKey": null
},
v39 = {
  "alias": null,
  "args": null,
  "concreteType": "MandatoryReasonsForEmploymentStatus",
  "kind": "LinkedField",
  "name": "mandatoryReasonsForEmploymentStatus",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "terminated",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v40 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "datapassTrackingEnabled",
  "storageKey": null
},
v41 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "datapassAffectingMetadata",
  "storageKey": null
},
v42 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "homeStoreTransferManagerCommentsEnabled",
  "storageKey": null
},
v43 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "storeTransferOnHireDateEnabled",
  "storageKey": null
},
v44 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shareRequiresApproval",
  "storageKey": null
},
v45 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "minSharingAge",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PeopleSettingsQueriesMutations_UpdateBusinessPeopleSettings_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Business",
        "kind": "LinkedField",
        "name": "updateBusiness",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/),
          (v24/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/),
          (v28/*: any*/),
          (v29/*: any*/),
          (v30/*: any*/),
          (v31/*: any*/),
          (v32/*: any*/),
          (v33/*: any*/),
          (v34/*: any*/),
          (v35/*: any*/),
          (v36/*: any*/),
          (v37/*: any*/),
          (v38/*: any*/),
          (v39/*: any*/),
          (v40/*: any*/),
          (v41/*: any*/),
          (v42/*: any*/),
          (v43/*: any*/),
          (v44/*: any*/),
          (v45/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PeopleSettingsQueriesMutations_UpdateBusinessPeopleSettings_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "Business",
        "kind": "LinkedField",
        "name": "updateBusiness",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v13/*: any*/),
          (v14/*: any*/),
          (v15/*: any*/),
          (v16/*: any*/),
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/),
          (v20/*: any*/),
          (v21/*: any*/),
          (v22/*: any*/),
          (v23/*: any*/),
          (v24/*: any*/),
          (v25/*: any*/),
          (v26/*: any*/),
          (v27/*: any*/),
          (v28/*: any*/),
          (v29/*: any*/),
          (v30/*: any*/),
          (v31/*: any*/),
          (v32/*: any*/),
          (v33/*: any*/),
          (v34/*: any*/),
          (v35/*: any*/),
          (v36/*: any*/),
          (v37/*: any*/),
          (v38/*: any*/),
          (v39/*: any*/),
          (v40/*: any*/),
          (v41/*: any*/),
          (v42/*: any*/),
          (v43/*: any*/),
          (v44/*: any*/),
          (v45/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f356ac4788f7bd3498d1aed1956d5504",
    "id": null,
    "metadata": {},
    "name": "PeopleSettingsQueriesMutations_UpdateBusinessPeopleSettings_Mutation",
    "operationKind": "mutation",
    "text": "mutation PeopleSettingsQueriesMutations_UpdateBusinessPeopleSettings_Mutation(\n  $input: BusinessInput!\n  $id: ID!\n) {\n  updateBusiness(id: $id, input: $input) {\n    employmentExternalManagedFlags {\n      availability\n      contract\n      hireHistory\n      homeStore\n      sharedStore\n      jtc\n      nickname\n      personalDetails\n      roleAndRate\n      school\n      skillLevel\n      timeOff\n    }\n    employeeDraftsEnabled\n    contractsUseEmploymentType\n    contractsConsentEnabled\n    contractsUseMinDurationPerWeek\n    disableEmployeeLeaveRequest\n    timeOffManagerCommentsEnabled\n    timeOffEmployeeCommentsEnabled\n    displayWarningUnavailabilityEvents\n    warningTimeFromUnavailabilityStart {\n      value\n      unit\n    }\n    autoRejectUnavailabilityEvents\n    autoRejectTimeFromUnavailabilityStart {\n      value\n      unit\n    }\n    timeOffShiftsEnabled\n    timeOffShiftNotificationsEnabled\n    timeOffRequestDuringBlackoutEnabled\n    timeOffDuringPublishedScheduleEnabled\n    employeeAvailabilityEditableByManager\n    availabilityWarnOnMinNoticeDays\n    availabilityErrorOnMinNoticeDays\n    availabilityEmployeeComments\n    availabilityManagerComments\n    availabilityTypesEnabled\n    availabilityConsentRequired\n    availabilityUseMaxDurationPerWeek\n    availabilityMaxCycleWeeks\n    availabilityErrorOnRosteredShiftConflict\n    availabilityErrorOnAnyShiftConflict\n    availabilityCoverageHorizonDays\n    availabilityUseConsecutiveDaysOffWaived\n    availabilityChangeDuringPublishedScheduleEnabled\n    fixedShiftsEnabled\n    fixedShiftsRoleId\n    fixedShiftsBreakDurationEnabled\n    managerTerminateUsersEnabled\n    unshareOnTermination\n    mandatoryReasonsForEmploymentStatus {\n      terminated\n    }\n    datapassTrackingEnabled\n    datapassAffectingMetadata\n    homeStoreTransferManagerCommentsEnabled\n    storeTransferOnHireDateEnabled\n    shareRequiresApproval\n    minSharingAge\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'eca40bd3eea42d8f7b29d5e64fe62dfd';
export default node;
