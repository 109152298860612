/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type PerformanceSettingsQueriesMutations_PerformanceSettingsQueryVariables = {
    businessId: string;
};
export type PerformanceSettingsQueriesMutations_PerformanceSettingsQueryResponse = {
    readonly businesses: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly feedbackReasonEnabled: boolean;
                readonly feedbackCommentEnabled: boolean;
                readonly commendationEnabled: boolean;
                readonly approvalNeededForCommendation: boolean;
                readonly commendationEmploymentNotifications: boolean;
                readonly disciplinaryActionEnabled: boolean;
                readonly approvalNeededForDisciplinaryAction: boolean;
                readonly disciplinaryActionEmploymentNotifications: boolean;
            } | null;
        } | null> | null;
    };
};
export type PerformanceSettingsQueriesMutations_PerformanceSettingsQuery = {
    readonly response: PerformanceSettingsQueriesMutations_PerformanceSettingsQueryResponse;
    readonly variables: PerformanceSettingsQueriesMutations_PerformanceSettingsQueryVariables;
};



/*
query PerformanceSettingsQueriesMutations_PerformanceSettingsQuery(
  $businessId: ID!
) {
  businesses(ids: [$businessId]) {
    edges {
      node {
        id
        feedbackReasonEnabled
        feedbackCommentEnabled
        commendationEnabled
        approvalNeededForCommendation
        commendationEmploymentNotifications
        disciplinaryActionEnabled
        approvalNeededForDisciplinaryAction
        disciplinaryActionEmploymentNotifications
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "items": [
          {
            "kind": "Variable",
            "name": "ids.0",
            "variableName": "businessId"
          }
        ],
        "kind": "ListValue",
        "name": "ids"
      }
    ],
    "concreteType": "BusinessConnection",
    "kind": "LinkedField",
    "name": "businesses",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BusinessEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Business",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "feedbackReasonEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "feedbackCommentEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "commendationEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "approvalNeededForCommendation",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "commendationEmploymentNotifications",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "disciplinaryActionEnabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "approvalNeededForDisciplinaryAction",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "disciplinaryActionEmploymentNotifications",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PerformanceSettingsQueriesMutations_PerformanceSettingsQuery",
    "selections": (v1/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PerformanceSettingsQueriesMutations_PerformanceSettingsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "4d4c95c2c2166d6f1c4aa75ef27f40a3",
    "id": null,
    "metadata": {},
    "name": "PerformanceSettingsQueriesMutations_PerformanceSettingsQuery",
    "operationKind": "query",
    "text": "query PerformanceSettingsQueriesMutations_PerformanceSettingsQuery(\n  $businessId: ID!\n) {\n  businesses(ids: [$businessId]) {\n    edges {\n      node {\n        id\n        feedbackReasonEnabled\n        feedbackCommentEnabled\n        commendationEnabled\n        approvalNeededForCommendation\n        commendationEmploymentNotifications\n        disciplinaryActionEnabled\n        approvalNeededForDisciplinaryAction\n        disciplinaryActionEmploymentNotifications\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '3e752668194037161e4a0340226e1400';
export default node;
