import React, { FunctionComponent, useEffect, useState } from "react";
import { createFragmentContainer, Environment, graphql } from "react-relay";
import { toast } from "react-toastify";
import { RouteComponentProps } from "react-router-dom";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import omit from "lodash/omit";
import { Profile_business } from "./__generated__/Profile_business.graphql";
import { UpdateBusinessMutationResponse } from "../mutations/__generated__/UpdateBusinessMutation.graphql";
import UpdateBusinessMutation from "../mutations/UpdateBusinessMutation";
import CreateBusinessMutation from "../mutations/CreateBusinessMutation";
import {
  BusinessInContext,
  useBusinessContext,
} from "../../Context/BusinessContext";
import properties from "../../../data/business-settings.json";
import FormLayout from "../../Form/FormLayout";
import FormLayoutFooter from "../../Form/FormLayoutFooter";
import {
  ComponentRule,
  currencyTypeOptions,
  daysOfWeekOptions,
  IProperty,
  JsonFieldType,
  payFrequencyOptions,
  PayFrequncies,
  punchSlipModeOptions,
  restScreenThemeOptions,
  swapDropFilteringOptions,
  swapSortingOptions,
} from "../../Form/models";
import { EmploymentRateEditPastEnum } from "../../../generated/stack_internal_schema";
import PayDates from "../../Form/PayDates";
import DynamicSelect from "../../Form/DynamicSelect";
import TopLevelForecastMeasurements from "../../Form/TopLevelForecastMeasurements";
import DynamicInputGroup from "../../Form/DynamicInputGroup";
import {
  getFieldsByInputObjectName,
  getSettingsByGroup,
} from "../../Form/formUtilities";
import List from "../../Form/List";
import ImageUpload from "../../Form/ImageUpload";
import { Id } from "../../../models/common";
import BusinessService, { GlobalBusiness } from "../Services/BusinessService";
import Checkbox from "../../Form/Checkbox";
import { useTimeOffShiftComponentRules } from "../../hooks/useTimeOffShiftFieldComponentRules";
import Switch from "../../Form/Switch";
import Field from "../../Form/Field";

interface MatchParams {
  business_id: string;
  stack_id: string;
}

type Props = RouteComponentProps<MatchParams> & {
  business: Profile_business | null;
  environment?: Environment;
};

const Profile: FunctionComponent<Props> = ({
  business: propsBusiness,
  history,
  match: {
    params: { business_id: businessId },
  },
}) => {
  const {
    environment,
    setBusiness: setBusinessInContext,
    appContext: { stack },
  } = useBusinessContext();
  const { t } = useTranslation();
  const [globalBusinesses, setGlobalBusinesses] = useState<
    Map<Id, GlobalBusiness>
  >(new Map<Id, GlobalBusiness>());

  const emplTypeTimeOffComplianceEnabledComponentRules =
    useTimeOffShiftComponentRules();

  const [business, setBusiness] = useState<Profile_business>(
    propsBusiness ?? ({} as Profile_business),
  );

  useEffect(() => {
    async function load() {
      if (!environment) {
        return;
      }
      const maps = await BusinessService.getGlobalBusinesses(environment);
      setGlobalBusinesses(maps);
    }

    load();
  }, [environment]);

  if (!environment) {
    return null;
  }

  const inviteComponentRules = {
    md: 6,
    lg: 6,
    componentProps: {
      xs: 12,
      md: 12,
      lg: 12,
    },
  };

  // Custom component rules
  const componentRules: Record<string, ComponentRule> = {
    inviteHeader: {
      ...inviteComponentRules,
    },
    inviteMessage: {
      ...inviteComponentRules,
    },
    inviteFooter: {
      ...inviteComponentRules,
    },
    analyticsServerUrl: {
      disabled: (values: Profile_business) => !values.analyticsEnabled,
    },
    dataLakeId: {
      disabled: (values: Profile_business) => !values.analyticsEnabled,
    },
    dataLakeGlobalId: {
      disabled: (values: Profile_business) => !values.analyticsEnabled,
    },
    defaultScheduleSnapshotStartDate: {
      disabled: (values: Profile_business) => !values.analyticsEnabled,
    },
    defaultScheduleSnapshotDuration: {
      disabled: (values: Profile_business) => !values.analyticsEnabled,
    },
    analyticsForecastHiddenSettings: {
      disabled: (values: Profile_business) => !values.analyticsEnabled,
    },
    showSystemRolesAndPermissions: {
      lg: 6,
      md: 6,
      xs: 6,
    },
    businessTerms: {
      lg: 6,
      md: 6,
      xs: 6,
    },
    forecastRequiresApproval: {
      disabled: (values: Profile_business) => !values.analyticsEnabled,
      lg: 12,
      md: 12,
      xs: 12,
    },
    canAdjustForecast: {
      disabled: (values: Profile_business) => !values.analyticsEnabled,
      lg: 12,
      md: 12,
      xs: 12,
    },
    payDates: {
      // render payDate using custom component
      component: PayDates,
      componentProps: {},
      disabled: (values: Profile_business) => {
        return values.payFrequency !== PayFrequncies.BI_MONTHLY;
      },
    },
    globalBusinessId: {
      component: DynamicSelect,
      componentProps: {
        options: Array.from(globalBusinesses.values()).map((i) => {
          return { value: i.id, label: i.name };
        }),
        defaultValue: null,
        isClearable: true,
      },
    },
    payFrequency: {
      component: DynamicSelect,
      componentProps: {
        options: payFrequencyOptions,
        defaultValue: null,
      },
    },
    swapDropFiltering: {
      component: DynamicSelect,
      componentProps: {
        options: swapDropFilteringOptions,
        defaultValue: null,
      },
    },
    swapSorting: {
      component: DynamicSelect,
      componentProps: {
        options: swapSortingOptions,
        defaultValue: null,
      },
    },
    rateType: {
      component: (props: any) => {
        return (
          <Field
            {...props}
            hideLabel
            hideDescription
            hideError
            schemaFieldType={JsonFieldType}
            componentProps={{
              defaultValue: [],
            }}
            formGroupClassName="pl-0 pr-0"
          />
        );
      },
      componentProps: {
        xs: 12,
        md: 12,
        lg: 12,
      },
      xs: 8,
      md: 8,
      lg: 8,
    },
    payPeriodSelfApprovalEnabled: {
      disabled: (values: Profile_business) => !values.payPeriodReviewEnabled,
    },
    shiftDropThreshold: {
      disabled: (values: Profile_business) => !values.shiftDropEnabled,
    },
    shiftSwapNeedsApproval: {
      disabled: (values: Profile_business) => !values.shiftSwapEnabled,
    },
    restScreenTheme: {
      component: DynamicSelect,
      componentProps: {
        options: restScreenThemeOptions,
        defaultValue: null,
      },
    },
    punchSlipMode: {
      component: DynamicSelect,
      componentProps: {
        options: punchSlipModeOptions,
        defaultValue: null,
      },
    },
    jobTitlesEnabled: {
      onValueChanged: (value: boolean, formikContext) => {
        if (!value) {
          formikContext.setFieldValue("employmentRateEditEnabled", false);
          formikContext.setFieldValue("deletePrimaryJobTitlesEnabled", false);
          formikContext.setFieldValue(
            "employmentRateEditPast",
            EmploymentRateEditPastEnum.Disabled,
          );
          formikContext.setFieldValue(
            "approvalRequests.employmentRateChange.autoApprovalEnabled",
            // set default value based on value of employmentRateEditEnabled
            false,
          );
        }
      },
    },
    sharedStoreShiftsEnabled: {
      disabled: (values: Profile_business) =>
        !values.crossScheduleComplianceChecksEnabled,
    },
    crossScheduleComplianceChecksEnabled: {
      onValueChanged: (value: boolean, formikContext) => {
        if (!value) {
          formikContext.setFieldValue("sharedStoreShiftsEnabled", false);
        }
      },
    },
    employmentRateEditEnabled: {
      disabled: (values: Profile_business) => !values.jobTitlesEnabled,
      onValueChanged: (value: boolean, formikContext) => {
        formikContext.setFieldValue("deletePrimaryJobTitlesEnabled", false);
        formikContext.setFieldValue(
          "approvalRequests.employmentRateChange.autoApprovalEnabled",
          false,
        );
        formikContext.setFieldValue(
          "employmentRateEditPast",
          // set default value based on value of employmentRateEditEnabled
          value
            ? EmploymentRateEditPastEnum.Enabled
            : EmploymentRateEditPastEnum.Disabled,
        );
      },
      lg: 12,
      md: 12,
      xs: 12,
    },
    employmentRateEditPast: {
      disabled: (values: Profile_business) => !values.employmentRateEditEnabled,
      component: Checkbox,
      hideLabel: true,
      componentProps: {
        toBoolean: (v: EmploymentRateEditPastEnum) =>
          v === EmploymentRateEditPastEnum.Enabled,
        fromBoolean: (v: boolean) =>
          v
            ? EmploymentRateEditPastEnum.Enabled
            : EmploymentRateEditPastEnum.Disabled,
      },
    },
    approvalRequests: {
      hideLabel: true,
      hideDescription: true,
      lg: 3,
      md: 6,
      xs: 12,
    },
    "approvalRequests.employmentRateChange": {
      hideLabel: true,
      hideDescription: true,
      lg: 12,
      md: 12,
      xs: 12,
    },
    "approvalRequests.employmentRateChange.autoApprovalEnabled": {
      disabled: (values: Profile_business) => !values.employmentRateEditEnabled,
      hideDescription: false,
      lg: 12,
      md: 12,
      xs: 12,
    },
    deletePrimaryJobTitlesEnabled: {
      disabled: (values: Profile_business) => !values.employmentRateEditEnabled,
    },
    firstDayOfWeek: {
      component: DynamicSelect,
      componentProps: {
        options: daysOfWeekOptions,
        defaultValue: null,
      },
    },
    topLevelForecastMeasurements: {
      lg: 8,
      md: 12,
      xs: 12,
      component: TopLevelForecastMeasurements,
      componentProps: {},
      disabled: (values: Profile_business) => !values.analyticsEnabled,
    },
    idleTimeoutLength: {
      disabled: (values: Profile_business) => !values.idleTimeoutEnabled,
    },
    darFields: {
      disabled: (values: Profile_business) => !values.darEnabled,
      lg: 12,
      md: 12,
      xs: 12,
      component: List,
      componentProps: {
        defaultValue: {
          name: "",
          fieldCode: "",
          valueType: "",
          description: "",
        },
      },
    },
    darDataOnlyPastSlicesEnabled: {
      disabled: (values: Profile_business) => !values.darEnabled,
    },
    darAnalyticsSyncThreshold: {
      disabled: (values: Profile_business) => !values.darEnabled,
    },
    timeClockAppBusinessImageUri: {
      lg: 12,
      md: 6,
      xs: 12,
      component: ImageUpload,
      componentProps: {
        getValueKey: "timeClockAppBusinessImageId",
        setValueKey: "timeClockAppBusinessImageUri",
        getPreviewUrl: (values: Profile_business, imageId: Id | null) => {
          const { id } = values;
          return imageId
            ? `api/businesses/${id}/time_clock_app_business_images/${imageId}`
            : null;
        },
        dropzoneProps: {
          multiple: false,
          accept: "image/png, image/jpeg",
          maxSize: 500000,
        },
      },
    },
    defaultPayrollCutoffTime: {
      disabled: (values: Profile_business) => !values.aggregateToStartDay,
    },
    liveTimeClockViewEnabled: {
      disabled: (values: Profile_business) => !values.managerAppEnabled,
    },

    swapMobileDisclaimerEnabled: {
      lg: 12,
      md: 12,
      xs: 12,
    },
    swapMobileDisclaimer: {
      disabled: (values: Profile_business) =>
        !values.swapMobileDisclaimerEnabled,
    },
    dropMobileDisclaimerEnabled: {
      lg: 12,
      md: 12,
      xs: 12,
    },
    dropMobileDisclaimer: {
      disabled: (values: Profile_business) =>
        !values.dropMobileDisclaimerEnabled,
    },
    enableShiftDifferentials: {
      xs: 3,
      md: 3,
      lg: 3,
      component: Switch,
      hideLabel: true,
      componentProps: {
        defaultValue: false,
        boldLabels: true,
        onLabel: t("people-settings:form.payroll.differential_rates_enable"),
        offLabel: t("people-settings:form.payroll.differential_rates_disable"),
      },
    },
    enableMiscPay: {
      xs: 3,
      md: 3,
      lg: 3,
      component: Switch,
      hideLabel: true,
      componentProps: {
        boldLabels: true,
        onLabel: t("people-settings:form.payroll.misc_pay_enable"),
        offLabel: t("people-settings:form.payroll.misc_pay_disable"),
      },
    },
    payPeriodReviewEnabled: {
      xs: 3,
      md: 3,
      lg: 3,
      component: Switch,
      label: undefined,
      componentProps: {
        boldLabels: true,
        onLabel: t("people-settings:form.payroll.pay_period_review_enable"),
        offLabel: t("people-settings:form.payroll.pay_period_review_disable"),
      },
    },
    currencyType: {
      component: DynamicSelect,
      componentProps: {
        options: currencyTypeOptions,
        defaultValue: business.currencyType,
      },
    },
    ...emplTypeTimeOffComplianceEnabledComponentRules,
  };

  const onBusinessUpdated = (response: UpdateBusinessMutationResponse) => {
    setBusiness(response.updateBusiness as unknown as Profile_business);
    setBusinessInContext(
      response.updateBusiness as unknown as BusinessInContext,
    );
    toast(t("form.notifications.saved_successfully"));
  };

  const onBusinessCreated = () => {
    toast(t("form.notifications.created_successfully"));
    history.push(`/stack/${stack?.id}/businesses/`);
  };

  const handleSave = (
    changes: Partial<Profile_business>,
    onError: (err: Error) => void,
  ) => {
    changes = omit(changes, "timeClockAppBusinessImageId");

    if (businessId) {
      UpdateBusinessMutation(
        environment,
        changes,
        businessId,
        onBusinessUpdated,
        onError,
      );
    } else {
      // create business
      CreateBusinessMutation(environment, changes, onBusinessCreated, onError);
    }
  };

  // Validation rules
  const validationRules = yup.object({
    shiftDropThreshold: yup.number().min(0),
    timeClockAppPinConfig: yup.object({
      min: yup
        .number()
        .min(6)
        .max(yup.ref("max"))
        .label(t("property.timeClockAppPinConfig.min")),
      max: yup
        .number()
        .min(6)
        .max(8)
        .label(t("property.timeClockAppPinConfig.max")),
    }),
    darFields: yup
      .array()
      .of(
        yup.object({
          name: yup.string().required().label(t("property.name.value")),
          fieldCode: yup
            .string()
            .required()
            .label(t("property.fieldCode.value")),
          valueType: yup
            .string()
            .required()
            .label(t("property.valueType.value")),
        }),
      )
      .nullable(),
    darAnalyticsSyncThreshold: yup
      .number()
      .min(0)
      .label(t("property.darAnalyticsSyncThreshold")),
    swapMobileDisclaimer: yup
      .string()
      .nullable()
      .when("swapMobileDisclaimerEnabled", {
        is: true,
        then: yup
          .string()
          .required()
          .label(t("property.swapMobileDisclaimer.value")),
      }),
    dropMobileDisclaimer: yup
      .string()
      .nullable()
      .when("dropMobileDisclaimerEnabled", {
        is: true,
        then: yup
          .string()
          .required()
          .label(t("property.dropMobileDisclaimer.value")),
      }),
    businessTerms: yup.mixed().isValidJson(t("property.businessTerms.value")),
    webuiViewOptions: yup
      .mixed()
      .isValidJson(t("property.webuiViewOptions.value")),
    timeClockAppWorkflow: yup
      .mixed()
      .isValidJson(t("property.timeClockAppWorkflow.value")),
    reports: yup.mixed().isValidJson(t("property.reports.value")),
    mailerLocales: yup.mixed().isValidJson(t("property.mailerLocales.value")),
    rateType: yup
      .mixed()
      .isValidJson(t("property.rateType.value"))
      .test(
        "invalid_json_structure",
        t("property.rateType.invalid_structure"),
        (rates: Record<string, unknown>[] | null) => {
          return rates == null || Array.isArray(rates);
        },
      ),
    roleJobTitles: yup
      .mixed()
      .isSingleLevelJson(t("property.roleJobTitles.value"), "string"),
    warningCodeMap: yup
      .mixed()
      .isSingleLevelJson(t("property.warningCodeMap.value"), "string", false),
  });

  return (
    <div className="panel">
      <FormLayout<Profile_business>
        base={business}
        onSave={handleSave}
        propertyList={properties as unknown as IProperty[]}
        validationRules={validationRules}
        componentRules={componentRules}
        isCreate={business.id == null}
      >
        <DynamicInputGroup
          fields={getSettingsByGroup(
            getFieldsByInputObjectName(
              properties as unknown as IProperty[],
              "BusinessInput",
            ),
          )}
        />
        <FormLayoutFooter />
      </FormLayout>
    </div>
  );
};

export default createFragmentContainer(
  Profile,
  // Each key specified in this object will correspond to a prop available to the component
  {
    business: graphql`
      # As a convention, we name the fragment as '<ComponentFileName>_<propName>'
      fragment Profile_business on Business {
        id
        timeClockAppBusinessImageId
        ### Replaceable content start
        businessName
        globalBusinessId
        shortDescription
        registrationNumber
        globalImageId
        schoolCalendarsEnabled
        chatEnabled
        inviteHeader
        inviteMessage
        inviteFooter
        accentColor1
        coBrandingEnabled
        firstDayOfWeek
        defaultScheduleDayStartTime
        defaultScheduleDayEndTime
        showEarnings
        showSystemRolesAndPermissions
        businessTerms
        webuiViewOptions
        kpiAvgHourlyRateEnabled
        kpiSalesPerEmployeeHourEnabled
        highlightOverstaffing
        militaryTime
        durationFormat
        dateFormat
        showAvatarIcon
        showNickname
        nicknameReplacesOnlyFirstName
        firstNameCharCount
        lastNameCharCount
        firstNameSuffix
        lastNameSuffix
        idleTimeoutEnabled
        idleTimeoutLength
        rostered
        maxShiftDuration
        autoAcceptRosteredShifts
        markAsAbsentEnabled
        enablePastShiftOperation
        timeOffShiftsEnabled
        timeOffShiftNotificationsEnabled
        shiftCanOverlapLeaveRequest
        shiftCanOverlapUnavailability
        shiftMultiRateEnabled
        shiftCostBreakdownEnabled
        unassignedShiftDefaultRateEnabled
        crossScheduleComplianceChecksEnabled
        sharedStoreShiftsEnabled
        swapDropFiltering
        swapSorting
        shiftSwapEnabled
        shiftSwapNeedsApproval
        swapMobileDisclaimerEnabled
        swapMobileDisclaimer
        shiftDropEnabled
        shiftDropThreshold
        dropMobileDisclaimerEnabled
        dropMobileDisclaimer
        clockTrackingEnabled
        clockInThreshold
        clockOutThreshold
        timekeepingReadonly
        timekeepingRolesVisible
        earlyShiftStartThreshold
        restScreenTheme
        punchSlipMode
        voluntaryLateClockOutEnabled
        timeClockAppFetchScheduleJobTitles
        timeClockAppSyncIntervalInSeconds
        approvalNeededForRoleRateChange
        timeClockRoleRateChangeAuthThreshold
        allowManagerToApproveOwnPunch
        timeClockAppPinConfig {
          min
          max
        }
        timeClockAppWorkflowConfig
        notificationsOnTimeClockAppEnabled
        enableShiftDifferentials
        enableMiscPay
        payPeriodReviewEnabled
        payPeriodSelfApprovalEnabled
        payPeriodDailyReportSplitNames
        payFrequency
        payCycleStartDate
        payDates
        aggregateToStartDay
        defaultPayrollCutoffTime
        earningsEstimator
        payDisclaimer
        roleJobTitles
        warningCodeMap
        rateType
        employeeMultiRateEnabled
        jobTitlesEnabled
        employmentRateEditEnabled
        approvalRequests {
          employmentRateChange {
            autoApprovalEnabled
          }
        }
        employmentRateEditPast
        deletePrimaryJobTitlesEnabled
        analyticsEnabled
        analyticsServerUrl
        defaultScheduleSnapshotStartDate
        defaultScheduleSnapshotDuration
        dataLakeGlobalId
        dataLakeId
        quarterlyAggregationEnabled
        topLevelForecastMeasurements {
          name
          label
          isPrimary
          isCurrency
        }
        forecastRequiresApproval
        canAdjustForecast
        populateScheduleReplanningEnabled
        reportSettings
        csvReportDisclaimerEnabled
        footerDisclaimer
        showEarningsToUser
        showShiftRolesToUser
        showColleaguesToUser
        showBreaksToUser
        anonymousShiftSwapToUser
        anonymousShiftDropToUser
        markAsAbsentEnabledToUser
        managerAppEnabled
        liveTimeClockViewEnabled
        darEnabled
        darFields {
          name
          fieldCode
          valueType
          description
        }
        darDataOnlyPastSlicesEnabled
        darAnalyticsSyncThreshold
        currencyType
        localeSuffix
        mailerLocales
        regularBusinessInviteEnabled
        managerInviteUsersEnabled
        managerAssignUsersToSchedulesEnabled
        timeoutThreshold
        roundingStrategy
        inviteUserConfirmationText
        terminateUserConfirmationText
        assignEmployeeConfirmationText
        unassignEmployeeConfirmationText
        monthlyCostLoadingEnabled
        ### Replaceable content finish
      }
    `,
  },
);
