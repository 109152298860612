/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type BusinessRoleSortField = "businessRoleName" | "id" | "%future added value";
export type Order = "asc" | "desc" | "%future added value";
export type BusinessRoleSort = {
    field: BusinessRoleSortField;
    order: Order;
};
export type BasicFields_BusinessRolesQueryVariables = {
    businessId: string;
    sort?: Array<BusinessRoleSort> | null | undefined;
};
export type BasicFields_BusinessRolesQueryResponse = {
    readonly businessRoles: {
        readonly nodes: ReadonlyArray<{
            readonly id: string;
            readonly businessRoleName: string;
            readonly replaceByAos: boolean;
        } | null> | null;
    };
};
export type BasicFields_BusinessRolesQuery = {
    readonly response: BasicFields_BusinessRolesQueryResponse;
    readonly variables: BasicFields_BusinessRolesQueryVariables;
};



/*
query BasicFields_BusinessRolesQuery(
  $businessId: ID!
  $sort: [BusinessRoleSort!]
) {
  businessRoles(businessId: $businessId, sort: $sort) {
    nodes {
      id
      businessRoleName
      replaceByAos
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "sort"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Variable",
        "name": "sort",
        "variableName": "sort"
      }
    ],
    "concreteType": "BusinessRoleConnection",
    "kind": "LinkedField",
    "name": "businessRoles",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BusinessRole",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "businessRoleName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "replaceByAos",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BasicFields_BusinessRolesQuery",
    "selections": (v1/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BasicFields_BusinessRolesQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "02252ba04ef37e7221fc77b87fee1a0f",
    "id": null,
    "metadata": {},
    "name": "BasicFields_BusinessRolesQuery",
    "operationKind": "query",
    "text": "query BasicFields_BusinessRolesQuery(\n  $businessId: ID!\n  $sort: [BusinessRoleSort!]\n) {\n  businessRoles(businessId: $businessId, sort: $sort) {\n    nodes {\n      id\n      businessRoleName\n      replaceByAos\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'd399bdedbd4e1a8d03df17a0b084ce37';
export default node;
