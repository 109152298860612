/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { Component } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import {
  graphql,
  createPaginationContainer,
  RelayPaginationProp,
} from "react-relay";
import Table from "react-bootstrap/Table";
import { Link, RouteComponentProps } from "react-router-dom";
import { formatDate, toRelative } from "../../../utils/utility";
import { EmploymentTable_viewer as EmploymentTableViewer } from "./__generated__/EmploymentTable_viewer.graphql";
import RelayLazyLoader from "../../common/RelayLazyLoader";
import EmploymentBadge from "./EmploymentBadge";

const EmploymentTablePageQuery = graphql`
  query EmploymentTable_InternalQuery(
    $businessId: ID!
    $searchValue: String
    $deleted: Boolean
    $acceptedInvite: Boolean
    $userLinked: Boolean
    $currentlyEmployed: Boolean
    $pageSize: Int!
    $after: String
  ) {
    ...EmploymentTable_viewer
  }
`;

interface MatchParams {
  business_id: string;
  stack_id: string;
}

export enum EmploymentStatus {
  Any,
  Employed,
  Invited,
  Terminated,
}

export const getCurrentlyEmployedParam = (status: EmploymentStatus) => {
  switch (status) {
    case EmploymentStatus.Terminated:
      return false;
    case EmploymentStatus.Employed:
      return true;
    default:
      return undefined;
  }
};

export const getDeletedParam = (status: EmploymentStatus) => {
  switch (status) {
    case EmploymentStatus.Terminated:
      return true;
    case EmploymentStatus.Invited:
      return false;
    default:
      return undefined;
  }
};

export enum CurrentlyEmployedStatus {
  Any,
  Employed,
  Unemployed,
}
type Props = RouteComponentProps<MatchParams> &
  WithTranslation & {
    stackId: string;
    businessId: string;
    searchValue: string;
    employmentStatus: EmploymentStatus;
    unlinked: boolean;
    viewer: EmploymentTableViewer;
    relay: RelayPaginationProp;
  };

class EmploymentTableBase extends Component<Props> {
  render() {
    const { businessId, stackId, relay, t } = this.props;
    const nodes = (this.props.viewer.employments.edges || []).map((edge) => {
      if (!edge || !edge.node) {
        return null;
      }

      const { node } = edge;
      const {
        businessInvite,
        during,
        userId,
        userEmail,
        firstName,
        lastName,
        email,
        securityRole,
        updatedAt,
        id,
      } = node;

      return (
        <tr key={node.id}>
          <td>
            <Link
              to={`/stack/${stackId}/business/${businessId}/employee/${id}`}
            >
              {firstName} {lastName}
            </Link>
          </td>

          <td>
            {userId ? (
              <Link to={`/account/${userId}`}>{userEmail}</Link>
            ) : (
              "unlinked"
            )}
          </td>
          <td>{email}</td>
          <td>
            <EmploymentBadge employment={node} />
          </td>
          <td>{during}</td>
          <td>
            {businessInvite
              ? formatDate(businessInvite.expiryTime as string, {
                  toFormat: "dd MMM yyyy",
                  showRelative: true,
                })
              : "-"}
          </td>
          <td>{securityRole}</td>
          <td>
            {toRelative(updatedAt as string, {
              defaultValue: "-",
            })}
          </td>
        </tr>
      );
    });

    return (
      <>
        <Table hover size="sm">
          <thead>
            <tr>
              <th>{t("table.headers.name")}</th>
              <th>{t("table.headers.accountEmail")}</th>
              <th>{t("table.headers.employmentEmail")}</th>
              <th>{t("table.headers.employmentStatus")}</th>
              <th>{t("table.headers.employmentDuration")}</th>
              <th>{t("table.headers.businessInvitation")}</th>
              <th>{t("table.headers.securityRole")}</th>
              <th>{t("table.headers.lastUpdate")}</th>
            </tr>
          </thead>
          <tbody>{nodes}</tbody>
        </Table>

        <RelayLazyLoader relay={relay} />
      </>
    );
  }
}

export default createPaginationContainer(
  withTranslation("employment")(EmploymentTableBase),
  {
    viewer: graphql`
      fragment EmploymentTable_viewer on InternalQuery {
        employments(
          businessId: $businessId
          search: $searchValue
          deleted: $deleted
          acceptedInvite: $acceptedInvite
          userLinked: $userLinked
          currentlyEmployed: $currentlyEmployed
          first: $pageSize
          after: $after
        ) @connection(key: "EmploymentTable_employments") {
          edges {
            node {
              id
              firstName
              lastName
              email
              userId
              acceptedInvite
              deleted
              securityRole
              updatedAt
              userEmail
              during
              businessInvite {
                id
                expiryTime
              }
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    `,
  },
  {
    direction: "forward",
    query: EmploymentTablePageQuery,
    getConnectionFromProps: (props) => props.viewer.employments,
    getFragmentVariables: (previousVars, pageSize) => ({
      ...previousVars,
      pageSize,
    }),
    getVariables: (props, paginationInfo) => ({
      businessId: props.businessId,
      searchValue: props.searchValue,
      acceptedInvite:
        props.employmentStatus === EmploymentStatus.Invited ? false : undefined,
      deleted: getDeletedParam(props.employmentStatus),
      userLinked: props.unlinked ? false : undefined,
      currentlyEmployed: getCurrentlyEmployedParam(props.employmentStatus),
      pageSize: paginationInfo.count,
      after: paginationInfo.cursor,
    }),
  },
);
