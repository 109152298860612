/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import AuditLogTablePaginationQuery from "./AuditLogTablePaginationQuery.graphql";
import { FragmentRefs } from "relay-runtime";
export type AuditLogActionTypeEnum = "created" | "deleted" | "specific" | "updated" | "%future added value";
export type AuditLogsQueries_ListFragment = {
    readonly auditLogs: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly actionType: AuditLogActionTypeEnum;
                readonly code: string | null;
                readonly objId: string;
                readonly objType: string;
                readonly occurredAt: string;
                readonly performedBy: {
                    readonly id: string;
                    readonly computedName: string;
                } | null;
                readonly schedule: {
                    readonly scheduleName: string;
                } | null;
                readonly performedById: string | null;
                readonly relatedToId: string | null;
                readonly scheduleId: string | null;
                readonly specificAction: string | null;
            } | null;
        } | null> | null;
        readonly pageInfo: {
            readonly hasNextPage: boolean;
            readonly endCursor: string | null;
        };
    };
    readonly " $refType": "AuditLogsQueries_ListFragment";
};
export type AuditLogsQueries_ListFragment$data = AuditLogsQueries_ListFragment;
export type AuditLogsQueries_ListFragment$key = {
    readonly " $data"?: AuditLogsQueries_ListFragment$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"AuditLogsQueries_ListFragment">;
};



const node: ReaderFragment = (function(){
var v0 = [
  "auditLogs"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "actionType"
    },
    {
      "kind": "RootArgument",
      "name": "after"
    },
    {
      "kind": "RootArgument",
      "name": "businessId"
    },
    {
      "kind": "RootArgument",
      "name": "endDateTime"
    },
    {
      "kind": "RootArgument",
      "name": "objId"
    },
    {
      "kind": "RootArgument",
      "name": "objectType"
    },
    {
      "kind": "RootArgument",
      "name": "pageSize"
    },
    {
      "kind": "RootArgument",
      "name": "performedById"
    },
    {
      "kind": "RootArgument",
      "name": "relatedToId"
    },
    {
      "kind": "RootArgument",
      "name": "requestId"
    },
    {
      "kind": "RootArgument",
      "name": "scheduleId"
    },
    {
      "kind": "RootArgument",
      "name": "sort"
    },
    {
      "kind": "RootArgument",
      "name": "specificAction"
    },
    {
      "kind": "RootArgument",
      "name": "startDateTime"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "pageSize",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "pageSize",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": AuditLogTablePaginationQuery
    }
  },
  "name": "AuditLogsQueries_ListFragment",
  "selections": [
    {
      "alias": "auditLogs",
      "args": [
        {
          "kind": "Variable",
          "name": "actionType",
          "variableName": "actionType"
        },
        {
          "kind": "Variable",
          "name": "businessId",
          "variableName": "businessId"
        },
        {
          "kind": "Variable",
          "name": "endDateTime",
          "variableName": "endDateTime"
        },
        {
          "kind": "Variable",
          "name": "objId",
          "variableName": "objId"
        },
        {
          "kind": "Variable",
          "name": "objType",
          "variableName": "objectType"
        },
        {
          "kind": "Variable",
          "name": "performedById",
          "variableName": "performedById"
        },
        {
          "kind": "Variable",
          "name": "relatedToId",
          "variableName": "relatedToId"
        },
        {
          "kind": "Variable",
          "name": "requestId",
          "variableName": "requestId"
        },
        {
          "kind": "Variable",
          "name": "scheduleId",
          "variableName": "scheduleId"
        },
        {
          "kind": "Variable",
          "name": "sort",
          "variableName": "sort"
        },
        {
          "kind": "Variable",
          "name": "specificAction",
          "variableName": "specificAction"
        },
        {
          "kind": "Variable",
          "name": "startDateTime",
          "variableName": "startDateTime"
        }
      ],
      "concreteType": "AuditLogConnection",
      "kind": "LinkedField",
      "name": "__AuditLogTable_auditLogs_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AuditLogEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AuditLog",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "actionType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "code",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "objId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "objType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "occurredAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Employment",
                  "kind": "LinkedField",
                  "name": "performedBy",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "computedName",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Schedule",
                  "kind": "LinkedField",
                  "name": "schedule",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "scheduleName",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "performedById",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "relatedToId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "scheduleId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "specificAction",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "InternalQuery",
  "abstractKey": null
};
})();
(node as any).hash = '239c458a612b91c9313ddc6f9a18c2b9';
export default node;
