import React, { Component } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import {
  graphql,
  createPaginationContainer,
  RelayPaginationProp,
} from "react-relay";
import Table from "react-bootstrap/Table";
import { ScheduleTable_viewer } from "./__generated__/ScheduleTable_viewer.graphql";
import RelayLazyLoader from "../../common/RelayLazyLoader";

const ScheduleTablePageQuery = graphql`
  query ScheduleTable_InternalQuery(
    $businessId: ID!
    $searchValue: String
    $pageSize: Int!
    $after: String
  ) {
    ...ScheduleTable_viewer
  }
`;

interface MatchParams {
  business_id: string;
  stack_id: string;
}

type Props = RouteComponentProps<MatchParams> & {
  stackId: string;
  businessId: string;
  searchValue: string;
  viewer: ScheduleTable_viewer;
  relay: RelayPaginationProp;
};

class ScheduleTableBase extends Component<Props> {
  render() {
    const { stackId, businessId, viewer, relay } = this.props;
    const nodes = (viewer.schedules.edges || []).map((edge: any) => (
      <tr key={edge.node.id}>
        <td>
          <Link
            to={`/stack/${stackId}/business/${businessId}/schedule/${edge.node.id}`}
          >
            {edge.node.scheduleName}
          </Link>
        </td>
        <td>{!edge.node.isGroup ? "schedule" : "group"}</td>
      </tr>
    ));

    return (
      <>
        <Table hover size="sm">
          <thead>
            <tr>
              <th>Schedule Name</th>
              <th>Group / Schedule</th>
            </tr>
          </thead>
          <tbody>{nodes}</tbody>
        </Table>
        <RelayLazyLoader relay={relay} />
      </>
    );
  }
}

export default createPaginationContainer(
  ScheduleTableBase,
  {
    viewer: graphql`
      fragment ScheduleTable_viewer on InternalQuery {
        schedules(
          businessId: $businessId
          search: $searchValue
          first: $pageSize
          after: $after
          isGroup: false
        ) @connection(key: "ScheduleTable_schedules") {
          edges {
            node {
              id
              scheduleName
              isGroup
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    `,
  },
  {
    direction: "forward",
    query: ScheduleTablePageQuery,
    getConnectionFromProps: (props) => props.viewer.schedules,
    getFragmentVariables: (previousVars, pageSize) => ({
      ...previousVars,
      pageSize,
    }),
    getVariables: (props, paginationInfo) => ({
      businessId: props.businessId,
      searchValue: props.searchValue,
      pageSize: paginationInfo.count,
      after: paginationInfo.cursor,
    }),
  },
);
