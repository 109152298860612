import React from "react";
import { graphql, createFragmentContainer } from "react-relay";
import { toast } from "react-toastify";
import * as yup from "yup";
import { Profile_user } from "./__generated__/Profile_user.graphql";

import properties from "../../../../data/user-settings.json";
import { idmInternalEnvironment } from "../../../../environment";
import FormLayout from "../../../Form/FormLayout";
import FormLayoutFooter from "../../../Form/FormLayoutFooter";
import { BusinessContext } from "../../../Context/BusinessContext";
import { IProperty } from "../../../Form/models";
import DynamicInputGroup from "../../../Form/DynamicInputGroup";
import {
  getFieldsByInputObjectName,
  getSettingsByGroup,
} from "../../../Form/formUtilities";
import UpdateUserMutation from "../mutations/UpdateUserMutation";

type Props = {
  user: Profile_user;
};

type State = {};

const validationRules = yup.object({
  email: yup.string().email(),
  // TODO: add more rules
});

class Profile extends React.Component<Props, State> {
  static contextType = BusinessContext;

  // eslint-disable-next-line class-methods-use-this
  private onSaved() {
    toast("Save successfully");
  }

  // Add a new event handler that fires off the mutation
  private handleSave = (
    values: Partial<Profile_user>,
    onError: (err: Error) => void,
  ) => {
    UpdateUserMutation(
      idmInternalEnvironment,
      this.props.user.id,
      values,
      this.onSaved.bind(this),
      onError,
    );
  };

  render() {
    const { user } = this.props;
    if (user) {
      return (
        <div className="panel">
          <FormLayout<Profile_user>
            base={user}
            onSave={this.handleSave}
            propertyList={properties as unknown as IProperty[]}
            validationRules={validationRules}
          >
            <DynamicInputGroup
              fields={getSettingsByGroup(
                getFieldsByInputObjectName(
                  properties as unknown as IProperty[],
                  "UserInput",
                ),
              )}
            />
            <FormLayoutFooter />
          </FormLayout>
        </div>
      );
    }
    return <div>User not found</div>;
  }
}

export default createFragmentContainer(
  Profile,
  // Each key specified in this object will correspond to a prop available to the component
  {
    user: graphql`
      # As a convention, we name the fragment as '<ComponentFileName>_<propName>'
      fragment Profile_user on User {
        id
        email
        locale
      }
    `,
  },
);
