/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type ScheduleService_SchedulesByName_QueryVariables = {
    businessId: string;
    search?: string | null | undefined;
};
export type ScheduleService_SchedulesByName_QueryResponse = {
    readonly schedules: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly scheduleName: string;
            } | null;
        } | null> | null;
    };
};
export type ScheduleService_SchedulesByName_Query = {
    readonly response: ScheduleService_SchedulesByName_QueryResponse;
    readonly variables: ScheduleService_SchedulesByName_QueryVariables;
};



/*
query ScheduleService_SchedulesByName_Query(
  $businessId: ID!
  $search: String
) {
  schedules(businessId: $businessId, search: $search, sort: [{field: scheduleName, order: desc}], isGroup: false) {
    edges {
      node {
        id
        scheduleName
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Literal",
        "name": "isGroup",
        "value": false
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      },
      {
        "kind": "Literal",
        "name": "sort",
        "value": [
          {
            "field": "scheduleName",
            "order": "desc"
          }
        ]
      }
    ],
    "concreteType": "ScheduleConnection",
    "kind": "LinkedField",
    "name": "schedules",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ScheduleEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Schedule",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "scheduleName",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ScheduleService_SchedulesByName_Query",
    "selections": (v1/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ScheduleService_SchedulesByName_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "63232ab0ba6c721b53083030d213fa07",
    "id": null,
    "metadata": {},
    "name": "ScheduleService_SchedulesByName_Query",
    "operationKind": "query",
    "text": "query ScheduleService_SchedulesByName_Query(\n  $businessId: ID!\n  $search: String\n) {\n  schedules(businessId: $businessId, search: $search, sort: [{field: scheduleName, order: desc}], isGroup: false) {\n    edges {\n      node {\n        id\n        scheduleName\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '32ca641b1dac484b70f05712b1439ade';
export default node;
