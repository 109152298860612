import { FormikContext } from "formik";
import React from "react";
import Dropzone, { DropzoneProps, DropzoneRootProps } from "react-dropzone";
import styled from "styled-components";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { Translation } from "react-i18next";
import { Id } from "../../models/common";
import ImagePreview, { ImagePreviewState } from "./ImagePreview";
import ButtonIcon from "../common/ButtonIcon";

type Props<T> = {
  value: string;
  fieldKey: string;
  onChange: (v: any) => void;
  getPreviewUrl: (obj: T, imageId: Id | null) => string;
  disabled?: boolean;
  setValueKey: string;
  dropzoneProps?: DropzoneProps;
};

type State = {
  file: File | null;
  imagePreviewUrl: string | ArrayBuffer | null;
};

const getColor = (props: DropzoneRootProps) => {
  if (props.isDragAccept) {
    return props.theme.bluePrimary;
  }
  if (props.isDragReject) {
    return props.theme.error;
  }
  if (props.isDragActive) {
    return props.theme.success;
  }
  return props.theme.borderColor;
};

const StyledDiv = styled.div<DropzoneRootProps>`
  border: 2px dashed ${(props) => props.theme.borderColor};
  border-color: ${(props) => getColor(props)};
  transition: border 0.24s ease-in-out;
  border-radius: 2px;
  padding: 16px 8px;
  max-width: 280px;
  outline: none;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default class ImageUpload<T> extends React.Component<Props<T>, State> {
  constructor(props: Props<T>) {
    super(props);
    this.state = {
      file: null,
      imagePreviewUrl: null,
    };
  }

  render() {
    const {
      getPreviewUrl,
      value,
      onChange,
      disabled,
      setValueKey,
      fieldKey,

      dropzoneProps,
    } = this.props;
    const { imagePreviewUrl } = this.state;

    return (
      <FormikContext.Consumer>
        {(formikContext) => {
          const { initialValues, setFieldValue, getFieldMeta, setFieldError } =
            formikContext;
          const { initialValue } = getFieldMeta(fieldKey);

          let previewState = ImagePreviewState.Preview;
          let path: string | null = null;
          if (value == null) {
            previewState = ImagePreviewState.Empty;
          } else if (value === initialValue) {
            previewState = ImagePreviewState.Saved;
            path = getPreviewUrl(initialValues, value);
          }

          return (
            <Translation>
              {(t) => (
                <div>
                  <Row>
                    <Col md="auto" className="text-center">
                      <ImagePreview
                        previewDataUrl={imagePreviewUrl}
                        imagePath={path}
                        imagePreviewState={previewState}
                      />
                      {previewState !== ImagePreviewState.Empty ? (
                        <ButtonIcon
                          className="ml-1 text-black-50"
                          icon={faTimesCircle}
                          disabled={disabled}
                          onClick={() => {
                            this.setState({
                              file: null,
                              imagePreviewUrl: null,
                            });
                            onChange(null);
                            setFieldValue(setValueKey, null);
                          }}
                        />
                      ) : null}
                    </Col>
                    <Col>
                      <Dropzone
                        onDrop={(files) => {
                          const reader = new FileReader();
                          const file = files[0] ?? null;
                          if (!file) {
                            setFieldError(
                              fieldKey,
                              t("imageUpload.invalidFile"),
                            );
                            return;
                          }
                          reader.onloadend = () => {
                            this.setState({
                              file,
                              imagePreviewUrl: reader.result as string,
                            });

                            onChange(reader.result);
                            setFieldValue(setValueKey, reader.result);
                          };

                          reader.readAsDataURL(file);
                        }}
                        {...dropzoneProps}
                      >
                        {({
                          getRootProps,
                          getInputProps,
                          isDragActive,
                          isDragAccept,
                          isDragReject,
                        }) => (
                          <StyledDiv
                            {...getRootProps({
                              isDragActive,
                              isDragAccept,
                              isDragReject,
                            })}
                          >
                            <input {...getInputProps()} />
                            <div>
                              <span className="text-primary mr-1">
                                {t("imageUpload.browse")}
                              </span>
                              <span>{t("imageUpload.dragAndDrop")}</span>
                            </div>
                            <div className="text-muted">
                              {dropzoneProps?.maxSize ? (
                                <small>
                                  {t("imageUpload.maxFileSize", {
                                    sizeKb: dropzoneProps.maxSize / 1000,
                                  })}
                                </small>
                              ) : null}
                              {dropzoneProps?.accept ? (
                                <div>
                                  <small>
                                    {t("imageUpload.acceptedFileTypes", {
                                      formats: dropzoneProps.accept,
                                    })}
                                  </small>
                                </div>
                              ) : null}
                            </div>
                          </StyledDiv>
                        )}
                      </Dropzone>
                    </Col>
                  </Row>
                </div>
              )}
            </Translation>
          );
        }}
      </FormikContext.Consumer>
    );
  }
}
