import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ToastContainer } from "react-toastify";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  RouteComponentProps,
} from "react-router-dom";
import { QueryRenderer, graphql, RelayEnvironmentProvider } from "react-relay";
import styled from "styled-components";
import { IUser, SessionInfo } from "../../../models/common";

import Accounts from "../internal/Account/Accounts";
import AccountLayout from "../internal/Account/AccountLayout";
import Businesses from "../../Stack/Business/Businesses";
import StackContextLayout from "../../Stack/StackContextLayout";
import Sidebar from "../../Sidebar/Sidebar";
import { ModalContextProvider } from "../../Context/ModalContextLegacy";
import AppContext, { AppContextProvider } from "./Context/AppContext";
import Authentication from "../../../utils/authentication";
import TopNav from "../../TopNav/TopNav";
import Stacks from "../internal/Stack/Stacks";
import StackLayout from "../internal/Stack/StackLayout";

import {
  getRegionalStackEnvironment,
  idmExternalEnvironment,
} from "../../../environment";
import { AuthenticatedApp_Query } from "./__generated__/AuthenticatedApp_Query.graphql";
import Loader from "../../common/Loader";
import GlobalBusinessLayout from "../../Stack/GlobalBusiness/GlobalBusinessLayout";
import CorporatesLayout from "../internal/Corporates/CorporatesLayout/CorporatesLayout";
import Corporates from "../internal/Corporates/Corporates";

const AuthenticatedAppQuery = graphql`
  query AuthenticatedApp_Query {
    stacks {
      edges {
        node {
          id
          domainName
          deleted
          stackCode
        }
      }
    }
  }
`;

type State = {
  session: SessionInfo | null;
};

type Props = {
  user: IUser;
  handleLogout: () => void;
  stackCode: string;
};

const StyledContainer = styled(Container)`
  padding-left: 0px;
  padding-right: 0px;
`;

const NonWrappingRow = styled(Row)`
  flex-wrap: nowrap;
`;

const StyledContent = styled(Col)`
  padding-left: 200px !important;
`;

export default class AuthenticatedApp extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const s = Authentication.restoreSession();

    this.state = {
      session: s,
    };
  }

  render() {
    const { user, handleLogout, stackCode } = this.props;

    return (
      <QueryRenderer<AuthenticatedApp_Query>
        environment={idmExternalEnvironment}
        query={AuthenticatedAppQuery}
        variables={{}}
        render={({ error, props }) => {
          if (error) {
            // TODO: check error type?

            return (
              <Router>
                {/* show top nav bar so that user can log out */}
                <TopNav handleLogout={handleLogout} user={user}>
                  <div id="header-portal" />
                </TopNav>
                <Container fluid className="justify-content-md-center">
                  <div>Error! {error.message}</div>
                </Container>
              </Router>
            );
          }

          if (!props) {
            return <Loader />;
          }

          const stacks = (props.stacks?.edges || []).map(
            (edge) => edge?.node as any,
          );

          if (!stacks.length) {
            alert("Account does not have any access");
            handleLogout();
            return null;
          }

          return (
            <Router basename={stackCode}>
              <div className="App bg-light">
                <AppContextProvider
                  user={user}
                  stacks={stacks}
                  stackCode={stackCode}
                >
                  <ModalContextProvider>
                    <ToastContainer />
                    <StyledContainer fluid>
                      <NonWrappingRow noGutters>
                        <div className="pull-left">
                          <Sidebar />
                        </div>
                        <StyledContent className="col-md-12 content">
                          <TopNav handleLogout={handleLogout} user={user}>
                            <div id="header-portal" />
                          </TopNav>
                          <Container fluid>
                            <Route path="/" exact>
                              <Redirect to="/stacks" />
                            </Route>
                            <AppContext.Consumer>
                              {({ stack }) => {
                                return (
                                  <RelayEnvironmentProvider
                                    environment={getRegionalStackEnvironment(
                                      stack?.domainName,
                                    )}
                                  >
                                    <Route
                                      path="/stack/:stack_id/businesses/"
                                      exact
                                      render={(p: RouteComponentProps<any>) => (
                                        <Businesses {...p} />
                                      )}
                                    />

                                    <Route
                                      path={[
                                        "/stack/:stack_id/business/create",
                                        "/stack/:stack_id/business/:business_id",
                                      ]}
                                      render={(p: RouteComponentProps<any>) => (
                                        <StackContextLayout {...p} />
                                      )}
                                    />
                                  </RelayEnvironmentProvider>
                                );
                              }}
                            </AppContext.Consumer>
                            <Route
                              path="/accounts"
                              exact
                              render={(p: RouteComponentProps<any>) => (
                                <Accounts {...p} />
                              )}
                            />
                            <Route
                              path={["/account/create", "/account/:user_id"]}
                              render={(p: RouteComponentProps<any>) => (
                                <AccountLayout {...p} />
                              )}
                            />
                            <Route
                              path="/stacks"
                              exact
                              render={(p: RouteComponentProps<any>) => (
                                <Stacks {...p} />
                              )}
                            />
                            <Route
                              path={[
                                "/stack_people/:stack_id/",
                                "/stack_global_business/:stack_id/",
                              ]}
                              exact
                              render={(p: RouteComponentProps<any>) => (
                                <StackLayout {...p} />
                              )}
                            />
                            <Route
                              path={["/stack/create", "/stack/:stack_id"]}
                              exact
                              render={(p: RouteComponentProps<any>) => (
                                <StackLayout {...p} />
                              )}
                            />
                            <Route
                              path={[
                                "/stack/:stack_id/global_business/create",
                                "/stack/:stack_id/global_business/edit/:global_business_id",
                              ]}
                              exact
                              render={(p: RouteComponentProps<any>) => (
                                <GlobalBusinessLayout {...p} />
                              )}
                            />
                            <Route
                              path={["/corporates"]}
                              exact
                              component={Corporates}
                            />
                            <Route
                              path={[
                                "/corporates/edit/:corporate_id",
                                "/corporates/create",
                              ]}
                              component={CorporatesLayout}
                            />
                          </Container>
                        </StyledContent>
                      </NonWrappingRow>
                    </StyledContainer>
                  </ModalContextProvider>
                </AppContextProvider>
              </div>
            </Router>
          );
        }}
      />
    );
  }
}
