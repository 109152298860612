/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type SSOAuthMethodUpdateInput = {
    authType?: string | null | undefined;
    code?: string | null | undefined;
    corporateId?: string | null | undefined;
    displayName?: string | null | undefined;
    employmentAttrToSearch?: string | null | undefined;
    employmentIdAttribute?: string | null | undefined;
    metadataConfig?: string | null | undefined;
    metadataIssuer?: string | null | undefined;
    metadataOverrides?: unknown | null | undefined;
    metadataUrl?: string | null | undefined;
};
export type CorporatesAuthMethodsQueriesMutations_UpdateAuthMethod_MutationVariables = {
    id: string;
    input: SSOAuthMethodUpdateInput;
};
export type CorporatesAuthMethodsQueriesMutations_UpdateAuthMethod_MutationResponse = {
    readonly updateSsoAuthMethod: {
        readonly id: string;
        readonly displayName: string;
        readonly code: string;
        readonly authType: string;
        readonly metadataIssuer: string | null;
        readonly metadataUrl: string | null;
        readonly metadataConfig: string | null;
        readonly metadataOverrides: unknown | null;
        readonly employmentIdAttribute: string | null;
        readonly employmentAttrToSearch: string;
        readonly corporateId: string;
        readonly updatedAt: string;
    };
};
export type CorporatesAuthMethodsQueriesMutations_UpdateAuthMethod_Mutation = {
    readonly response: CorporatesAuthMethodsQueriesMutations_UpdateAuthMethod_MutationResponse;
    readonly variables: CorporatesAuthMethodsQueriesMutations_UpdateAuthMethod_MutationVariables;
};



/*
mutation CorporatesAuthMethodsQueriesMutations_UpdateAuthMethod_Mutation(
  $id: ID!
  $input: SSOAuthMethodUpdateInput!
) {
  updateSsoAuthMethod(id: $id, input: $input) {
    id
    displayName
    code
    authType
    metadataIssuer
    metadataUrl
    metadataConfig
    metadataOverrides
    employmentIdAttribute
    employmentAttrToSearch
    corporateId
    updatedAt
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SSOAuthMethod",
    "kind": "LinkedField",
    "name": "updateSsoAuthMethod",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "displayName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "authType",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "metadataIssuer",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "metadataUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "metadataConfig",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "metadataOverrides",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "employmentIdAttribute",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "employmentAttrToSearch",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "corporateId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "updatedAt",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CorporatesAuthMethodsQueriesMutations_UpdateAuthMethod_Mutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CorporatesAuthMethodsQueriesMutations_UpdateAuthMethod_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8cdec28c651b27fea34ff0ed819b9d04",
    "id": null,
    "metadata": {},
    "name": "CorporatesAuthMethodsQueriesMutations_UpdateAuthMethod_Mutation",
    "operationKind": "mutation",
    "text": "mutation CorporatesAuthMethodsQueriesMutations_UpdateAuthMethod_Mutation(\n  $id: ID!\n  $input: SSOAuthMethodUpdateInput!\n) {\n  updateSsoAuthMethod(id: $id, input: $input) {\n    id\n    displayName\n    code\n    authType\n    metadataIssuer\n    metadataUrl\n    metadataConfig\n    metadataOverrides\n    employmentIdAttribute\n    employmentAttrToSearch\n    corporateId\n    updatedAt\n  }\n}\n"
  }
};
})();
(node as any).hash = '21767d2e05fe8f7d45e171779ce702f2';
export default node;
