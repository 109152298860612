import React from "react";
import { graphql, QueryRenderer, Environment } from "react-relay";
import { RouteComponentProps } from "react-router-dom";
import Card from "react-bootstrap/Card";
import Profile from "./Profile";

import Loader from "../../../common/Loader";
import { AccountProfile_Query } from "./__generated__/AccountProfile_Query.graphql";

const AccountProfileQuery = graphql`
  query AccountProfile_Query($userId: ID!) {
    users(ids: [$userId]) {
      edges {
        node {
          ...Profile_user
        }
      }
    }
  }
`;

export interface MatchParams {
  user_id: string;
}

type Props = RouteComponentProps<MatchParams> & {
  environment: Environment;
};

type State = {};

export default class AccountProfile extends React.PureComponent<Props, State> {
  render() {
    const properties = this.props;
    const { match, environment } = properties;
    const { params } = match;
    const userId = params.user_id;

    return (
      <QueryRenderer<AccountProfile_Query>
        environment={environment}
        query={AccountProfileQuery}
        variables={{
          userId,
        }}
        render={({ error, props }) => {
          if (error) {
            return <div>Error!</div>;
          }
          if (!props) {
            return <Loader />;
          }

          return (
            <Card body>
              <Profile
                {...properties}
                user={(props as any).users.edges[0].node}
              />
            </Card>
          );
        }}
      />
    );
  }
}
