import { graphql, useLazyLoadQuery } from "react-relay";
import {
  CorporatesAuthMethodsQueriesMutations_GetAuthMethodsQuery,
  CorporatesAuthMethodsQueriesMutations_GetAuthMethodsQueryVariables,
} from "./__generated__/CorporatesAuthMethodsQueriesMutations_GetAuthMethodsQuery.graphql";

import {
  CorporatesAuthMethodsQueriesMutations_GetAuthMethodByIdQuery,
  CorporatesAuthMethodsQueriesMutations_GetAuthMethodByIdQueryResponse,
} from "./__generated__/CorporatesAuthMethodsQueriesMutations_GetAuthMethodByIdQuery.graphql";

export const GetSSOAuthMethodsQuery = graphql`
  query CorporatesAuthMethodsQueriesMutations_GetAuthMethodsQuery(
    $corporateIds: [ID!]
  ) {
    ssoAuthMethods(corporateIds: $corporateIds) {
      edges {
        node {
          id
          displayName
          code
          authType
          metadataSyncedAt
          updatedAt
        }
      }
    }
  }
`;

export const GetSSOAuthMethodByIdQuery = graphql`
  query CorporatesAuthMethodsQueriesMutations_GetAuthMethodByIdQuery(
    $id: ID!
    $skip: Boolean!
  ) {
    ssoAuthMethods(ids: [$id]) @skip(if: $skip) {
      nodes {
        id
        ### Replaceable content start
        displayName
        code
        authType
        metadataIssuer
        metadataUrl
        metadataConfig
        metadataOverrides
        employmentIdAttribute
        employmentAttrToSearch
        corporateId
        ### Replaceable content finish
        updatedAt
      }
    }
  }
`;

type ResponseType =
  {} & CorporatesAuthMethodsQueriesMutations_GetAuthMethodByIdQueryResponse["ssoAuthMethods"];
type NodeType = {} & ResponseType["nodes"];
export type SSOAuthMethodFormData = {} & NodeType[number];

export function useCorporateAuthMethodLazyLoad(
  corporateVariables: Partial<CorporatesAuthMethodsQueriesMutations_GetAuthMethodsQueryVariables>,
  networkPolicy = false,
) {
  const queryData =
    useLazyLoadQuery<CorporatesAuthMethodsQueriesMutations_GetAuthMethodsQuery>(
      GetSSOAuthMethodsQuery,
      corporateVariables,
      {
        ...(networkPolicy && { fetchPolicy: "network-only" }),
      },
    );

  const authMethods =
    queryData?.ssoAuthMethods?.edges?.map((i) => i?.node ?? null) ?? [];

  return [authMethods] as const;
}

export function useCorporateAuthMethodById(
  authMethodId?: string,
  networkPolicy = false,
) {
  const queryData =
    useLazyLoadQuery<CorporatesAuthMethodsQueriesMutations_GetAuthMethodByIdQuery>(
      GetSSOAuthMethodByIdQuery,
      { id: authMethodId ?? "", skip: authMethodId == null },
      {
        ...(networkPolicy && { fetchPolicy: "network-only" }),
      },
    );

  return queryData.ssoAuthMethods?.nodes?.[0] ?? null;
}

export const CreateAuthMethodMutation = graphql`
  mutation CorporatesAuthMethodsQueriesMutations_CreateAuthMethod_Mutation(
    $input: SSOAuthMethodCreateInput!
  ) {
    createSsoAuthMethod(input: $input) {
      id
      ### Replaceable content start
      displayName
      code
      authType
      metadataIssuer
      metadataUrl
      metadataConfig
      metadataOverrides
      employmentIdAttribute
      employmentAttrToSearch
      corporateId
      ### Replaceable content finish
      updatedAt
    }
  }
`;

export const UpdateAuthMethodMutation = graphql`
  mutation CorporatesAuthMethodsQueriesMutations_UpdateAuthMethod_Mutation(
    $id: ID!
    $input: SSOAuthMethodUpdateInput!
  ) {
    updateSsoAuthMethod(id: $id, input: $input) {
      id
      ### Replaceable content start
      displayName
      code
      authType
      metadataIssuer
      metadataUrl
      metadataConfig
      metadataOverrides
      employmentIdAttribute
      employmentAttrToSearch
      corporateId
      ### Replaceable content finish
      updatedAt
    }
  }
`;

export const DeleteAuthMethodMutation = graphql`
  mutation CorporatesAuthMethodsQueriesMutations_DeleteAuthMethod_Mutation(
    $id: ID!
  ) {
    deleteSsoAuthMethod(id: $id)
  }
`;
