import React, { Component } from "react";
import styled from "styled-components";
// import { isEmptyChildren } from "../../utils/utility";

const StyledSubgroupName = styled("legend")`
  font-size: 13px;
  font-weight: bold;
  padding-top: 12px;
`;

// eslint-disable-next-line @typescript-eslint/no-use-before-define
type Props = typeof SubGroupHeader.defaultProps & {
  groupName?: string;
  className?: string;
};

export default class SubGroupHeader extends Component<Props> {
  static defaultProps = {
    className: "sub-group",
  };

  render() {
    const { children, className } = this.props;

    return (
      <StyledSubgroupName className={className}>{children}</StyledSubgroupName>
    );
  }
}
