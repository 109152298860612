import React from "react";
import styled from "styled-components";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Translation } from "react-i18next";
import { getImageUrl } from "../../environment";
import AppContext from "../IDM/external/Context/AppContext";

const StyledPreviewImg = styled("img")`
  width: 88px;
  background-color: ${(props) => props.theme.grey100};
`;

export enum ImagePreviewState {
  Saved, // Image present on initial load or after reset
  Preview, // New image selected
  Empty, // Image not present
}

type Props = {
  imagePath: string | null;
  previewDataUrl: string | ArrayBuffer | null;
  imagePreviewState: ImagePreviewState;
};

type State = {
  imagePathPreviewUrl: string | null;
  stackDomain?: string;
};

export default class ImagePreview extends React.Component<Props, State> {
  static contextType = AppContext;

  constructor(props: Props) {
    super(props);
    this.state = {
      imagePathPreviewUrl: null,
    };
  }

  async componentDidMount() {
    const { imagePath } = this.props;
    await this.setImagePreviewUrl(imagePath);
  }

  async componentDidUpdate(previousProps: Props) {
    const { imagePath } = this.props;

    if (previousProps.imagePath !== imagePath) {
      this.revokePreviewObjectUrl();
      await this.setImagePreviewUrl(imagePath);
    }
  }

  componentWillUnmount() {
    this.revokePreviewObjectUrl();
  }

  async setImagePreviewUrl(imagePath: string | null) {
    const { stack } = this.context;
    if (imagePath) {
      const url = await getImageUrl(stack?.domainName, imagePath);
      this.setState({
        imagePathPreviewUrl: url,
      });
    } else {
      this.setState({
        imagePathPreviewUrl: null,
      });
    }
  }

  private revokePreviewObjectUrl() {
    const { imagePathPreviewUrl } = this.state;
    if (imagePathPreviewUrl) {
      // Revoke the data uris to avoid memory leaks
      URL.revokeObjectURL(imagePathPreviewUrl);
    }
  }

  render() {
    const { imagePathPreviewUrl } = this.state;
    const { previewDataUrl, imagePreviewState } = this.props;

    switch (imagePreviewState) {
      case ImagePreviewState.Empty:
        return (
          <FontAwesomeIcon
            icon={faCloudUploadAlt}
            size="2x"
            className="text-black-50"
          />
        );

      default:
        return (
          <Translation>
            {(t) => (
              <StyledPreviewImg
                src={
                  imagePreviewState === ImagePreviewState.Preview
                    ? (previewDataUrl as string)
                    : (imagePathPreviewUrl as string)
                }
                alt={t("imagePreview.preview")}
              />
            )}
          </Translation>
        );
    }
  }
}
