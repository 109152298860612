import React from "react";
import Navbar from "react-bootstrap/Navbar";
import styled, { ThemeProvider } from "styled-components";
import defaultTheme from "../../styles/theme";

type Props = {};
type State = {};

const StyledNavbar = styled(Navbar)`
  position: sticky;
  background-color: ${(props) => props.theme.footer.backgroundColor};
  border-right: 1px solid ${(props) => props.theme.borderColor};
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: -20px;
  z-index: 0;
`;

export default (p: React.PropsWithChildren<Props>) => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <StyledNavbar fixed="bottom">{p.children}</StyledNavbar>
    </ThemeProvider>
  );
};
