/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type AosConfigTemplateCreateInput = {
    aosConfig?: AosConfigInput | null | undefined;
    isDefault?: boolean | null | undefined;
    templateName: string;
};
export type AosConfigInput = {
    addCoreBreaks?: boolean | null | undefined;
    addUnderstaffingShifts?: boolean | null | undefined;
    aosCombinedRoles?: unknown | null | undefined;
    aosOrchestratorConfig?: unknown | null | undefined;
    aosTimeout?: number | null | undefined;
    applyRule24HoursRest?: boolean | null | undefined;
    bonusRoles?: Array<string> | null | undefined;
    breakEndPad?: number | null | undefined;
    breakLength?: number | null | undefined;
    breakMaxStart?: number | null | undefined;
    breakMinStart?: number | null | undefined;
    breakTrigger?: number | null | undefined;
    coreObeysAosRules?: boolean | null | undefined;
    costEqualLabor?: number | null | undefined;
    daypartRanks?: unknown | null | undefined;
    demandOnly?: Array<string> | null | undefined;
    flexibleRole?: string | null | undefined;
    ignoreBreakRoles?: Array<string> | null | undefined;
    includeRoles?: Array<string> | null | undefined;
    maxRolesPerShift?: number | null | undefined;
    maxWorkdaysCalweek?: number | null | undefined;
    maxWorkdaysWorkweek?: number | null | undefined;
    minShiftPartLength?: number | null | undefined;
    optimizeCoreBreaks?: boolean | null | undefined;
    overstaffingByRole?: Array<string> | null | undefined;
    overstaffingPenalty?: number | null | undefined;
    penaltyShortParts?: number | null | undefined;
    penaltyShortPartsCutoff?: number | null | undefined;
    planningOrder?: Array<string> | null | undefined;
    replanningOrchestratorConfig?: unknown | null | undefined;
    replanningTimeout?: number | null | undefined;
    schedulingDayStart?: string | null | undefined;
    shiftConfig?: unknown | null | undefined;
    shiftGapMin?: number | null | undefined;
    shiftLengthPreference?: number | null | undefined;
    shiftMax?: number | null | undefined;
    shiftMaxStart?: string | null | undefined;
    shiftMin?: number | null | undefined;
    shiftMinAbsolute?: number | null | undefined;
    skillPreference?: number | null | undefined;
    skipSchedulingCore?: boolean | null | undefined;
    skipSchedulingManagers?: boolean | null | undefined;
    understaffingByRole?: Array<string> | null | undefined;
    weeklyDayparts?: unknown | null | undefined;
    weeklyWorkhoursMax?: number | null | undefined;
    weeklyWorkhoursMin?: number | null | undefined;
    weightEqualLaborByRating?: Array<number> | null | undefined;
};
export type CreateAosConfigTemplateMutationVariables = {
    businessId: string;
    input: AosConfigTemplateCreateInput;
};
export type CreateAosConfigTemplateMutationResponse = {
    readonly createAosConfigTemplate: {
        readonly id: string;
        readonly templateName: string;
        readonly isDefault: boolean;
        readonly aosConfig: {
            readonly shiftConfig: unknown | null;
            readonly schedulingDayStart: string | null;
            readonly addUnderstaffingShifts: boolean | null;
            readonly weeklyWorkhoursMin: number | null;
            readonly weeklyWorkhoursMax: number | null;
            readonly shiftMin: number | null;
            readonly shiftMax: number | null;
            readonly shiftMinAbsolute: number | null;
            readonly shiftGapMin: number | null;
            readonly shiftMaxStart: string | null;
            readonly maxRolesPerShift: number | null;
            readonly minShiftPartLength: number | null;
            readonly breakTrigger: number | null;
            readonly breakLength: number | null;
            readonly breakMinStart: number | null;
            readonly breakMaxStart: number | null;
            readonly breakEndPad: number | null;
            readonly includeRoles: ReadonlyArray<string> | null;
            readonly demandOnly: ReadonlyArray<string> | null;
            readonly bonusRoles: ReadonlyArray<string> | null;
            readonly ignoreBreakRoles: ReadonlyArray<string> | null;
            readonly flexibleRole: string | null;
            readonly coreObeysAosRules: boolean | null;
            readonly skipSchedulingManagers: boolean | null;
            readonly skipSchedulingCore: boolean | null;
            readonly addCoreBreaks: boolean | null;
            readonly optimizeCoreBreaks: boolean | null;
            readonly weeklyDayparts: unknown | null;
            readonly daypartRanks: unknown | null;
            readonly planningOrder: ReadonlyArray<string> | null;
            readonly overstaffingPenalty: number | null;
            readonly skillPreference: number | null;
            readonly shiftLengthPreference: number | null;
            readonly penaltyShortParts: number | null;
            readonly penaltyShortPartsCutoff: number | null;
            readonly costEqualLabor: number | null;
            readonly weightEqualLaborByRating: ReadonlyArray<number> | null;
            readonly applyRule24HoursRest: boolean | null;
            readonly maxWorkdaysCalweek: number | null;
            readonly maxWorkdaysWorkweek: number | null;
            readonly overstaffingByRole: ReadonlyArray<string> | null;
            readonly understaffingByRole: ReadonlyArray<string> | null;
            readonly aosTimeout: number | null;
            readonly aosCombinedRoles: unknown | null;
            readonly aosOrchestratorConfig: unknown | null;
            readonly replanningTimeout: number | null;
            readonly replanningOrchestratorConfig: unknown | null;
        };
    };
};
export type CreateAosConfigTemplateMutation = {
    readonly response: CreateAosConfigTemplateMutationResponse;
    readonly variables: CreateAosConfigTemplateMutationVariables;
};



/*
mutation CreateAosConfigTemplateMutation(
  $businessId: ID!
  $input: AosConfigTemplateCreateInput!
) {
  createAosConfigTemplate(businessId: $businessId, input: $input) {
    id
    templateName
    isDefault
    aosConfig {
      shiftConfig
      schedulingDayStart
      addUnderstaffingShifts
      weeklyWorkhoursMin
      weeklyWorkhoursMax
      shiftMin
      shiftMax
      shiftMinAbsolute
      shiftGapMin
      shiftMaxStart
      maxRolesPerShift
      minShiftPartLength
      breakTrigger
      breakLength
      breakMinStart
      breakMaxStart
      breakEndPad
      includeRoles
      demandOnly
      bonusRoles
      ignoreBreakRoles
      flexibleRole
      coreObeysAosRules
      skipSchedulingManagers
      skipSchedulingCore
      addCoreBreaks
      optimizeCoreBreaks
      weeklyDayparts
      daypartRanks
      planningOrder
      overstaffingPenalty
      skillPreference
      shiftLengthPreference
      penaltyShortParts
      penaltyShortPartsCutoff
      costEqualLabor
      weightEqualLaborByRating
      applyRule24HoursRest
      maxWorkdaysCalweek
      maxWorkdaysWorkweek
      overstaffingByRole
      understaffingByRole
      aosTimeout
      aosCombinedRoles
      aosOrchestratorConfig
      replanningTimeout
      replanningOrchestratorConfig
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AosConfigTemplate",
    "kind": "LinkedField",
    "name": "createAosConfigTemplate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "templateName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "isDefault",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AosConfig",
        "kind": "LinkedField",
        "name": "aosConfig",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shiftConfig",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "schedulingDayStart",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "addUnderstaffingShifts",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "weeklyWorkhoursMin",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "weeklyWorkhoursMax",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shiftMin",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shiftMax",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shiftMinAbsolute",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shiftGapMin",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shiftMaxStart",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "maxRolesPerShift",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "minShiftPartLength",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "breakTrigger",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "breakLength",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "breakMinStart",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "breakMaxStart",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "breakEndPad",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "includeRoles",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "demandOnly",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "bonusRoles",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ignoreBreakRoles",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "flexibleRole",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "coreObeysAosRules",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "skipSchedulingManagers",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "skipSchedulingCore",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "addCoreBreaks",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "optimizeCoreBreaks",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "weeklyDayparts",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "daypartRanks",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "planningOrder",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "overstaffingPenalty",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "skillPreference",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shiftLengthPreference",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "penaltyShortParts",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "penaltyShortPartsCutoff",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "costEqualLabor",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "weightEqualLaborByRating",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "applyRule24HoursRest",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "maxWorkdaysCalweek",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "maxWorkdaysWorkweek",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "overstaffingByRole",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "understaffingByRole",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "aosTimeout",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "aosCombinedRoles",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "aosOrchestratorConfig",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "replanningTimeout",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "replanningOrchestratorConfig",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateAosConfigTemplateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateAosConfigTemplateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a3d7c0f40318a80dbd273f75a4af60ae",
    "id": null,
    "metadata": {},
    "name": "CreateAosConfigTemplateMutation",
    "operationKind": "mutation",
    "text": "mutation CreateAosConfigTemplateMutation(\n  $businessId: ID!\n  $input: AosConfigTemplateCreateInput!\n) {\n  createAosConfigTemplate(businessId: $businessId, input: $input) {\n    id\n    templateName\n    isDefault\n    aosConfig {\n      shiftConfig\n      schedulingDayStart\n      addUnderstaffingShifts\n      weeklyWorkhoursMin\n      weeklyWorkhoursMax\n      shiftMin\n      shiftMax\n      shiftMinAbsolute\n      shiftGapMin\n      shiftMaxStart\n      maxRolesPerShift\n      minShiftPartLength\n      breakTrigger\n      breakLength\n      breakMinStart\n      breakMaxStart\n      breakEndPad\n      includeRoles\n      demandOnly\n      bonusRoles\n      ignoreBreakRoles\n      flexibleRole\n      coreObeysAosRules\n      skipSchedulingManagers\n      skipSchedulingCore\n      addCoreBreaks\n      optimizeCoreBreaks\n      weeklyDayparts\n      daypartRanks\n      planningOrder\n      overstaffingPenalty\n      skillPreference\n      shiftLengthPreference\n      penaltyShortParts\n      penaltyShortPartsCutoff\n      costEqualLabor\n      weightEqualLaborByRating\n      applyRule24HoursRest\n      maxWorkdaysCalweek\n      maxWorkdaysWorkweek\n      overstaffingByRole\n      understaffingByRole\n      aosTimeout\n      aosCombinedRoles\n      aosOrchestratorConfig\n      replanningTimeout\n      replanningOrchestratorConfig\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '62f3c1c251a12624a37013abb296dd96';
export default node;
