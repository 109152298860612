import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: "en-US",
    fallbackLng: "en",
    debug: true,
    ns: [
      "translation",
      "aos",
      "audit-logs",
      "corporates",
      "accounts",
      "employment",
      "pay-periods",
      "skill-levels",
      "stacks",
      "people-settings",
      "time-off-types",
      "break-types",
      "employment-types",
      "businesses",
      "metadata-types",
      "corporates-auth-methods",
      "feedback-reasons",
    ],
    defaultNS: "translation",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
      // fetch translations on demand but allow them to be fingerprinted
      request: async (
        options: Record<string, any>,
        url: string,
        payload: Record<string, string>,
        callback: (
          error: Error | null,
          result: { status: number; data: string | null },
        ) => void,
      ) => {
        try {
          const result = await import(`.${url}`);
          callback(null, { status: 200, data: JSON.stringify(result) });
        } catch (error) {
          callback(error as Error, { status: 404, data: null });
        }
      },
    },
  });

export default i18n;
