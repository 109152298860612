/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type MetadataTypeDataTypeEnum = "boolean" | "date" | "number" | "object" | "string" | "timestamp" | "%future added value";
export type MetadataLayoutQueriesMutations_GetMetadataTypesForLayouts_QueryVariables = {
    businessId: string;
};
export type MetadataLayoutQueriesMutations_GetMetadataTypesForLayouts_QueryResponse = {
    readonly metadataTypes: {
        readonly nodes: ReadonlyArray<{
            readonly id: string;
            readonly name: string;
            readonly displayName: string | null;
            readonly required: boolean;
            readonly dataType: MetadataTypeDataTypeEnum;
            readonly internalAccess: boolean;
        } | null> | null;
    };
};
export type MetadataLayoutQueriesMutations_GetMetadataTypesForLayouts_Query = {
    readonly response: MetadataLayoutQueriesMutations_GetMetadataTypesForLayouts_QueryResponse;
    readonly variables: MetadataLayoutQueriesMutations_GetMetadataTypesForLayouts_QueryVariables;
};



/*
query MetadataLayoutQueriesMutations_GetMetadataTypesForLayouts_Query(
  $businessId: ID!
) {
  metadataTypes(businessId: $businessId) {
    nodes {
      id
      name
      displayName
      required
      dataType
      internalAccess
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      }
    ],
    "concreteType": "MetadataTypeConnection",
    "kind": "LinkedField",
    "name": "metadataTypes",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MetadataType",
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "required",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "dataType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "internalAccess",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MetadataLayoutQueriesMutations_GetMetadataTypesForLayouts_Query",
    "selections": (v1/*: any*/),
    "type": "InternalQuery",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MetadataLayoutQueriesMutations_GetMetadataTypesForLayouts_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "751595590faeda2e50e181ea8cda7c40",
    "id": null,
    "metadata": {},
    "name": "MetadataLayoutQueriesMutations_GetMetadataTypesForLayouts_Query",
    "operationKind": "query",
    "text": "query MetadataLayoutQueriesMutations_GetMetadataTypesForLayouts_Query(\n  $businessId: ID!\n) {\n  metadataTypes(businessId: $businessId) {\n    nodes {\n      id\n      name\n      displayName\n      required\n      dataType\n      internalAccess\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'e27699bf8e5822b93e31fca19f2adfaf';
export default node;
