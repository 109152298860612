/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type TimeClockAppPreShiftMessageTypeEnum = "configurable" | "predefined" | "%future added value";
export type ScheduleUpdateInput = {
    analyticsEnabled?: boolean | null | undefined;
    aosConfig?: AosConfigInput | null | undefined;
    approvalNeededForEarlyBreakEnd?: boolean | null | undefined;
    approvalNeededForMinorViolations?: boolean | null | undefined;
    approvalNeededForNonCompliantBreak?: boolean | null | undefined;
    approvalNeededForPinLogin?: boolean | null | undefined;
    approvalNeededForRoleRateChange?: boolean | null | undefined;
    approvalNeededForShiftChange?: boolean | null | undefined;
    approvalNeededForUnplannedShift?: boolean | null | undefined;
    autoGfeConfig?: unknown | null | undefined;
    autoGfeEnabled?: boolean | null | undefined;
    autoPopulateScheduleAtTime?: number | null | undefined;
    autoPopulateScheduleDaysInAdvance?: number | null | undefined;
    autoPopulateScheduleEnabled?: boolean | null | undefined;
    autoPublishConfig?: unknown | null | undefined;
    autoPublishEnabled?: boolean | null | undefined;
    clockInThreshold?: number | null | undefined;
    clockOutThreshold?: number | null | undefined;
    clockTrackingEnabled?: boolean | null | undefined;
    code?: string | null | undefined;
    complianceStartDate?: unknown | null | undefined;
    dataLakeId?: string | null | undefined;
    dayEndTime?: string | null | undefined;
    dayPartInheritedScheduleId?: string | null | undefined;
    dayPartTemplateId?: string | null | undefined;
    dayStartTime?: string | null | undefined;
    employeeMultiRateEnabled?: boolean | null | undefined;
    firstDayOfWeek?: number | null | undefined;
    hrGoLiveDate?: unknown | null | undefined;
    idleTimeoutEnabled?: boolean | null | undefined;
    idleTimeoutLength?: number | null | undefined;
    labourCostPercentage?: Array<number> | null | undefined;
    labourCostPercentageEnabled?: boolean | null | undefined;
    locale?: string | null | undefined;
    monthlyCostLoading?: number | null | undefined;
    onCostDefinitionId?: string | null | undefined;
    payCycleStartDate?: unknown | null | undefined;
    payDates?: Array<number> | null | undefined;
    payFrequency?: string | null | undefined;
    payPeriodEnabled?: boolean | null | undefined;
    payPeriodRequiresApproval?: boolean | null | undefined;
    payrollCutoffTime?: string | null | undefined;
    populateScheduleConfig?: unknown | null | undefined;
    populateScheduleEnabled?: boolean | null | undefined;
    populateScheduleReplanningEnabled?: boolean | null | undefined;
    punchSlipMode?: string | null | undefined;
    restScreenTheme?: string | null | undefined;
    ruleSetId?: string | null | undefined;
    scheduleName?: string | null | undefined;
    scheduleStatus?: string | null | undefined;
    settings?: unknown | null | undefined;
    shiftCanOverlapLeaveRequest?: boolean | null | undefined;
    shiftCanOverlapUnavailability?: boolean | null | undefined;
    shiftDropEnabled?: boolean | null | undefined;
    shiftMultiRateEnabled?: boolean | null | undefined;
    shiftShowUpThreshold?: number | null | undefined;
    shiftSwapEnabled?: boolean | null | undefined;
    shiftSwapNeedsApproval?: boolean | null | undefined;
    showEarnings?: boolean | null | undefined;
    snapshotDuration?: number | null | undefined;
    snapshotStartDate?: unknown | null | undefined;
    timeClockAppPreShiftFormattedMessages?: TimeClockAppPreShiftFormattedShiftMessagesInput | null | undefined;
    timeClockAppPreShiftMessages?: Array<string> | null | undefined;
    timeClockAppRestScreenSettings?: TimeClockAppRestScreenSettingsInput | null | undefined;
    timeClockAppWorkflow?: string | null | undefined;
    timeClockRoleRateChangeAuthThreshold?: number | null | undefined;
    timekeepingGoLiveDate?: unknown | null | undefined;
    unplannedShiftStartThreshold?: number | null | undefined;
    usesBiometrics?: boolean | null | undefined;
    usesPin?: boolean | null | undefined;
    voluntaryLateClockOutEnabled?: boolean | null | undefined;
};
export type AosConfigInput = {
    addCoreBreaks?: boolean | null | undefined;
    addUnderstaffingShifts?: boolean | null | undefined;
    aosCombinedRoles?: unknown | null | undefined;
    aosOrchestratorConfig?: unknown | null | undefined;
    aosTimeout?: number | null | undefined;
    applyRule24HoursRest?: boolean | null | undefined;
    bonusRoles?: Array<string> | null | undefined;
    breakEndPad?: number | null | undefined;
    breakLength?: number | null | undefined;
    breakMaxStart?: number | null | undefined;
    breakMinStart?: number | null | undefined;
    breakTrigger?: number | null | undefined;
    coreObeysAosRules?: boolean | null | undefined;
    costEqualLabor?: number | null | undefined;
    daypartRanks?: unknown | null | undefined;
    demandOnly?: Array<string> | null | undefined;
    flexibleRole?: string | null | undefined;
    ignoreBreakRoles?: Array<string> | null | undefined;
    includeRoles?: Array<string> | null | undefined;
    maxRolesPerShift?: number | null | undefined;
    maxWorkdaysCalweek?: number | null | undefined;
    maxWorkdaysWorkweek?: number | null | undefined;
    minShiftPartLength?: number | null | undefined;
    optimizeCoreBreaks?: boolean | null | undefined;
    overstaffingByRole?: Array<string> | null | undefined;
    overstaffingPenalty?: number | null | undefined;
    penaltyShortParts?: number | null | undefined;
    penaltyShortPartsCutoff?: number | null | undefined;
    planningOrder?: Array<string> | null | undefined;
    replanningOrchestratorConfig?: unknown | null | undefined;
    replanningTimeout?: number | null | undefined;
    schedulingDayStart?: string | null | undefined;
    shiftConfig?: unknown | null | undefined;
    shiftGapMin?: number | null | undefined;
    shiftLengthPreference?: number | null | undefined;
    shiftMax?: number | null | undefined;
    shiftMaxStart?: string | null | undefined;
    shiftMin?: number | null | undefined;
    shiftMinAbsolute?: number | null | undefined;
    skillPreference?: number | null | undefined;
    skipSchedulingCore?: boolean | null | undefined;
    skipSchedulingManagers?: boolean | null | undefined;
    understaffingByRole?: Array<string> | null | undefined;
    weeklyDayparts?: unknown | null | undefined;
    weeklyWorkhoursMax?: number | null | undefined;
    weeklyWorkhoursMin?: number | null | undefined;
    weightEqualLaborByRating?: Array<number> | null | undefined;
};
export type TimeClockAppPreShiftFormattedShiftMessagesInput = {
    configurable?: Array<TimeClockAppPreShiftMessageConfigurableInput> | null | undefined;
    predefined?: Array<string> | null | undefined;
    style?: TimeClockAppPreShiftMessageTypeEnum | null | undefined;
};
export type TimeClockAppPreShiftMessageConfigurableInput = {
    buttons: Array<string>;
    code: string;
    message: string;
    title: string;
    version: number;
};
export type TimeClockAppRestScreenSettingsInput = {
    breakCompletedUpperBound?: number | null | undefined;
    mealBreakApproachingLowerBound?: number | null | undefined;
    mealBreakDueUpperBound?: number | null | undefined;
    mealBreakEndApproachingLowerBound?: number | null | undefined;
    mealBreakEndDueUpperBound?: number | null | undefined;
    mealBreakOverdueUpperBound?: number | null | undefined;
    onTimeThreshold?: number | null | undefined;
    shiftApproachingLowerBound?: number | null | undefined;
    shiftDueUpperBound?: number | null | undefined;
    shiftEndApproachingLowerBound?: number | null | undefined;
    shiftEndDueUpperBound?: number | null | undefined;
    shiftLateUpperBound?: number | null | undefined;
    shiftOverdueUpperBound?: number | null | undefined;
};
export type UpdateScheduleAOSAdvancedMutationVariables = {
    input: ScheduleUpdateInput;
    id: string;
    businessId: string;
};
export type UpdateScheduleAOSAdvancedMutationResponse = {
    readonly updateSchedule: {
        readonly id: string;
        readonly populateScheduleEnabled: boolean;
        readonly aosConfigSchema: unknown;
        readonly populateScheduleReplanningEnabled: boolean;
        readonly aosConfig: {
            readonly aosTimeout: number | null;
            readonly aosCombinedRoles: unknown | null;
            readonly aosOrchestratorConfig: unknown | null;
            readonly replanningTimeout: number | null;
            readonly replanningOrchestratorConfig: unknown | null;
        } | null;
    };
};
export type UpdateScheduleAOSAdvancedMutation = {
    readonly response: UpdateScheduleAOSAdvancedMutationResponse;
    readonly variables: UpdateScheduleAOSAdvancedMutationVariables;
};



/*
mutation UpdateScheduleAOSAdvancedMutation(
  $input: ScheduleUpdateInput!
  $id: ID!
  $businessId: ID!
) {
  updateSchedule(businessId: $businessId, id: $id, input: $input) {
    id
    populateScheduleEnabled
    aosConfigSchema
    populateScheduleReplanningEnabled
    aosConfig {
      aosTimeout
      aosCombinedRoles
      aosOrchestratorConfig
      replanningTimeout
      replanningOrchestratorConfig
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "businessId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "businessId",
        "variableName": "businessId"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Schedule",
    "kind": "LinkedField",
    "name": "updateSchedule",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "populateScheduleEnabled",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "aosConfigSchema",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "populateScheduleReplanningEnabled",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AosConfig",
        "kind": "LinkedField",
        "name": "aosConfig",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "aosTimeout",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "aosCombinedRoles",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "aosOrchestratorConfig",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "replanningTimeout",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "replanningOrchestratorConfig",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateScheduleAOSAdvancedMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "UpdateScheduleAOSAdvancedMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "9ca59189f4a900e66cb7db9ea2eb14e7",
    "id": null,
    "metadata": {},
    "name": "UpdateScheduleAOSAdvancedMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateScheduleAOSAdvancedMutation(\n  $input: ScheduleUpdateInput!\n  $id: ID!\n  $businessId: ID!\n) {\n  updateSchedule(businessId: $businessId, id: $id, input: $input) {\n    id\n    populateScheduleEnabled\n    aosConfigSchema\n    populateScheduleReplanningEnabled\n    aosConfig {\n      aosTimeout\n      aosCombinedRoles\n      aosOrchestratorConfig\n      replanningTimeout\n      replanningOrchestratorConfig\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'a91e814df6b17c8802d085e468f9ef05';
export default node;
