import React, { Component } from "react";
import isFunction from "lodash/isFunction";
import range from "lodash/range";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import {
  BaseOption,
  DayOfWeekEnum,
  daysOfWeekAbbreviated,
} from "../../models/common";
import { isEmptyChildren } from "../../utils/utility";

import "./WeekTable.scss";

// eslint-disable-next-line @typescript-eslint/no-use-before-define
type Props = typeof WeekTable.defaultProps & {
  onAddDayItem?: (
    index: number,
    day: BaseOption<string, number>,
    event: React.MouseEvent<HTMLElement | MouseEvent>,
  ) => any;
  className?: string;
  startDay?: DayOfWeekEnum;
};

type State = {};

export default class WeekTable extends Component<Props> {
  static defaultProps = {
    startDay: DayOfWeekEnum.Monday,
  };

  render() {
    const { children, onAddDayItem, className, startDay } = this.props;
    const startDayIndex = daysOfWeekAbbreviated.findIndex(
      (i) => i.value === startDay,
    );

    // get days of week to render based on starting day
    const daysOfWeek = range(0, 7).map(
      (i: number) => daysOfWeekAbbreviated[(i + startDayIndex) % 7],
    );

    const footer = onAddDayItem ? (
      <tfoot>
        <tr>
          {daysOfWeek.map((day: BaseOption<string, number>, i: number) => (
            <td key={`footer-${day.value}`}>
              <Button
                size="sm"
                block
                variant="light"
                onClick={(e: React.MouseEvent<HTMLElement | MouseEvent>) =>
                  onAddDayItem(i, day, e)
                }
              >
                +
              </Button>
            </td>
          ))}
        </tr>
      </tfoot>
    ) : null;

    const getChildren = (day: BaseOption<string, number>, index: number) => {
      if (children) {
        if (isFunction(children)) {
          return (
            children as (
              d: BaseOption<string, number>,
              i: number,
              p: Props,
            ) => React.ReactNode
          )(day, index, this.state as Props);
        }

        if (!isEmptyChildren(children)) {
          return children;
        }
      }

      return null;
    };

    return (
      <Table className={`week-table ${className || ""} table-fixed mb-0`}>
        <thead>
          <tr>
            {daysOfWeek.map((day: BaseOption<string, number>) => (
              <th key={`header-${day.value}`}>
                <div>{day.label}</div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            {daysOfWeek.map(
              (day: BaseOption<string, number>, index: number) => (
                <td key={`cell-${day.value}`}>{getChildren(day, index)}</td>
              ),
            )}
          </tr>
        </tbody>

        {footer}
      </Table>
    );
  }
}
