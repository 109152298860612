import { FormikContextType } from "formik";
import { ComponentClass, FunctionComponent } from "react";
import { ColProps } from "react-bootstrap";
import {
  MetadataTypeDataTypeEnum,
  MetadataTypeObjectClassEnum,
  EmploymentTypeEnum,
  LegacyBreakTypeEnum,
  TimeClockAppPreShiftMessageTypeEnum,
  EmploymentTypeFixedShiftRestrictionOptionEnum,
  CurrencyEnum,
  SwapDropFilteringEnum,
  SwapSortingEnum,
} from "../../generated/stack_internal_schema";

// supported Kind
export enum Kind {
  SCALAR = "SCALAR",
  INPUT_OBJECT = "INPUT_OBJECT",
  LIST = "LIST",
  NON_NULL = "NON_NULL",
}

export enum Type {
  String = "String",
  Int = "Int",
  BigInt = "BigInt",
  JSON = "JSON",
  Float = "Float",
  Boolean = "Boolean",
  Minutes = "Minutes",
  Seconds = "Seconds",
  ID = "ID",
}

export type SchemaFieldType = {
  kind: string;
  name: string;
  ofType: SchemaFieldType | null;
};

export const StringFieldType: SchemaFieldType = {
  kind: Kind.SCALAR,
  name: Type.String,
  ofType: null,
};

export const BooleanFieldType: SchemaFieldType = {
  kind: Kind.SCALAR,
  name: Type.Boolean,
  ofType: null,
};

export const FloatFieldType: SchemaFieldType = {
  kind: Kind.SCALAR,
  name: Type.Float,
  ofType: null,
};

export const BigIntFieldType: SchemaFieldType = {
  kind: Kind.SCALAR,
  name: Type.BigInt,
  ofType: null,
};

export const IntegerFieldType: SchemaFieldType = {
  kind: Kind.SCALAR,
  name: Type.Int,
  ofType: null,
};

export const JsonFieldType: SchemaFieldType = {
  kind: Kind.SCALAR,
  name: Type.JSON,
  ofType: null,
};

export const JSONStringListType: SchemaFieldType = {
  kind: Kind.LIST,
  name: Type.JSON,
  ofType: StringFieldType,
};

export const JSONIntListType: SchemaFieldType = {
  kind: Kind.LIST,
  name: Type.JSON,
  ofType: IntegerFieldType,
};

export interface IProperty {
  key: string;
  name: string;
  type: SchemaFieldType;
  component?: string;
  group?: string;
  subGroup?: string;
  label?: string;
  description?: string;
  inputObjectName: string;
  show: string;
}

export type GroupName = string;
export type SubGroupName = string;

export type DisabledRule = boolean | ((x: any) => boolean);

export type FormGroupProperties = ColProps & {
  label?: string;
  description?: string;
  hideLabel?: boolean;
  hideError?: boolean;
  hideDescription?: boolean;
  className?: string;
  horizontal?: boolean;
  tooltipText?: string;
  isExternal?: boolean;
  isRequired?: boolean;
  boldLabel?: boolean;
  hide?: boolean;
  formGroupClassName?: string;
};

export type FieldProperties = FormGroupProperties & {
  postfix?: string;
  formControlType?: string;
  placeholder?: string;
  readOnly?: boolean;
  max?: number | string;
  maxLength?: number;
  min?: number | string;
  minLength?: number;
};

export type CommonComponentRule = FieldProperties & {
  component?: ComponentClass<any, any> | FunctionComponent<any>;
  componentProps?: Record<string, any>;
};

export type ComponentRule = CommonComponentRule & {
  disabled?: DisabledRule;
  onValueChanged?: (
    newValue: any,
    formikContext: FormikContextType<any>,
  ) => any;
  postfix?: string;
};

export enum PayFrequncies {
  WEEKLY = "weekly",
  FORTNIGHTLY = "fortnightly",
  TWICE_MONTHLY = "twice_monthly",
  FOUR_WEEKLY = "four_weekly",
  MONTHLY = "monthly",
  QUARTERLY = "quarterly",
  BI_MONTHLY = "bi_monthly",
}

export const payFrequencyOptions = [
  { label: "Weekly", value: PayFrequncies.WEEKLY },
  { label: "Fortnightly", value: PayFrequncies.FORTNIGHTLY },
  { label: "Twice monthly", value: PayFrequncies.TWICE_MONTHLY },
  { label: "Four weekly", value: PayFrequncies.FOUR_WEEKLY },
  { label: "Monthly", value: PayFrequncies.MONTHLY },
  { label: "Quarterly", value: PayFrequncies.QUARTERLY },
  { label: "Bi-monthly", value: PayFrequncies.BI_MONTHLY },
];

export const swapDropFilteringOptions = [
  { label: "Default", value: SwapDropFilteringEnum.Default },
  { label: "JTC", value: SwapDropFilteringEnum.Jtc },
];

export const swapSortingOptions = [
  { label: "Default", value: SwapSortingEnum.Default },
  { label: "Skill Level", value: SwapSortingEnum.SkillLevel },
];

export const restScreenThemeOptions = [
  { label: "Dark", value: "dark" },
  { label: "Light", value: "light" },
];

export const punchSlipModeOptions = [
  { label: "Always", value: "always" },
  { label: "Optional", value: "optional" },
  { label: "Never", value: "never" },
];

export const currencyTypeOptions = [
  { label: "USD", value: CurrencyEnum.Usd },
  { label: "AUD", value: CurrencyEnum.Aud },
  { label: "CAD", value: CurrencyEnum.Cad },
  { label: "NZD", value: CurrencyEnum.Nzd },
  { label: "GBP", value: CurrencyEnum.Gbp },
  { label: "EUR", value: CurrencyEnum.Eur },
];

export const employmentRateEditPastOptions = [
  { label: "Cannot edit in past", value: "disabled" },
  { label: "Can edit in past (no restrictions)", value: "enabled" },
];

export const daysOfWeekOptions = [
  { label: "Sunday", value: 0 },
  { label: "Monday", value: 1 },
  { label: "Tuesday", value: 2 },
  { label: "Wednesday", value: 3 },
  { label: "Thursday", value: 4 },
  { label: "Friday", value: 5 },
  { label: "Saturday", value: 6 },
];

export enum TimeUnitOptionsEnum {
  DAYS = "days",
  HOURS = "hours",
}

export const timeUnitOptions = [
  { label: "(none)", value: null },
  { label: "days", value: TimeUnitOptionsEnum.DAYS },
  { label: "hours", value: TimeUnitOptionsEnum.HOURS },
];

export const metadataParentObjectOptions = [
  { label: "Employment", value: MetadataTypeObjectClassEnum.Employment },
];

export const metadataDataTypeOptions = [
  { label: "Number", value: MetadataTypeDataTypeEnum.Number },
  { label: "String", value: MetadataTypeDataTypeEnum.String },
  { label: "Boolean", value: MetadataTypeDataTypeEnum.Boolean },
  { label: "Date", value: MetadataTypeDataTypeEnum.Date },
  { label: "Timestamp", value: MetadataTypeDataTypeEnum.Timestamp },
  { label: "Object", value: MetadataTypeDataTypeEnum.Object },
];

export const BreakTypePaidThreshold = {
  // Platform UI will assume anything over this value is unpaid, but when the user selects 'always' via platform ui, we will set the
  // value to 1440. (this just covers backend changing this value for whatever reason)
  AlwaysPaidMinimum: 1240,
  AlwaysPaidDefault: 1440,

  // User has selected 'never' for the paidThreshold
  NeverPaid: 0,

  // Default custom input amount
  DefaultUnpaidCustomAmount: 15,
};

export const legacyBreakTypeOptions = [
  {
    value: LegacyBreakTypeEnum.Meal,
    label: "Meal",
  },
  {
    value: LegacyBreakTypeEnum.Rest,
    label: "Rest",
  },
  {
    value: LegacyBreakTypeEnum.Unknown,
    label: "Unknown",
  },
];

export const employmentFixedShiftTypeOptions = [
  {
    label: "Required",
    value: EmploymentTypeFixedShiftRestrictionOptionEnum.Required,
  },
  {
    label: "Optional",
    value: EmploymentTypeFixedShiftRestrictionOptionEnum.Optional,
  },
  {
    label: "Not applicable",
    value: EmploymentTypeFixedShiftRestrictionOptionEnum.NotApplicable,
  },
];

export const metadataLayoutWidthOptions = [2, 3, 4, 6, 8, 12].map((width) => ({
  label: `${width}`,
  value: width,
}));

// These are some common group names to make data entry easier
export const presetMetadataGroupNames = [
  "personal_general",
  "personal_school_details",
  "employment_general",
  "employment_contracts",
  "employment_roles",
  "employment_home_store",
  "employment_shared_store",
  "employment_job_rate",
  "employment_gfe",
  "hiring_summary_general",
  "hiring_summary_employment_details",
  "rehire_date",
  "rehire_job_rate",
  "rehire_personal",
];

export const timeclockPreshiftMessageTypeOptions = [
  {
    label: "None",
    value: null,
  },
  {
    label: "Configurable",
    value: TimeClockAppPreShiftMessageTypeEnum.Configurable,
  },
  {
    label: "Predefined (hardcoded)",
    value: TimeClockAppPreShiftMessageTypeEnum.Predefined,
  },
];
